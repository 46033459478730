import { isElectron } from '@groupthinkai/groupthink';
import { Box } from '@mui/joy';
import router from 'next/router';
import * as React from 'react';

const DEFAULT_TITLE_BAR_COLOR = 'groupthinkNeutral.200';
const DEFAULT_TITLE_BAR_HEIGHT = '28px';

interface TitleBarProps {
  titleBarColor: string;
}

// Replace Record<string, never> with an { object } whenever ElectronProps gains additional props
type ElectronProps = TitleBarProps & Record<string, never>;

type BaseLayoutProps = {
  electronProps?: ElectronProps;
  children: React.ReactNode;
};

export default function BaseLayout({ electronProps, children }: BaseLayoutProps) {
  const { titleBarColor = DEFAULT_TITLE_BAR_COLOR } = electronProps ?? {};

  // Handle navigation events from the main process
  React.useEffect(() => {
    // Add listener for navigation events from the main process
    if (window.electron) {
      const handleNavigation = (event, path) => {
        console.log(`🔗 Navigating to: ${path}`);
        router.push(path);
      };

      window.electron.receive('navigate-to', handleNavigation);

      // Clean up listener
      return () => {
        window.electron.removeListener('navigate-to', handleNavigation);
      };
    }
  }, [router]);

  return (
    <Box
      id="base-layout"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}>
      {isElectron && <TitleBar titleBarColor={titleBarColor} />}
      {children}
    </Box>
  );
}

const TitleBar = ({ titleBarColor }: TitleBarProps) => {
  // We could have the title bar display the current page title, but it clashes with our Header
  // Uncomment if we want to use the document title... IMHO it looks better blank.
  // const [title, setTitle] = React.useState('Groupthink');
  // React.useEffect(() => {
  //   // The document title MUST be fetched after the component mounts
  //   setTitle(document.title || 'Groupthink');
  // }, []);

  return (
    <Box
      id="electron-title-bar"
      sx={{
        height: DEFAULT_TITLE_BAR_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: titleBarColor,
        WebkitAppRegion: 'drag', // Allow dragging the window by the title bar
        WebkitUserSelect: 'none', // Prevent text selection in title bar
      }}>
      {/* Uncomment to use a title */}
      {/* <Typography level="body-sm" fontWeight="bold">
        {title}
      </Typography> */}
    </Box>
  );
};
