import { useParticipantProperty } from '@daily-co/daily-react';

/**
 * Returns a descriptive username for a participant.
 *
 * @param {string} sessionId - The participant's session ID.
 * @param {boolean} isScreenShare - Whether the participant is sharing their screen.
 * @param {boolean} isLocal - Whether the participant is the local participant.
 * @returns {string} - A descriptive username for a participant.
 */
export function useParticipantUsername({ sessionId, isScreenShare = false, isLocal = false }) {
  const username = useParticipantProperty(sessionId, 'user_name');
  return `${username || sessionId}${isScreenShare ? "'s Screen" : ''} ${isLocal ? '(you)' : ''}`;
}
