import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

export default function CameraPermissionsSnackbar({ open, setOpen }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={10_000}
      open={open}
      size={'lg'}
      onClose={(event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      }}
      startDecorator={<VideocamOffOutlinedIcon />}
      endDecorator={
        <Button onClick={() => setOpen(false)} size="sm" variant="soft" color="neutral">
          Dismiss
        </Button>
      }>
      <Stack>
        <Typography level="title-sm" fontWeight="lg">
          Unblock your camera and microphone
        </Typography>
        <Typography level="body-sm" fontWeight="lg">
          Your browser needs camera and microphone access.
        </Typography>
      </Stack>
    </Snackbar>
  );
}
