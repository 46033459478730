import { Button, Divider, Stack } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { useRouter } from 'next/router';
import React from 'react';

import ItemContent from '@/components/action/ItemContent';
import { Modal } from '@/components/reusable';

const ActionModal = ({ action, open, setOpen }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ItemContent actionId={action.id} workspaceId={action.workspace_id} outlined={false} />
    </Modal>
  );
};

export default ActionModal;
