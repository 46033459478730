import {
  isElectron,
  selectNextMeetingTime,
  useAgenda,
  useMeetings,
  useOrganization,
  useUserAgendas,
} from '@groupthinkai/groupthink';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Link, Typography, Stack, Tooltip } from '@mui/joy';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

import AddConferencingModal from '@/components/agenda/AddConferencingModal';
import MeetingTimer from '@/components/agenda/MeetingTimer';
import StartMeetingModal from '@/components/agenda/StartMeetingModal';
import EndMeetingButton from '@/components/agenda/meeting/EndMeetingButton';
import NextMeetingButton from '@/components/agenda/meeting/NextMeetingButton';
import StartMeetingActions from '@/components/agenda/meeting/StartMeetingActions';
import ScheduleMeetingModal from '@/components/calendar/ScheduleMeetingModal';
import { useCall } from '@/hooks/call';
import { usePusher, useRealtimeResource } from '@/hooks/realtime';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_MEETING_DURATION_CAP_IN_MINUTES = 60; // TODO: Should this come from the API?
const WARN_BEFORE_MEETING_ENDS_IN_MINUTES = 5;

const MeetingStatus = ({ agendaId, agendaListRef, onMeetingStart = null }) => {
  const [open, setOpen] = React.useState(false);
  const [openScheduler, setOpenScheduler] = React.useState(false);
  const [conferenceUrl, setConferenceUrl] = React.useState('');
  const [isCreating, setIsCreating] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [meetingEnded, setMeetingEnded] = useState(false);
  const [nextAgenda, setNextAgenda] = React.useState(false);
  const [statusTextColor, setStatusTextColor] = useState('neutral.500');
  const [statusText, setStatusText] = useState(null);
  const [nextMeetingTooltip, setNextMeetingTooltip] = useState('');

  const { agenda, isError, mutate, isCurrentUserAgendaEditor } = useAgenda(agendaId, {
    useRealtimeResource,
  });
  const { createMeeting, updateMeeting } = useMeetings(agendaId, agenda?.active_meeting?.id);
  const { organization } = useOrganization(agenda?.workspace?.id);

  const hasUnlimitedMeetingDuration =
    organization?.features?.groupthink_meeting_duration_cap_removed ?? false;

  const { state: callState, selectors, presentPreJoinHaircheck } = useCall();
  const isCallIdleOrIgnored = selectors.selectIsCallIdleOrIgnored(callState);

  let { time_string, meeting_is_scheduled, meeting_has_no_upcoming_event } =
    selectNextMeetingTime(agenda);

  const router = useRouter();

  const inCurrentAgenda = router?.asPath.startsWith(`/agendas/${agendaId}`);
  const textColor = inCurrentAgenda ? 'neutral.900' : 'neutral.100';
  const meetingEndsAt =
    agenda?.active_meeting &&
    agenda?.active_meeting?.conferencing?.driver === 'groupthink' &&
    !hasUnlimitedMeetingDuration
      ? dayjs(agenda?.active_meeting?.started_at).add(
          DEFAULT_MEETING_DURATION_CAP_IN_MINUTES,
          'minutes'
        )
      : agenda?.active_meeting?.ends_at;

  //get the first active agenda that is not the current agenda
  const {
    agendas: upcomingAgendas,
    isLoading: isLoadingUpcomingAgendas,
    isValidating: isValidatingUpcomingAgendas,
  } = useUserAgendas(null, 'upcoming');

  const {
    agendas: activeAgendas,
    isLoading: isLoadingActiveAgendas,
    isValidating: isValidatingActiveAgendas,
  } = useUserAgendas(null, 'active');

  const now = dayjs();
  const fiveMinFromNow = now.add(5, 'minute');

  useEffect(() => {
    if (!activeAgendas && !upcomingAgendas) return;

    const findNextAgenda = (agendas, key, condition) => {
      return agendas?.find((item) => {
        const meetingTime = dayjs(item[key]?.starts_at); // Parse the starts_at field
        return item.id !== agendaId && item[key]?.starts_at && condition(meetingTime);
      });
    };

    // Try finding the next active agenda
    let nextAgenda = findNextAgenda(activeAgendas, 'active_meeting', (time) => time.isAfter(now));

    // If no active agenda is found, try finding the next upcoming agenda
    if (!nextAgenda) {
      nextAgenda = findNextAgenda(
        upcomingAgendas,
        'next_meeting',
        (time) => time.isAfter(now) && time.isBefore(fiveMinFromNow)
      );
    }

    setNextAgenda(nextAgenda);
  }, [activeAgendas, upcomingAgendas, agendaId, now, fiveMinFromNow]);

  useEffect(() => {
    if (nextAgenda) {
      setNextMeetingTooltip(
        nextAgenda.active_meeting?.conferencing?.driver === 'Groupthink' ||
          nextAgenda.next_meeting?.conferencing?.driver === 'Groupthink'
          ? 'Clicking will take you directly to the next meeting, skipping the haircheck and keeping your current camera and microphone settings'
          : "Clicking will take you to your next meeting's agenda"
      );
    }
  }, [nextMeetingTooltip]);

  const startMeeting = () => {
    createMeeting({
      payload: {
        conference_url: conferenceUrl,
        source: 'meeting-container-start',
        start: true,
        workspace_id: agenda?.workspace.id,
      },
      setIsCreating,
      onSuccess: () => {
        setOpen(false);
        mutate();

        onMeetingStart && onMeetingStart();
      },
    });
  };

  const startGroupthinkMeeting = () => {
    createMeeting({
      payload: {
        source: 'meeting-container-start',
        start: true,
        workspace_id: agenda?.workspace.id,
      },
      setIsCreating,
      onSuccess: (data) => {
        router.push(`/rooms/${data.room_id}`);
      },
    });
  };

  const addConferencing = (e) => {
    e?.preventDefault();
    updateMeeting(agenda?.active_meeting?.id, {
      payload: { conference_url: conferenceUrl, workspace_id: agenda?.workspace.id },
      setIsUpdating,
      onSuccess: () => {
        setOpen(false);
        mutate();
      },
    });
  };

  const handleJoinVideoConference = React.useCallback(() => {
    if (agenda?.room_id) {
      const roomId = agenda.room_id;
      presentPreJoinHaircheck({
        roomId,
        agendaId: agenda?.id,
        meetingId: agenda?.active_meeting?.id,
      });
    }
  }, [agenda]);

  const updateMeetingStatus = () => {
    const started = dayjs(agenda?.active_meeting?.started_at);
    const meeting_length_minutes = agenda?.active_meeting && dayjs().diff(started, 'minutes');
    const timeLeftInMeeting = DEFAULT_MEETING_DURATION_CAP_IN_MINUTES - meeting_length_minutes;

    // Warn that meeting is about to end around 5 minutes before the meeting duration cap
    const isNearingMeetingDurationCap =
      agenda?.active_meeting &&
      !hasUnlimitedMeetingDuration &&
      timeLeftInMeeting <= WARN_BEFORE_MEETING_ENDS_IN_MINUTES;

    const hasExceededMeetingDurationCap =
      agenda?.active_meeting && !hasUnlimitedMeetingDuration && timeLeftInMeeting <= 0;

    setStatusTextColor(
      isNearingMeetingDurationCap ? '#cf4743' : inCurrentAgenda ? 'neutral.500' : 'neutral.100'
    );

    setStatusText(
      agenda?.active_meeting
        ? isNearingMeetingDurationCap
          ? 'Meeting Ending Soon'
          : ''
        : meeting_is_scheduled
        ? ''
        : meeting_has_no_upcoming_event
        ? ''
        : null
    );
  };

  // Render initial meeting status, then re-calculate status every second if meeting is active
  useEffect(() => {
    updateMeetingStatus();
    if (agenda?.active_meeting) {
      const interval = setInterval(updateMeetingStatus, 1000);
      return () => clearInterval(interval);
    }
  }, [agenda?.active_meeting]);

  // watch for meeting status changes from other users
  usePusher({
    type: 'Meeting.MeetingStarted',
    channel: agenda?.id ? `agendas.${agenda?.id}.meetings` : null,
    callback: () => {
      mutate();
    },
  });

  usePusher({
    type: 'Meeting.MeetingEnded',
    channel: agenda?.id ? `agendas.${agenda?.id}.meetings` : null,
    callback: () => {
      mutate();
    },
  });

  if (isError) {
    return null;
  }

  return (
    <Box id="meeting-status-container">
      <Box
        sx={{
          px: 1,
          pt: 1,
        }}>
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {/* Top Section: Timer and Meeting Ending Soon Text */}
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography level="body-md" textColor={textColor}>
                {agenda?.active_meeting && (
                  <MeetingTimer
                    startTime={agenda?.active_meeting?.started_at}
                    endTime={meetingEndsAt}
                  />
                )}
                {!agenda?.active_meeting && meeting_is_scheduled ? (
                  <Typography level="body-sm">
                    {time_string}
                    {agenda?.next_meeting?.conferencing?.url && (
                      <>
                        {' '}
                        via{' '}
                        <a
                          style={{ textDecoration: 'none' }}
                          href={agenda?.next_meeting?.conferencing?.url}
                          target="_blank"
                          rel="noreferrer">
                          <Typography color="primary">
                            {agenda?.next_meeting?.conferencing?.label}
                          </Typography>
                        </a>
                      </>
                    )}
                  </Typography>
                ) : !agenda?.active_meeting && meeting_has_no_upcoming_event ? (
                  <Button
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    onClick={() => setOpenScheduler(true)}>
                    Schedule...
                  </Button>
                ) : null}
              </Typography>
              {statusText && (
                <Typography
                  level="title-sm"
                  sx={{ fontWeight: 'bold', margin: 2 }}
                  textColor={statusTextColor}>
                  {statusText}
                </Typography>
              )}
            </Stack>

            {/* Bottom Section: Buttons */}
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={0.5}
              justifyContent="space-between"
              alignItems="center">
              {!agenda?.active_meeting && isCurrentUserAgendaEditor && (
                <StartMeetingActions organizationId={agenda?.workspace?.id} agendaId={agendaId} />
              )}
              {agenda?.active_meeting &&
                isCurrentUserAgendaEditor &&
                isCallIdleOrIgnored &&
                agenda?.active_meeting?.conferencing.driver === 'groupthink' && (
                  <Button
                    sx={{ mb: 0 }}
                    size="sm"
                    onClick={handleJoinVideoConference}
                    color="primary"
                    disabled={isUpdating}>
                    {inCurrentAgenda ? 'Join Video Conference...' : 'Join...'}
                  </Button>
                )}
              {agenda?.active_meeting &&
                agenda?.active_meeting.conferencing?.url &&
                agenda?.active_meeting?.conferencing.driver !== 'groupthink' &&
                isCurrentUserAgendaEditor && (
                  <Typography level="body-md">
                    <Button
                      component={Link}
                      size="sm"
                      color="tertiary"
                      variant="outlined"
                      href={agenda?.active_meeting.conferencing.url}
                      endDecorator={<OpenInNewIcon />}
                      target="blank">
                      Open {agenda?.active_meeting.conferencing.label ?? 'Conference'}
                    </Button>
                  </Typography>
                )}
              {agenda?.active_meeting && isCurrentUserAgendaEditor && inCurrentAgenda && (
                <EndMeetingButton
                  agendaId={agendaId}
                  agendaListRef={agendaListRef}
                  isGroupthinkVideo={
                    agenda?.active_meeting?.conferencing?.label &&
                    agenda?.active_meeting?.conferencing.driver === 'groupthink'
                  }
                />
              )}
              {nextAgenda && isCurrentUserAgendaEditor && inCurrentAgenda && (
                <Tooltip title={nextMeetingTooltip}>
                  <NextMeetingButton
                    tooltip={nextMeetingTooltip}
                    currentAgendaId={agendaId}
                    nextAgenda={nextAgenda}
                    agendaListRef={agendaListRef}
                    isGroupthinkVideo={
                      agenda?.active_meeting?.conferencing?.label &&
                      agenda?.active_meeting?.conferencing.driver === 'groupthink'
                    }
                  />
                </Tooltip>
              )}
              {agenda?.active_meeting && !inCurrentAgenda && (
                <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent="space-between">
                  <Button
                    size="sm"
                    variant="plain"
                    component={NextLink}
                    endDecorator={<ArrowForwardIosIcon />}
                    href={`/agendas/${agenda?.id}`}>
                    Back to {agenda?.name}
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </>
      </Box>

      {agenda?.active_meeting ? (
        <AddConferencingModal
          open={open}
          setOpen={setOpen}
          conferenceUrl={conferenceUrl}
          setConferenceUrl={setConferenceUrl}
          isCreating={isCreating}
          onAddConferencing={addConferencing}
        />
      ) : (
        <StartMeetingModal
          open={open}
          setOpen={setOpen}
          conferenceUrl={conferenceUrl}
          setConferenceUrl={setConferenceUrl}
          isCreating={isCreating}
          onStartMeeting={startMeeting}
          onCreateGroupthinkVideo={startGroupthinkMeeting}
        />
      )}
      {!agenda?.active_meeting && openScheduler && (
        <ScheduleMeetingModal open={true} setOpen={setOpenScheduler} agendaId={agendaId} />
      )}
      {!agenda?.active_meeting && meetingEnded && Boolean(typeof window !== 'undefined') && (
        <Confetti
          width={window?.innerWidth}
          height={window?.innerHeight}
          recycle={false}
          style={{ position: 'fixed', zIndex: 901 }}
          onConfettiComplete={() => setMeetingEnded(false)}
        />
      )}
    </Box>
  );
};

export default MeetingStatus;
