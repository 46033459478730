import { isMac, isElectron } from '@groupthinkai/groupthink';
import {
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  CircleOutlined as CircleOutlinedIcon,
  Close as CloseIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  ListItemButton,
  Stack,
  Typography,
  Alert,
  Box,
  IconButton,
} from '@mui/joy';
import * as React from 'react';

import { Modal } from '@/components/reusable';

type MediaAccessStatus = 'not-determined' | 'granted' | 'denied' | 'restricted' | 'unknown';

export function DesktopPermissionsAlert() {
  const [isVisible, setIsVisible] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isElectron) {
      window.electron.checkMediaPermissions().then((result) => {
        if (!result) {
          return;
        }

        const { camera, microphone, error } = result;
        if (camera === 'not-determined' || microphone === 'not-determined') {
          setIsVisible(isMac());
        } else {
          setIsVisible(false);
        }

        if (error) {
          alert(error);
        }
      });
    }
  }, []);

  return (
    <>
      {isVisible && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            zIndex: 100,
            marginBottom: 1,
            marginRight: 1,
            width: 'calc(100% - 224px - 16px)', // 224px is the width of the sidebar, 16px is the right margin
          }}>
          <Alert
            variant="soft"
            // @ts-ignore
            color="tertiary"
            invertedColors
            endDecorator={
              <IconButton onClick={() => setIsVisible(false)}>
                <CloseIcon />
              </IconButton>
            }>
            <Box
              sx={{
                flex: 1,
                alignContent: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
              }}>
              <Typography
                level="body-md"
                startDecorator={<SettingsIcon />}
                endDecorator={
                  <Button
                    onClick={() => {
                      setIsVisible(false);
                      setOpen(true);
                    }}>
                    Verify Permissions
                  </Button>
                }>
                Groupthink needs your permission to access your microphone and camera.
              </Typography>
            </Box>
          </Alert>
        </Box>
      )}
      <DesktopMediaPermissionsModal open={open} onClose={() => setOpen(false)} />
    </>
  );
}

export function DesktopMediaPermissionsModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [microphoneStatus, setMicrophoneStatus] =
    React.useState<MediaAccessStatus>('not-determined');
  const [cameraStatus, setCameraStatus] = React.useState<MediaAccessStatus>('not-determined');
  // const [screenSharingStatus, setScreenSharingStatus] =
  //   React.useState<MediaAccessStatus>('not-determined');
  const [error, setError] = React.useState<string | null>(null);
  const [isRequestingPermissions, setIsRequestingPermissions] = React.useState(false);

  const renderIconForMediaAccessStatus = (status: MediaAccessStatus) => {
    switch (status) {
      case 'granted':
        return <CheckCircleOutlinedIcon />;
      case 'denied':
        return <CancelOutlinedIcon />;
      case 'restricted':
        return <ErrorOutlineOutlinedIcon />;
      case 'unknown':
        return <CircleOutlinedIcon />;
      case 'not-determined':
      default:
        return <CircleOutlinedIcon />;
    }
  };

  const handleCheckPermissions = async () => {
    setIsRequestingPermissions(true);
    const { camera, microphone, error } = await window.electron.checkMediaPermissions();
    setMicrophoneStatus(microphone);
    setCameraStatus(camera);
    // setScreenSharingStatus(screen);
    setError(error);
    setIsRequestingPermissions(false);
  };

  const handleRequestPermissions = async () => {
    setIsRequestingPermissions(true);
    const { camera, microphone, error } = await window.electron.requestMediaPermissions();
    if (!error) {
      if (camera) {
        setCameraStatus('granted');
      }
      if (microphone) {
        setMicrophoneStatus('granted');
      }
    }
    // setScreenSharingStatus(screen);
    setError(error);
    setIsRequestingPermissions(false);
  };

  const handleRequestDeniedPermission = async (type: 'microphone' | 'camera' | 'screen') => {
    window.electron.openSecuritySettings(
      type === 'microphone'
        ? 'Privacy_Microphone'
        : type === 'camera'
        ? 'Privacy_Camera'
        : 'Privacy_ScreenCapture'
    );
  };

  const isRequestPermissionsVisible = microphoneStatus === 'not-determined';
  const isSkipVisible = microphoneStatus !== 'granted' && cameraStatus !== 'granted';
  // &&     screenSharingStatus !== 'granted';
  const isContinueVisible = microphoneStatus === 'granted' || cameraStatus === 'granted';
  // ||    screenSharingStatus === 'granted';

  const isVerifyPermissionsVisible = microphoneStatus === 'denied' || cameraStatus === 'denied'; // || screenSharingStatus === 'denied';

  return (
    <Modal open={open} onClose={onClose} variant="dialog">
      <Stack maxWidth="800px" marginX="auto" alignItems={'center'} spacing={4} marginY={2}>
        <Stack spacing={2}>
          <Typography level="body-lg">
            Groupthink needs your <Typography fontWeight="lg">permission</Typography> to access your{' '}
            <Typography fontWeight="lg">microphone</Typography> and{' '}
            <Typography fontWeight="lg">camera</Typography>.
          </Typography>
          <Typography level="body-lg">
            Your microphone and camera{' '}
            <Typography fontWeight="lg">will not be turned on</Typography> until you join a meeting.
            {/* Your <Typography fontWeight="lg">screen will not be shared</Typography> until you choose
            to share it during a meeting. */}
          </Typography>

          <List size="lg">
            <ListItem>
              <ListItemButton
                disabled={microphoneStatus !== 'denied'}
                onClick={() => handleRequestDeniedPermission('microphone')}>
                <ListItemDecorator>
                  {renderIconForMediaAccessStatus(microphoneStatus)}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="body-lg" fontWeight={'lg'}>
                    Microphone
                  </Typography>
                  <Typography level="body-md">
                    Groupthink uses your microphone to share audio in your meetings.
                  </Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                disabled={cameraStatus !== 'denied'}
                onClick={() => handleRequestDeniedPermission('camera')}>
                <ListItemDecorator>
                  {renderIconForMediaAccessStatus(cameraStatus)}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="body-lg" fontWeight={'lg'}>
                    Camera
                  </Typography>
                  <Typography level="body-md">
                    Groupthink uses your camera to share video in your meetings.
                  </Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
            {/* <ListItem>
                <ListItemButton
                  disabled={screenSharingStatus !== 'denied'}
                  onClick={() => handleRequestDeniedPermission('screen')}>
                  <ListItemDecorator>
                    {renderIconForMediaAccessStatus(screenSharingStatus)}
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="body-lg" fontWeight={'lg'}>
                      Screen Sharing
                    </Typography>
                    <Typography level="body-md">
                      Allow Groupthink to share your screen in your meetings.
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem> */}
          </List>

          {error && (
            <Typography level="body-sm" color="danger">
              {error}
            </Typography>
          )}
          {isVerifyPermissionsVisible && (
            <Button
              size="sm"
              variant={'outlined'}
              // @ts-ignore
              color="tertiary"
              onClick={handleCheckPermissions}>
              Verify Permissions
            </Button>
          )}
          {isRequestPermissionsVisible && (
            <Button
              size="sm"
              variant={'solid'}
              // @ts-ignore
              color="tertiary"
              onClick={handleRequestPermissions}
              disabled={isRequestingPermissions}
              loading={isRequestingPermissions}>
              Request Permissions
            </Button>
          )}
          {isContinueVisible && (
            <Stack width="100%" direction="row" spacing={2} justifyContent={'flex-end'}>
              <Button
                size="lg"
                variant={'solid'}
                // @ts-ignore
                color="tertiary"
                onClick={onClose}>
                Continue
              </Button>
            </Stack>
          )}
          <Typography level="body-sm">
            You can grant or revoke these permissions at any time in the Privacy &amp; Security
            system settings.
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
}
