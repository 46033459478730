import { useCalendar, useOrganization } from '@groupthinkai/groupthink';
import { Button, CircularProgress, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import React, { useState, useCallback } from 'react';

import MeetingLink from '@/components/agenda/MeetingLink';
import { Modal } from '@/components/reusable';
import SetupCalendar from '@/components/setup/SetupCalendar';
import { useCurrentOrganization } from '@/hooks/organization';

const ShareMeetingWithCalendarPromptModal = ({ open, setOpen, agendaId, meetingId }) => {
  const [currentOrganization] = useCurrentOrganization();
  const { organization, isLoading: isLoadingOrganization } = useOrganization(currentOrganization);
  const { calendar, isLoading: isLoadingCalendar } = useCalendar(
    organization?.id,
    organization?.calendar_id
  );

  const [isSkipButtonClicked, setIsSkipButtonClicked] = useState(false);

  /**
   * If the user has not enabled the `should_create_agendas_as_organizer` setting, show a Skip button.
   * If the user clicks the Skip button, or enables the `should_create_agendas_as_organizer` setting,
   * render the Invite link.
   */
  const renderSkipButtonOrInviteLink = useCallback(() => {
    if (isSkipButtonClicked || calendar?.settings.should_create_agendas_as_organizer) {
      return (
        <Box>
          <Stack spacing={1}>
            <Typography level="h6" sx={{ mb: 0.5 }}>
              Meeting Link
            </Typography>
            <Typography sx={{ mb: 1 }}>Anyone with this link can access the room</Typography>
            <Box width="100%">
              <MeetingLink agendaId={agendaId} meetingId={meetingId} />
            </Box>
          </Stack>
        </Box>
      );
    } else {
      return (
        <Button
          variant="outlined"
          onClick={() => {
            setIsSkipButtonClicked(true);
          }}>
          Skip
        </Button>
      );
    }
  }, [calendar, isSkipButtonClicked]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title="Automatically Share by Connecting your Calendar"
      description="Groupthink automatically invites attendees to the meeting when you connect your calendar.">
      {isLoadingOrganization || isLoadingCalendar ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size="sm" />
        </Box>
      ) : (
        <>
          <SetupCalendar organization={organization} />
        </>
      )}
      {renderSkipButtonOrInviteLink()}
    </Modal>
  );
};

export default ShareMeetingWithCalendarPromptModal;
