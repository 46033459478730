import { isElectron, useAgenda, useGuestRoom } from '@groupthinkai/groupthink';
import { Chat as ChatIcon, Menu as MenuIcon, Videocam as VideocamIcon } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Stack } from '@mui/joy';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';

import { DesktopPermissionsAlert } from '@/components/DesktopPermissions';
import MeetingStatus from '@/components/agenda/MeetingStatus';
import ActiveMeetingsStatusBar from '@/components/agenda/meeting/ActiveMeetingsStatusBar';
import MeetingTextContainer from '@/components/agenda/meeting/MeetingTextContainer';
import NextNavigation from '@/components/navigation/NextNavigation';
import CallContainer from '@/components/video/CallContainer';
import { useAuth } from '@/hooks/auth';
import { CallProvider, useCall } from '@/hooks/call';
import { CallLayoutProvider, useCallLayout } from '@/hooks/callLayout';
import { NotificationProvider } from '@/hooks/notifications';
import BaseLayout from '@/layouts/BaseLayout';
import { LoadingContext } from '@/layouts/LoadingProvider';
export default function VideoLayoutWithProvider({
  sidebar = null,
  header = null,
  superHeader = null,
  fullWidth = false,
  transparentSidebar = false,
  children,
}) {
  return (
    <BaseLayout
      electronProps={{
        titleBarColor: 'groupthinkNeutral.200',
      }}>
      <CallProvider>
        <VideoLayout
          sidebar={sidebar}
          transparentSidebar={transparentSidebar}
          header={header}
          superHeader={superHeader}
          fullWidth={fullWidth}>
          <NotificationProvider>{children}</NotificationProvider>
        </VideoLayout>
      </CallProvider>
    </BaseLayout>
  );
}

function VideoLayout({
  sidebar = null,
  header = null,
  superHeader = null,
  fullWidth = false,
  transparentSidebar = false,
  children,
}) {
  const router = useRouter();
  const routeAgendaId = router.query?.agenda;

  /**
   * State
   */
  const [mobileShow, setMobileShow] = useState('');
  const [useableAgendaId, setUseableAgendaId] = useState(routeAgendaId);
  const hasRouteAgendaId = Boolean(routeAgendaId);

  // This is necessary in order to force a logout if the user is not authenticated
  const { user, isLoading: isLoadingAuth } = useAuth({
    middleware: 'auth',
  });

  const { state: callState, selectors, setVideoInSidebar, presentPreJoinHaircheck } = useCall();
  const currentVideoRoomId = selectors.selectRoomId(callState);
  const isCallIdleOrIgnored = selectors.selectIsCallIdleOrIgnored(callState);
  const isCallIdle = selectors.selectIsCallIdle(callState);
  const isCallActiveInOtherTab = selectors.selectIsCallActiveInOtherTab(callState);
  const hasJoinedCall = selectors.selectHasJoinedCall(callState);

  const { agenda, isCurrentUserAgendaEditor } = useAgenda(useableAgendaId);

  const { room: currentVideoRoom } = useGuestRoom(currentVideoRoomId);

  const currentVideoRoomAgendaId = currentVideoRoom?.agendas?.[0]?.id;

  const { isLoading: isContextLoading } = useContext(LoadingContext);

  const hasActiveMeeting = Boolean(agenda?.active_meeting);
  const isUsingGroupthinkVideo = agenda?.active_meeting?.conferencing?.driver === 'groupthink';
  const hasActiveMeetingUsingGroupthinkVideo = hasActiveMeeting && isUsingGroupthinkVideo;
  const hasActiveMeetingUsingGroupthinkVideoAndUserIsAllowedToAttend =
    hasActiveMeetingUsingGroupthinkVideo && isCurrentUserAgendaEditor;
  const hasVideoSidebar =
    hasActiveMeetingUsingGroupthinkVideo &&
    (hasRouteAgendaId || hasJoinedCall || !isCallActiveInOtherTab);

  let effectiveSidebar = sidebar;
  if (
    hasRouteAgendaId &&
    routeAgendaId === useableAgendaId &&
    hasActiveMeeting &&
    isCurrentUserAgendaEditor
  ) {
    effectiveSidebar = <MeetingTextContainer agendaId={useableAgendaId} />;
  }
  const hasSidebar = Boolean(effectiveSidebar);

  /**
   * Effects
   */

  /**
   * currentVideoRoomId is the room id of the video room we are currently in. We may or may not be on the actual agenda page for this room.
   * If we have a currentVideoRoomId, we can use that room id to derive the agenda id.
   * If we do not have a currentVideoRoomId, we can use the agenda id from the route.
   * If we have neither, do nothing.
   *
   * All of this is just to determine if we are ON the agenda page for the video room we are in or not.
   */
  useEffect(() => {
    if (!hasJoinedCall && !hasRouteAgendaId) {
      setUseableAgendaId(null);
      return;
    }

    if (currentVideoRoomId && currentVideoRoom && currentVideoRoomAgendaId) {
      setUseableAgendaId(currentVideoRoomAgendaId);
    } else if (!currentVideoRoomId && router?.isReady && router.query?.agenda) {
      // We don't have a roomId in local storage, but there's one available in the route.
      // We will use the route agenda id.
      const newRouteAgendaId = router.query.agenda;
      setUseableAgendaId(newRouteAgendaId);
    }
  }, [currentVideoRoomId, currentVideoRoom, currentVideoRoomAgendaId, router]);

  /**
   * Displays the haircheck modal when the video room is ready.
   */
  useEffect(() => {
    const roomId = agenda?.room_id ?? agenda?.room?.id;
    const agendaId = agenda?.id;
    const meetingId = agenda?.active_meeting?.id;

    if (!agendaId || !meetingId) {
      // Agenda is still loading
      return;
    }

    if (
      hasActiveMeetingUsingGroupthinkVideoAndUserIsAllowedToAttend &&
      isCallIdle &&
      !isCallActiveInOtherTab &&
      !callState.previousAgendaId &&
      !callState.previousMeetingId
    ) {
      if (currentVideoRoomId) {
        presentPreJoinHaircheck({
          roomId: currentVideoRoomId,
          agendaId: agenda?.id,
          meetingId: agenda?.active_meeting?.id,
        });
      } else if (roomId) {
        presentPreJoinHaircheck({
          roomId,
          agendaId: agenda?.id,
          meetingId: agenda?.active_meeting?.id,
        });
      }
    }
  }, [
    currentVideoRoomId,
    hasActiveMeetingUsingGroupthinkVideoAndUserIsAllowedToAttend,
    isCallIdle,
    agenda?.id,
    agenda?.room_id,
    agenda?.room?.id,
    agenda?.active_meeting?.id,
  ]);

  useEffect(() => {
    setMobileShow('');
  }, [router.asPath]);

  useEffect(() => {
    setVideoInSidebar(hasJoinedCall && (!hasRouteAgendaId || routeAgendaId !== useableAgendaId));
  }, [hasRouteAgendaId, routeAgendaId, useableAgendaId, hasJoinedCall]);

  return (
    <CallLayoutProvider>
      <GridLayoutProvider
        hasJoinedCall={hasJoinedCall}
        isCallIdleOrIgnored={isCallIdleOrIgnored}
        hasVideoSidebar={hasVideoSidebar}
        mobileShow={mobileShow}
        sidebar={effectiveSidebar}>
        <Box
          className="mobile-layout-head"
          sx={{
            gridArea: 'head',
            display: 'sticky',
            top: 0,
            width: '100%',
            height: '32px',
            marginTop: '8px',
            paddingX: '16px',
          }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <IconButton
              size="sm"
              variant={mobileShow === 'navigation' ? 'solid' : 'outlined'}
              color={mobileShow === 'navigation' ? 'primary' : 'neutral'}
              onClick={() => setMobileShow(mobileShow === 'navigation' ? '' : 'navigation')}>
              <MenuIcon />
            </IconButton>
            {hasActiveMeetingUsingGroupthinkVideoAndUserIsAllowedToAttend && (
              <IconButton
                size="sm"
                variant={mobileShow === 'video' ? 'solid' : 'outlined'}
                color={mobileShow === 'video' ? 'primary' : 'neutral'}
                onClick={() => setMobileShow(mobileShow === 'video' ? '' : 'video')}>
                <VideocamIcon />
              </IconButton>
            )}
            {hasSidebar && (
              <IconButton
                size="sm"
                variant={mobileShow === 'sidebar' ? 'solid' : 'outlined'}
                color={mobileShow === 'sidebar' ? 'primary' : 'neutral'}
                onClick={() => setMobileShow(mobileShow === 'sidebar' ? '' : 'sidebar')}>
                <ChatIcon />
              </IconButton>
            )}
          </Stack>
        </Box>
        <Box className="layout-header" sx={{ gridArea: 'header', width: '100%', mb: 1 }}>
          {isContextLoading && (
            <LinearProgress
              size="lg"
              sx={{
                zIndex: 9999,
              }}
            />
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ marginX: { xs: 1, lg: 0 }, pr: hasSidebar ? 0 : 1 }}>
            <Stack sx={{ width: '100%' }}>
              <ActiveMeetingsStatusBar />
              {hasJoinedCall && (!hasRouteAgendaId || routeAgendaId !== useableAgendaId) && (
                <MeetingStatus agendaId={useableAgendaId} />
              )}
              {superHeader}
            </Stack>

            {/*<Input*/}
            {/*  size="sm"*/}
            {/*  variant="outlined"*/}
            {/*  placeholder="Ask anything…"*/}
            {/*  startDecorator={<SearchRoundedIcon color="neutral" />}*/}
            {/*  endDecorator={*/}
            {/*    <IconButton*/}
            {/*      variant="outlined"*/}
            {/*      color="neutral"*/}
            {/*      sx={{ bgcolor: 'background.level1' }}>*/}
            {/*      <Typography level="title-sm" textColor="text.icon">*/}
            {/*        ⌘ K*/}
            {/*      </Typography>*/}
            {/*    </IconButton>*/}
            {/*  }*/}
            {/*  sx={{*/}
            {/*    alignSelf: 'center',*/}

            {/*    display: {*/}
            {/*      xs: 'none',*/}
            {/*      sm: 'flex',*/}
            {/*    },*/}
            {/*  }}*/}
            {/*/>*/}
          </Stack>
        </Box>
        <Box
          className="layout-navigation"
          sx={{
            backgroundColor: 'groupthinkNeutral.200',
            zIndex: 102,
            gridArea: 'navigation',
            position: { xs: 'fixed', lg: 'sticky' },
            top: { xs: '48px', lg: 0 },
            height: { xs: 'calc(100vh - 48px)', lg: 'initial' },
            paddingTop: { xs: '16px', lg: 0 },
            paddingBottom: { xs: '16px', lg: 0 },
          }}>
          <NextNavigation />
        </Box>
        <Box
          className="layout-primary"
          sx={{
            gridArea: 'primary',
            overflow: 'auto',
            backgroundColor: '#FFFFFF',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderTop: '1px solid #e2e2e2',
            borderLeft: '1px solid #e2e2e2',
            borderRight: '1px solid #e2e2e2',
            // box shadow top and left
            boxShadow: '-1px -1px 6px 0 rgba(0, 0, 0, 0.05)',
            marginLeft: { xs: 1, lg: 0 },
            marginRight: 1,
            maxWidth: fullWidth ? '100%' : '1024px',
          }}>
          {header && (
            <Box className="layout-header" sx={{ width: '100%' }}>
              {header}
            </Box>
          )}
          {/*<UserOnboardingStatusBar />*/}
          {children}
          {!isLoadingAuth && user && <DesktopPermissionsAlert />}
        </Box>
        <GroupthinkVideoContainer
          agendaId={agenda?.id}
          hasVideoSidebar={hasVideoSidebar}
          isCurrentUserAgendaEditor={isCurrentUserAgendaEditor}
          isCallIdleOrIgnored={isCallIdleOrIgnored}
        />
        <SidebarContainer
          sidebar={effectiveSidebar}
          transparentSidebar={transparentSidebar}
          isCallIdleOrIgnored={isCallIdleOrIgnored}
        />
      </GridLayoutProvider>
    </CallLayoutProvider>
  );
}

function GroupthinkVideoContainer({
  agendaId,
  hasVideoSidebar,
  isCurrentUserAgendaEditor,
  isCallIdleOrIgnored,
}) {
  if (isCallIdleOrIgnored) {
    return null;
  }
  return (
    hasVideoSidebar && (
      <Box
        className="layout-video"
        sx={{
          gridArea: 'video',
          position: { xs: 'fixed', lg: 'sticky' },
          top: { xs: '64px', lg: 0 },
          height: { xs: 'calc(100vh - 48px)', lg: 'initial' },
          width: { xs: '100%', lg: 'initial' },
          paddingTop: { xs: '16px', lg: 0 },
          paddingBottom: { xs: '16px', lg: 0 },
          backgroundColor: 'groupthinkNeutral.200',
        }}>
        {isCurrentUserAgendaEditor ? (
          <Box sx={{ height: '100%', mt: 1 }}>
            <CallContainer agendaId={agendaId} />
          </Box>
        ) : (
          <Box sx={{ p: 2, pt: 0 }}>
            <MeetingStatus agendaId={agendaId} />
          </Box>
        )}
      </Box>
    )
  );
}

function SidebarContainer({ sidebar, transparentSidebar = false, isCallIdleOrIgnored }) {
  const sidebarDecoration = {
    backgroundColor: '#fefefe',
    borderLeft: '1px solid #e2e2e2',
    borderTop: isElectron ? '1px solid #e2e2e2' : 'none',
    boxShadow: '-2px 0px 6px 0 rgba(0, 0, 0, 0.05)',
    marginLeft: 2,
  };

  return (
    Boolean(sidebar) && (
      <Box
        className="layout-sidebar"
        sx={{
          gridArea: 'sidebar',
          position: { xs: 'fixed', lg: 'sticky' },
          top: { xs: '64px', lg: 0 },
          height: { xs: 'calc(100% - 48px)', lg: 'initial' },
          width: { xs: '100%', lg: 'initial' },
          overflow: 'hidden',
          zIndex: 101,
          borderTopLeftRadius: {
            xs: '8px',
            lg: isElectron ? '8px' : isCallIdleOrIgnored ? 0 : '8px',
          },
          ...(transparentSidebar ? {} : sidebarDecoration),
        }}>
        {sidebar}
      </Box>
    )
  );
}

// Static definitions for the various grid areas
const GRID_AREA_MOBILE_HEAD = 'head';
const GRID_AREA_NAV = 'navigation';
const GRID_AREA_HEADER = 'header';
const GRID_AREA_PRIMARY = 'primary';
const GRID_AREA_SIDEBAR = 'sidebar';
const GRID_AREA_VIDEO = 'video';

// The 'nav' column width is 224px by default. This does not change.
const GRID_COLUMN_WIDTH_NAV = '224px';

// Use 640px for whichever column is collapsed, if any.
// Sidebars are collapsed by default, so this is the default sidebar width value.
const GRID_COLUMN_WIDTH_COLLAPSED = '480px';

function GridLayoutProvider({
  hasJoinedCall,
  isCallIdleOrIgnored,
  hasVideoSidebar,
  mobileShow,
  sidebar = null,
  children,
}) {
  const { state: groupthinkVideoState } = useCallLayout();

  const theme = useTheme();
  const lgPlus = useMediaQuery(theme.breakpoints.up('lg'));

  // Grid Area for breakpoints under lg.
  // This is a single column layout, with no sidebar shenanigans.
  const gridArea = `
          '${GRID_AREA_MOBILE_HEAD}'
          '${GRID_AREA_HEADER}'
          '${GRID_AREA_VIDEO}'
          '${GRID_AREA_PRIMARY}'
          '${GRID_AREA_NAV}'
          '${GRID_AREA_SIDEBAR}'`;

  // For larger breakpoints, we have a two column layout by default, three if there is a sidebar.

  // The 'main' column width is 1fr by default.
  // - If the user is in a video call, this can change depending on the layoutHorizontalBias.
  let gridColumnMainWidth = '1fr';

  // The 'sidebar' column width is 640px by default.
  // - The sidebar might not be visible, in which case this value is not used.
  // - If the sidebar is visible, and the user is in a video call, this value can change depending on the layoutHorizontalBias.
  let gridColumnSidebarWidth = GRID_COLUMN_WIDTH_COLLAPSED;

  // There are two types of sidebars:
  // - one that is explicitly set as a React component,
  // - and one that is implicitly set when the user is in a video call.
  const hasAnySidebar = Boolean(sidebar) || hasJoinedCall;

  // If the user is in a video call, the width of the 'main' and 'sidebar' columns can change depending on the layoutHorizontalBias.
  const isVideoSidebarExpanded = groupthinkVideoState.layoutHorizontalBias === 'right';
  if (hasJoinedCall) {
    gridColumnMainWidth = isVideoSidebarExpanded
      ? GRID_COLUMN_WIDTH_COLLAPSED
      : 'minmax(320px, 1fr)';
    gridColumnSidebarWidth = isVideoSidebarExpanded ? '1fr' : GRID_COLUMN_WIDTH_COLLAPSED;
  }

  const gridColumns = [
    GRID_COLUMN_WIDTH_NAV,
    gridColumnMainWidth,
    ...(hasAnySidebar ? [gridColumnSidebarWidth] : []),
  ].join(' ');

  // Now that we have calculated our column layout, we can define our grid areas for lg breakpoints.
  const gridAreaLgTwoColumnLayout = `
          '${[GRID_AREA_NAV, GRID_AREA_HEADER].join(' ')}'
          '${[GRID_AREA_NAV, GRID_AREA_PRIMARY].join(' ')}'
          '${[GRID_AREA_NAV, GRID_AREA_PRIMARY].join(' ')}'
        `;

  // For layouts where a sidebar is visible, the top of the sidebar is reserved for video if and only if the user is in a call.
  const gridAreaLgSidebarAreaTop = isCallIdleOrIgnored ? GRID_AREA_SIDEBAR : GRID_AREA_VIDEO;
  const gridAreaLgThreeColumnLayout = `
          '${[GRID_AREA_NAV, GRID_AREA_HEADER, gridAreaLgSidebarAreaTop].join(' ')}'
          '${[GRID_AREA_NAV, GRID_AREA_PRIMARY, gridAreaLgSidebarAreaTop].join(' ')}'
          '${[GRID_AREA_NAV, GRID_AREA_PRIMARY, GRID_AREA_SIDEBAR].join(' ')}'
        `;

  // Determine if a two or three column layout is needed.
  let gridAreaLg = hasAnySidebar ? gridAreaLgThreeColumnLayout : gridAreaLgTwoColumnLayout;

  let gridTemplateRows = 'auto 1fr';

  if (hasVideoSidebar && !isCallIdleOrIgnored) {
    gridTemplateRows = 'auto 1fr 1fr';
  }

  return (
    <Box
      className="grid-container-layout"
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          lg: gridColumns,
        },
        gridTemplateRows: {
          xs: '48px auto 0 1fr',
          lg: gridTemplateRows,
        },
        gridTemplateAreas: lgPlus ? gridAreaLg : gridArea,
        height: '100vh',
        maxHeight: '100vh',
        overflow: 'hidden',
        minHeight: { xs: '400px', lg: '200px' },
        backgroundColor: 'groupthinkNeutral.200',
        '.mobile-layout-head': {
          xs: { display: 'initial', transform: 'none' },
          lg: { display: 'none' },
        },
        '.layout-sidebar': {
          xs: {
            transform: mobileShow === 'sidebar' ? 'translateX(0)' : 'translateX(100%)',
            transition: 'transform .3s ease-in-out',
          },
          lg: { display: 'initial', transform: 'none' },
        },
        '.layout-navigation': {
          xs: {
            transform: mobileShow === 'navigation' ? 'translateX(0)' : 'translateX(-100%)',
            transition: 'transform .3s ease-in-out',
          },
          lg: { display: 'initial', transform: 'none' },
        },
        '.layout-video': {
          xs: {
            zIndex: '100',
            transform: mobileShow === 'video' ? 'translateY(0)' : 'translateY(-150%)',
            transition: 'transform .3s ease-in-out',
          },
          lg: { display: 'initial', transform: 'none' },
        },
      }}>
      {children}
    </Box>
  );
}
