import { selectMeetingUrl, useActiveMeetings, useAgenda } from '@groupthinkai/groupthink';
import { ArrowForward as ArrowForwardIcon, Call as CallIcon } from '@mui/icons-material';
import { Alert, Box, CircularProgress, Grid, IconButton, Link, Stack, Typography } from '@mui/joy';
import * as NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import ActiveMeetingsList from '@/components/agenda/meeting/ActiveMeetingsList';
import { Modal } from '@/components/reusable/Modal';
import { useCall } from '@/hooks/call';

export default function ActiveMeetingsStatusBar() {
  const { state: callState, selectors } = useCall();
  const hasJoinedCall = selectors.selectHasJoinedCall(callState);

  if (hasJoinedCall) {
    return;
  }

  return <PortableActiveMeetingsStatusBar />;
}

// for use in non-call contexts
export function PortableActiveMeetingsStatusBar({ children }) {
  const { activeMeetings, isLoading } = useActiveMeetings();
  const router = useRouter();
  const agendaId = router.query.agenda;

  const [showActiveMeetingsModal, setShowActiveMeetingsModal] = React.useState(false);

  if (isLoading || activeMeetings?.length === 0) {
    return null;
  }

  // if the agenda ID is in any of the active meetings, return
  if (activeMeetings?.find((meeting) => meeting.agenda_id === agendaId)) {
    return;
  }

  if (activeMeetings?.length === 1) {
    return (
      <>
        {children}
        <MeetingInProgressBar meeting={activeMeetings[0]} />
      </>
    );
  }

  return (
    <>
      {children}
      <ProminentStatusBar>
        <Typography color="#FFFFFF" sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>
          <Link
            underline="hover"
            color={'#FFFFFF'}
            component="button"
            onClick={() => setShowActiveMeetingsModal(true)}>
            {`${activeMeetings?.length ?? ''} Meetings in Progress!`}
          </Link>
        </Typography>
      </ProminentStatusBar>
      <Modal
        title="Meetings in Progress"
        open={showActiveMeetingsModal}
        onClose={() => setShowActiveMeetingsModal(false)}>
        <Grid container>
          <Grid xs={12}>
            <ActiveMeetingsList />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

const MeetingInProgressBar = ({ meeting }) => {
  const agendaId = meeting.agenda_id;
  const { agenda, isLoading: isLoadingAgenda } = useAgenda(agendaId);
  const { state: callState } = useCall();

  const meetingUrl = agenda?.active_meeting
    ? selectMeetingUrl(agenda?.active_meeting)
    : `/agendas/${agendaId}`;

  if (isLoadingAgenda) {
    return null;
  }

  return (
    <ProminentStatusBar
      startDecorator={
        <CircularProgress
          size="sm"
          color="danger"
          value={40}
          sx={{
            '& .MuiCircularProgress-progress': {
              animationDuration: '3s',
            },
          }}>
          <CallIcon sx={{ width: 14, height: 14 }} />
        </CircularProgress>
      }>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography level="body-md" fontWeight="lg">
          <Link underline="hover" href={meetingUrl} component={NextLink} disabled={isLoadingAgenda}>
            {agenda?.name ?? 'Meeting'} in Progress
          </Link>
        </Typography>
        {callState.isCallActiveInOtherTab && (
          <Typography level="body-sm" color="text.secondary">
            You have joined this meeting in another tab.
          </Typography>
        )}
        <IconButton
          sx={{ px: 1 }}
          variant="solid"
          size={'xs'}
          href={meetingUrl}
          component={NextLink}>
          <ArrowForwardIcon />
        </IconButton>
      </Stack>
    </ProminentStatusBar>
  );
};

function ProminentStatusBar({ children, startDecorator = null, endDecorator = null }) {
  return (
    <Alert
      variant="soft"
      color="tertiary"
      invertedColors
      startDecorator={startDecorator}
      endDecorator={endDecorator}
      sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Alert>
  );
}
