import { selectMeetingUrl, useActiveMeetings, useAgenda } from '@groupthinkai/groupthink';
import { Button, Card, Link, Stack, Typography } from '@mui/joy';
import * as React from 'react';

export default function ActiveMeetingsList() {
  const { activeMeetings, isLoading } = useActiveMeetings();

  if (isLoading) {
    return null;
  }

  if (activeMeetings?.length > 0) {
    return activeMeetings?.map((meeting) => (
      <React.Fragment key={`active-meetings-${meeting.id}`}>
        <ActiveMeetingCard meeting={meeting} />
      </React.Fragment>
    ));
  }

  return null;
}

function ActiveMeetingCard({ meeting }) {
  const agendaId = meeting.agenda_id;
  const { agenda, isLoading: isLoadingAgenda } = useAgenda(agendaId);

  const meetingUrl = agenda?.active_meeting
    ? selectMeetingUrl(agenda?.active_meeting)
    : `/agendas/${agendaId}`;

  return (
    <Card key={meeting.id} sx={{ my: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>{meeting.name}</Typography>
        <Button
          component={Link}
          variant="solid"
          color="primary"
          size="sm"
          href={meetingUrl}
          loading={isLoadingAgenda}
          disabled={isLoadingAgenda}>
          Go to Meeting
        </Button>
      </Stack>
    </Card>
  );
}
