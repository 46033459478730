import {
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  Notes as NotesIcon,
} from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import { Avatar, Badge, Box, Card, Link, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';

import AttachmentsPreview from '@/components/messages/AttachmentsPreview';
import FormattedMarkdown from '@/components/messages/Markdown';
import MessageEditor from '@/components/messages/MessageEditor';
import MessageSubActions from '@/components/messages/MessageSubActions';

dayjs.extend(isToday);
dayjs.extend(relativeTime);

interface MessageContentsProps {
  message: Groupthink.MessageResource;
  setActiveThread?: (threadId: string) => void;

  /**
   * Whether the message is adjacent to another message sent by the same user.
   * Used to determine if the avatar and other items in the "message header" should be displayed.
   */
  adjacent: boolean;

  /**
   * Whether the message is part of a thread and can be replied to.
   */
  threadable: boolean;
  threadMessageCount?: number;

  medium?: 'chat' | 'transcription' | 'note';

  /**
   * Whether the message is currently being edited.
   */
  isEditing?: boolean;

  /**
   * Callback to be called when the message is done being edited.
   */
  onEditingCompleted?: () => void;

  /**
   * Whether the message is the root of an unread thread.
   */
  hasUnread?: boolean;

  /**
   * The layout of the message.
   */
  layout?: 'chat' | 'comment';
}
export default function MessageContents(props: MessageContentsProps) {
  const {
    message,
    setActiveThread = () => {},
    onEditingCompleted = () => {},
    medium = 'chat',
    adjacent = false,
    threadable = false,
    threadMessageCount = 0,
    isEditing = false,
    hasUnread = false,
    layout = 'chat',
  } = props;

  const adjacentEligible = layout !== 'comment';

  const [expanded, setExpanded] = useState(null);

  if (!message) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
          }}>
          <Box sx={{ width: '2rem' }} className="avatar-container">
            {adjacentEligible && adjacent ? (
              <MessageTimestamp message={message} simple />
            ) : message.sent_by ? (
              <Badge
                variant="plain"
                badgeContent={medium === 'note' ? <NotesIcon /> : medium === 'chat' ? null : null}
                size="sm"
                invisible={medium !== 'note' && medium !== 'chat'}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Avatar
                  size="sm"
                  src={message.sent_by.id === 0 ? `/gt_avatar.png` : message.sent_by.avatar}
                  srcSet={message.sent_by.id === 0 ? `/gt_avatar.png` : message.sent_by.avatar}
                  alt={message.sent_by.name}
                  sx={{ mt: 0.5 }}
                />
              </Badge>
            ) : null}
          </Box>
          <Box
            flex={1}
            sx={{
              ml: 1,
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              maxWidth: '100%',
              userSelect: 'text',
            }}>
            {(!adjacent || layout === 'comment') && (
              <Typography
                level="body-sm"
                fontWeight="lg"
                textColor="text.primary"
                noWrap
                endDecorator={<MessageTimestamp message={message} />}>
                {message.sent_by?.name}
              </Typography>
            )}
            {isEditing ? (
              <MessageEditor message={message} onEditingCompleted={onEditingCompleted} />
            ) : (
              // @ts-ignore
              <FormattedMarkdown textSize={'body-sm'}>{message.content}</FormattedMarkdown>
            )}

            {message.attachments?.length > 0 && (
              <Box>
                {message.attachments && <AttachmentsPreview attachments={message.attachments} />}
              </Box>
            )}

            {!isEditing && threadable && threadMessageCount > 1 && (
              <Typography fontSize="12px" textColor="neutral">
                <Link onClick={() => setActiveThread(message.thread_id)}>
                  {threadMessageCount > 1 && hasUnread && <CircleIcon />}
                  <span style={{ paddingLeft: hasUnread ? '5px' : '0px' }}>
                    {threadMessageCount - 1} {threadMessageCount - 1 > 1 ? 'replies' : 'reply'}
                  </span>
                </Link>
              </Typography>
            )}
            {!isEditing && message.extra && <MessageSubActions message={message} />}
            {!isEditing && message.extra ? (
              <Link
                fontSize="12px"
                onClick={() => setExpanded(expanded !== null ? null : message.id)}>
                {expanded === message.id ? 'Hide Extra' : 'Show Extra'}
              </Link>
            ) : null}
            {!isEditing && expanded === message.id ? (
              <Card variant="plain" sx={{ mt: 2, color: '#000000', p: 1 }}>
                {/* @ts-ignore */}
                <FormattedMarkdown compacttextSize="body-xs">{message.extra}</FormattedMarkdown>
              </Card>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const MessageTimestamp = ({ message, simple = false, includeDate = false }) => {
  const createdAt = dayjs(message.created_at);

  const timestampFormatString = simple ? 'h:mm' : includeDate ? 'MMM D, h:mm A' : 'h:mm A';

  const formattedTime =
    createdAt.isToday() && !simple ? createdAt.fromNow() : createdAt.format(timestampFormatString);

  return (
    <Typography
      className="message-timestamp"
      level="body-sm"
      sx={{ fontSize: '12px', opacity: simple ? 0 : 1, userSelect: 'none' }}
      textColor="text.tertiary"
      title={createdAt.toDate().toLocaleString('en-us')}>
      {formattedTime}
    </Typography>
  );
};
