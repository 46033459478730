import CallSettings from './CallSettings';

import { Modal } from '@/components/reusable';

const CallSettingsModal = ({ open, setOpen, agendaId, meetingId }) => {
  return (
    <Modal title="Call Settings" open={open} onClose={() => setOpen(false)}>
      <CallSettings agendaId={agendaId} meetingId={meetingId} />
    </Modal>
  );
};

export default CallSettingsModal;
