import {
  useAgenda,
  useAgendaUsers,
  useMeetings,
  useOrganization,
  useUser,
} from '@groupthinkai/groupthink';
import {
  ArrowDropDown as ArrowDropDownIcon,
  Close as CloseIcon,
  Videocam as VideocamIcon,
  WarningOutlined as WarningOutlinedIcon,
} from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import StartMeetingModal from '@/components/agenda/StartMeetingModal';
import { useCall } from '@/hooks/call';

export default function StartMeetingActions({ organizationId, agendaId }) {
  const { user } = useUser('me');
  const { users } = useAgendaUsers(agendaId);
  let user_may_attend = !!users?.[user?.id]?.role !== 'observer';

  if (user_may_attend) {
    return <AttendeeHeaderActions organizationId={organizationId} agendaId={agendaId} />;
  }

  return null;
}

function AttendeeHeaderActions({ organizationId, agendaId }) {
  const router = useRouter();
  const { agenda, mutate } = useAgenda(agendaId);
  const [isCreating, setIsCreating] = React.useState(false);
  const [openStartMeeting, setOpenStartMeeting] = React.useState(false);
  const [conferenceUrl, setConferenceUrl] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const actionRef = React.useRef(null);
  const anchorRef = React.useRef(null);
  const theme = useTheme();
  const mdPlus = useMediaQuery(theme.breakpoints.up('md'));
  const stackDirection = mdPlus ? 'row' : 'column';

  const { state: callState, selectors } = useCall();
  const isVideoInSidebar = selectors.selectIsVideoInSidebar(callState);

  const { organization } = useOrganization(organizationId);

  const groupthinkVideoEnabled = organization?.features?.groupthink_video;

  const { createMeeting } = useMeetings(agendaId);

  const activeMeeting = agenda?.active_meeting;
  const hasActiveMeeting = Boolean(activeMeeting);

  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (errors?.meeting || errors?.error) {
      setSnackbarOpen(true);
    }
  }, [errors]);

  const startExternalMeeting = (url = null) => {
    createMeeting({
      payload: {
        conference_url: url ?? conferenceUrl,
        source: 'agenda-header-start',
        start: true,
        workspace_id: agenda?.workspace.id,
      },
      setIsCreating,
      onSuccess: () => {
        router.push(`/agendas/${agendaId}`);
      },
    });
  };

  const startGroupthinkMeeting = () => {
    createMeeting({
      payload: {
        source: 'agenda-header-start',
        start: true,
        workspace_id: agenda?.workspace.id,
      },
      setErrors,
      setIsCreating,
      onSuccess: () => {
        mutate(); // TODO: Don't remote revalidate, just merge incoming meeting
        router.push(`/agendas/${agendaId}`);
      },
    });
  };

  const handleStartMeetingClick = () => {
    const { driver, url } = (agenda?.attached_event ?? agenda?.next_meeting)?.conferencing ?? {};
    if (driver && driver !== 'groupthink') {
      setConferenceUrl(url);
      startExternalMeeting(url);
    } else {
      startGroupthinkMeeting();
    }
  };

  if (!agenda) return null;

  const standardStartMeetingButton = (
    <ButtonGroup variant="outlined" color="neutral">
      <Button
        loading={isCreating}
        fullWidth={stackDirection === 'column'}
        color="neutral"
        disabled={isCreating}
        onClick={() => setOpenStartMeeting(true)}
        startDecorator={<VideocamIcon />}>
        Start Meeting...
      </Button>
    </ButtonGroup>
  );

  const groupthinkVideoSplitStartMeetingButton = (
    <React.Fragment>
      <ButtonGroup variant="outlined" color="neutral" ref={anchorRef}>
        <Button
          loading={isCreating}
          fullWidth={stackDirection === 'column'}
          color="neutral"
          disabled={isCreating}
          onClick={handleStartMeetingClick}
          startDecorator={<VideocamIcon />}>
          Start Meeting
        </Button>
        <IconButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Select alternative meeting provider"
          aria-haspopup="menu"
          onMouseDown={() => {
            actionRef.current = () => setOpen(!open);
          }}
          onKeyDown={() => {
            actionRef.current = () => setOpen(!open);
          }}
          onClick={() => {
            actionRef.current?.();
          }}>
          <ArrowDropDownIcon />
        </IconButton>
      </ButtonGroup>
      <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <MenuItem
          onClick={() => {
            startGroupthinkMeeting();
            setOpen(false);
          }}>
          Start Groupthink Video Meeting
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenStartMeeting(true);
            setOpen(false);
          }}>
          Start Zoom, Meet, or Teams Meeting
        </MenuItem>
      </Menu>
    </React.Fragment>
  );

  const groupthinkVideoMeetingUrl = `/agendas/${agenda?.id}`;

  const goToActiveMeetingButton =
    activeMeeting?.conferencing?.driver === 'groupthink' ? (
      <ButtonGroup variant="outlined" color="neutral">
        <Button
          component={Link}
          startDecorator={<VideocamIcon />}
          href={groupthinkVideoMeetingUrl}
          fullWidth={stackDirection === 'column'}>
          Go to Active Meeting
        </Button>
      </ButtonGroup>
    ) : (
      <Button component={Link} href={`/agendas/${agendaId}/meetings/${activeMeeting?.id}`}>
        Go to Active Meeting
      </Button>
    );

  const startMeetingButton = groupthinkVideoEnabled
    ? groupthinkVideoSplitStartMeetingButton
    : standardStartMeetingButton;

  return (
    <Stack direction={stackDirection} spacing={1}>
      {/* TODO: Offer to end current meeting and start/join another meeting when video in sidebar (e.g. video does not match current agenda) */}
      {isVideoInSidebar ? null : hasActiveMeeting ? goToActiveMeetingButton : startMeetingButton}

      <StartMeetingModal
        open={openStartMeeting}
        setOpen={setOpenStartMeeting}
        conferenceUrl={conferenceUrl}
        setConferenceUrl={setConferenceUrl}
        isCreating={isCreating}
        onStartMeeting={startExternalMeeting}
        onCreateGroupthinkVideo={startGroupthinkMeeting}
        groupthinkVideoEnabled={groupthinkVideoEnabled}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        color="danger"
        variant="outlined"
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        endDecorator={
          <IconButton aria-label="Dismiss" onClick={() => setSnackbarOpen(false)} color="danger">
            <CloseIcon />
          </IconButton>
        }
        autoHideDuration={4000}>
        <Typography level="body-md" color="danger" startDecorator={<WarningOutlinedIcon />}>
          {errors?.meeting ?? 'Could not start meeting.'}
        </Typography>
      </Snackbar>
    </Stack>
  );
}
