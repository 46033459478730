import { useCalendar } from '@groupthinkai/groupthink';
import { CircularProgress } from '@mui/joy';
import Box from '@mui/joy/Box';

import CalendarSettings from '@/components/calendar/CalendarSettings';
import ConnectCalendar from '@/components/calendar/ConnectCalendar';

export default function SetupCalendar({ organization, direction = 'row', showSettings = true }) {
  const { isLoading } = useCalendar(organization?.id, organization?.calendar_id);

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ mt: 2 }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size="lg" />
          </Box>
        ) : (
          <>
            <ConnectCalendar direction={direction} organization={organization} setup={true} />
            {showSettings && <CalendarSettings organization={organization} simple={true} />}
          </>
        )}
      </Box>
    </Box>
  );
}
