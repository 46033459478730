import { Alert, Box, Button, Card, CardContent, Divider, Link, Table, Typography } from '@mui/joy';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { HoverCardById } from '@/components/users/UserCard';
import { copyTextToClipboard } from '@/hooks/util';

const getUserId = (mention) => parseInt(mention.replace('/u/', ''));

export default function FormattedMarkdown({
  children,
  onMessage,
  textSize = 'body-sm',
  compact = false,
}) {
  const handleCopyText = React.useCallback(() => {
    copyTextToClipboard(children);
  }, [children]);

  return (
    <div>
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkBreaks]}
        components={{
          a: ({ ...props }) => {
            const childContent = React.Children.toArray(props.children)
              .map((child) => (typeof child === 'string' ? child : ''))
              .join('');

            if (childContent.includes('BTN:')) {
              let btnText = childContent.replace('BTN:', '');
              return (
                <Button
                  variant="outlined"
                  {...props}
                  style={{ wordBreak: 'break-word' }}
                  target="_blank">
                  {btnText}
                </Button>
              );
            }

            if (props.href.includes('#message:')) {
              return (
                <Link
                  {...props}
                  onClick={(e) => {
                    e.preventDefault();
                    onMessage ? onMessage(props.href.replace('#message:', '')) : null;
                  }}
                  sx={{
                    '&:hover': { textDecoration: 'none' },
                    wordBreak: 'break-word',
                    fontWeight: 'bold',
                    borderBottom: 'solid 1px',
                    paddingBottom: '2px',
                  }}
                  target="_blank"
                />
              );
            }

            // Handle @-mentions, which will usually link to `/u/:userId`.
            if (childContent.startsWith('@')) {
              const userId = getUserId(props.href);

              if (userId === 0 || userId === '0') {
                // Groupthink does not have a public profile! Their mention will look like everyone else's, but it won't be clickable.
                return (
                  <Typography
                    textColor="#bf045b"
                    sx={{
                      '&:hover': { textDecoration: 'none', cursor: 'pointer' },
                      wordBreak: 'break-word',
                      paddingBottom: '2px',
                      backgroundColor: '#f2cdde',
                      paddingX: 0.25,
                    }}>
                    {props.children}
                  </Typography>
                );
              }
              return (
                <HoverCardById userId={userId}>
                  <Link
                    {...props}
                    sx={{
                      '&:hover': { textDecoration: 'none' },
                      wordBreak: 'break-word',
                      paddingBottom: '2px',
                      backgroundColor: '#f2cdde',
                      paddingX: 0.25,
                    }}
                    target="_blank"
                  />
                </HoverCardById>
              );
            }

            return (
              <Link
                {...props}
                sx={{
                  '&:hover': { textDecoration: 'none' },
                  wordBreak: 'break-word',
                  paddingBottom: '2px',
                }}
                target="_blank"
              />
            );
          },
          hr: () => {
            return <Divider style={{ margin: '1rem 0' }} />;
          },
          img: ({ ...props }) => {
            return (
              <img
                {...props}
                style={{
                  maxHeight: '120px',
                }}
              />
            );
          },
          p: ({ children }) => {
            return (
              <Typography className="markdown-p" level={textSize} gutterBottom={!compact}>
                {children}
              </Typography>
            );
          },
          li: ({ children }) => {
            return (
              <Box component={'li'} sx={{ '&::marker': { fontSize: '14px' }, my: 0.5 }}>
                <Typography className="markdown-p" level={textSize}>
                  {children}
                </Typography>
              </Box>
            );
          },
          table: ({ children }) => {
            return <Table>{children}</Table>;
          },
          blockquote: ({ ...props }) => {
            return (
              <Alert
                severity="info"
                sx={{
                  marginBottom: '1rem',
                  '& > .MuiAlert-message': { padding: '2px 0' },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}>
                {props.children}
              </Alert>
            );
          },
          code({ inline, className, children, ...props }) {
            if (inline) {
              return (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            }

            const match = /language-(\w+)/.exec(className || '');

            let code_label = '';

            if (Boolean(match) && match[1] === 'label') {
              let label = className.split('-').slice(2).join(' ');
              code_label = (
                <Typography gutterBottom level="subtitle2">
                  {label}
                </Typography>
              );
            }

            return (
              <>
                <Card
                  variant="outlined"
                  elevation={0}
                  style={{
                    overflow: 'scroll',
                    '&::WebkitScrollbar': {
                      display: 'none',
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                  }}>
                  <Link overlay onClick={handleCopyText}>
                    <CardContent>
                      {code_label}
                      <Typography level="subtitle2">
                        <pre
                          style={{
                            margin: 0,
                            display: 'table',
                            tableLayout: 'fixed',
                            width: '100%',
                          }}
                          className={className}
                          {...props}>
                          {children}
                        </pre>
                      </Typography>
                    </CardContent>
                  </Link>
                </Card>
                <Typography
                  level="caption"
                  display="block"
                  gutterBottom
                  onClick={handleCopyText}
                  sx={{ cursor: 'pointer' }}>
                  Click to copy
                </Typography>
              </>
            );
          },
        }}>
        {children}
      </ReactMarkdown>
    </div>
  );
}
