import { useDaily, useDevices, useLocalSessionId, useVideoTrack } from '@daily-co/daily-react';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import Badge from '@mui/joy/Badge';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Switch from '@mui/joy/Switch';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';

import CameraPermissionsSnackbar from '@/components/video/CameraPermissionsSnackbar';

export default function VideoControlButton({ toggle, children, badgeOnFault = false }) {
  const daily = useDaily();

  const localSessionId = useLocalSessionId();
  const localVideo = useVideoTrack(localSessionId);
  const { hasCamError, camState } = useDevices();
  const [isCameraPermissionsSnackbarOpen, setIsCameraPermissionsSnackbarOpen] = useState(false);

  const toggleVideo = () => {
    if (localVideo.blocked) {
      setIsCameraPermissionsSnackbarOpen(true);
    } else {
      daily.setLocalVideo(localVideo.isOff);
    }
  };

  let label = hasCamError ? 'Error' : localVideo.isOff ? 'Turn camera on' : 'Turn camera off';

  // Use a specific error message, if available
  if (camState === 'blocked') {
    label = 'Allow camera access to turn on';
  } else if (camState === 'in-use') {
    label = 'Camera already in use by another app';
  } else if (camState === 'not-found') {
    label = 'No camera found';
  }

  const isLoadingCamera = ['idle', 'pending'].includes(camState);
  const videoFault = localVideo.blocked || localVideo.state === 'interrupted';
  const videoOn = !localVideo.isOff && !videoFault;
  const color = isLoadingCamera
    ? 'neutral'
    : localVideo.isOff
    ? videoFault
      ? 'danger'
      : 'tertiary'
    : 'primary';

  const sx = {
    fontSize: '1.5rem',
  };

  if (toggle) {
    return (
      <>
        <Typography
          component={'div'}
          startDecorator={
            <Tooltip title={label} placement="top">
              <Switch checked={videoOn} onChange={toggleVideo} />
            </Tooltip>
          }>
          {children ?? 'Camera'}
        </Typography>
        <CameraPermissionsSnackbar
          open={isCameraPermissionsSnackbarOpen}
          setOpen={setIsCameraPermissionsSnackbarOpen}
        />
      </>
    );
  }

  return (
    <>
      <Tooltip title={label} placement="top" arrow>
        <IconButton onClick={toggleVideo} color={color} aria-label={label}>
          <Badge badgeContent="!" invisible={!hasCamError || !badgeOnFault}>
            {videoOn ? <VideocamOutlinedIcon sx={sx} /> : <VideocamOffOutlinedIcon sx={sx} />}
            {children && <Box mx={1}>{children}</Box>}
          </Badge>
        </IconButton>
      </Tooltip>
      <CameraPermissionsSnackbar
        open={isCameraPermissionsSnackbarOpen}
        setOpen={setIsCameraPermissionsSnackbarOpen}
      />
    </>
  );
}
