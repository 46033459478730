import { Button, FormControl, Input } from '@mui/joy';

import { Modal } from '@/components/reusable';

const AddConferencingModal = ({
  open,
  setOpen,
  conferenceUrl,
  setConferenceUrl,
  isCreating,
  onAddConferencing,
}) => {
  return (
    <Modal
      title="Add Conferencing to your Meeting"
      description="Add a Zoom, Meet, or Teams meeting URL and Groupthink will automatically join and take notes
    for you."
      open={open}
      onClose={() => setOpen(false)}>
      <form onSubmit={onAddConferencing} disabled={isCreating}>
        <FormControl required sx={{ my: 2 }}>
          <Input
            value={conferenceUrl}
            type="email"
            onChange={(e) => setConferenceUrl(e.target.value)}
            placeholder="Optional - Zoom, Meet, or Teams URL (include the password in the URL)"
          />
        </FormControl>
        <Button loading={isCreating} disabled={isCreating} onClick={onAddConferencing}>
          Add Conferencing
        </Button>
      </form>
    </Modal>
  );
};

export default AddConferencingModal;
