import { useAgenda } from '@groupthinkai/groupthink';
import { MailOutline as MailIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/joy';
import React, { useCallback, useEffect, useState } from 'react';

import { usePrevious } from '@/hooks/util';

const ShareAgenda = ({ agendaId, showTitle = true, onInvite = () => {} }) => {
  const { inviteUser, isCurrentUserAgendaEditor } = useAgenda(agendaId);
  const [attendees, setAttendees] = useState([]);
  const previousAttendeesLength = usePrevious(attendees.length);
  const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');
  const [attendeesInputValue, setAttendeesInputValue] = useState('');
  const [errors, setErrors] = useState({});

  const [isInviting, setIsInviting] = React.useState(false);
  const wasInviting = usePrevious(isInviting);

  useEffect(() => {
    if (previousAttendeesLength > 0 && attendees.length === 0 && wasInviting) {
      setIsInviting(false);
      onInvite();
    }
  }, [isInviting, attendees]);

  const handleInviteAgendaUsers = useCallback(async () => {
    setIsInviting(true);
    await Promise.allSettled(
      attendees.map((email) =>
        inviteUser({
          payload: { email, role: 'participant' },
          setErrors,
          onSuccess: () => {
            setAttendees(attendees.filter((attendee) => attendee !== email));
          },
        })
      )
    );
    setIsInviting(false);
  }, [attendees]);

  useEffect(() => {
    if (errors) {
      let newErrors = {};
      Object.keys(errors).forEach((key) => {
        const [field, index] = key.split('.');
        if (index) {
          if (!newErrors[field]) {
            newErrors[field] = [];
          }
          newErrors[field].push(errors[key]);
        } else {
          newErrors[field] = errors[key];
        }
      });
      setErrors(newErrors);
    }
  }, [JSON.stringify(errors)]);

  return (
    <Box>
      {isCurrentUserAgendaEditor && (
        <>
          {showTitle && (
            <Typography level="body-lg" fontWeight="lg" gutterBottom>
              Invite by email
            </Typography>
          )}
          <FormControl sx={{ mb: 2 }}>
            <Autocomplete
              startDecorator={<MailIcon />}
              value={attendees}
              options={[]}
              onChange={(event, newValue) => {
                // test the last value to see if it's an email
                if (emailRegex.test(newValue[newValue.length - 1]) || newValue.length === 0) {
                  setAttendees(newValue);
                }
              }}
              onBlur={() => {
                // Add attendees onBlur if the input value is an email address
                const potentialEmail = attendeesInputValue.replace(/[, ]/g, '');

                if (emailRegex.test(potentialEmail)) {
                  setAttendees([...attendees, potentialEmail]);

                  // only clear the input if it's a valid email address
                  setAttendeesInputValue('');
                }
              }}
              multiple
              freeSolo
              placeholder="Email Addresses"
              onInputChange={(event, newInputValue) => {
                // This function handles commas and spaces the same way autocomplete natively handles the enter button
                // if the current string ends in a comma or space
                const lastCharacter = newInputValue[newInputValue.length - 1];
                if (lastCharacter === ',' || lastCharacter === ' ') {
                  // strip commas and spaces
                  const potentialEmail = newInputValue.replace(/[, ]/g, '');

                  // if it's an email address, add it to the list
                  if (emailRegex.test(potentialEmail)) {
                    setAttendees([...attendees, potentialEmail]);
                  }

                  // either way, clear the input
                  setAttendeesInputValue('');
                } else {
                  // otherwise, update the input value
                  setAttendeesInputValue(newInputValue);
                }
              }}
              inputValue={attendeesInputValue}
            />

            {errors['attendees'] && (
              <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                {errors['attendees'].join(' ')}
              </FormHelperText>
            )}
            <FormHelperText>Press &quot;enter&quot; after each valid email address</FormHelperText>
          </FormControl>

          <Stack
            alignItems={{ xs: 'stretch', lg: 'center' }}
            mt={2}
            spacing={{ xs: 2, lg: 0 }}
            flexDirection={{ xs: 'column', lg: 'row' }}
            justifyContent={'space-between'}>
            <Typography level="body-sm">
              Groupthink will email everyone with instructions on how to join this meeting.
            </Typography>
            <Button
              sx={{ minWidth: '120px' }}
              loading={isInviting}
              disabled={isInviting || attendees.length < 1}
              onClick={handleInviteAgendaUsers}>
              Invite{attendees.length > 1 ? ' All' : ''}
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default ShareAgenda;
