import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Box,
  Card,
  CardCover,
  Typography,
  IconButton,
  Modal,
  Sheet,
  Link as JoyLink,
} from '@mui/joy';
import React, { useState } from 'react';

interface Attachment {
  id: number;
  name: string;
  mime_type: string;
  url: string;
}

interface AttachmentsPreviewProps {
  attachments: Attachment[];
}

export default function AttachmentsPreview({ attachments }: AttachmentsPreviewProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  if (!attachments || attachments.length === 0) return null;

  // Only image attachments are navigable within the modal
  const imageAttachments = attachments.filter((att) => att.mime_type.startsWith('image/'));

  const openModalForAttachment = (index: number) => {
    setSelectedIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const goToNextImage = () => {
    if (imageAttachments.length > 1) {
      const currentId = imageAttachments[selectedIndex].id;
      const currentPos = imageAttachments.findIndex((a) => a.id === currentId);
      const nextPos = (currentPos + 1) % imageAttachments.length;
      setSelectedIndex(nextPos);
    }
  };

  const goToPrevImage = () => {
    if (imageAttachments.length > 1) {
      const currentId = imageAttachments[selectedIndex].id;
      const currentPos = imageAttachments.findIndex((a) => a.id === currentId);
      const prevPos = (currentPos - 1 + imageAttachments.length) % imageAttachments.length;
      setSelectedIndex(prevPos);
    }
  };

  const selectedAttachment = imageAttachments[selectedIndex] || null;
  const isImageSelected = selectedAttachment?.mime_type.startsWith('image/');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          mt: 1,
        }}>
        {attachments.map((attachment) => {
          const isImage = attachment.mime_type.startsWith('image/');
          if (isImage) {
            return (
              <Card
                key={attachment.id}
                variant="outlined"
                onClick={() =>
                  openModalForAttachment(imageAttachments.findIndex((a) => a.id === attachment.id))
                }
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: '8px',
                  overflow: 'hidden',
                  position: 'relative',
                  flexShrink: 0,
                  mr: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: 'md',
                  },
                }}>
                <CardCover>
                  <img
                    src={attachment.url}
                    alt={attachment.name}
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  />
                </CardCover>
              </Card>
            );
          } else {
            // Non-image file preview
            return (
              <Card
                key={attachment.id}
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 100,
                  height: 100,
                  borderRadius: '8px',
                  overflow: 'hidden',
                  p: 1,
                  mr: 1,
                  gap: 1,
                  textAlign: 'center',
                }}>
                <InsertDriveFileIcon fontSize="large" />
                <Typography
                  level="body-sm"
                  sx={{
                    fontSize: '0.75rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}>
                  {attachment.name}
                </Typography>
                <JoyLink
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  level="body-xs"
                  underline="none"
                  sx={{ fontSize: '0.75rem', textDecoration: 'underline', cursor: 'pointer' }}>
                  DOWNLOAD
                </JoyLink>
              </Card>
            );
          }
        })}
      </Box>

      {/* Modal for image preview */}
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="attachment-modal-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            position: 'relative',
            width: '90vw',
            height: '90vh',
            background: 'rgba(0,0,0,0.9)',
            borderRadius: '8px',
            overflow: 'hidden', // So arrows aren't clipped
            display: 'flex',
            flexDirection: 'column',
            p: 1,
          }}
          onClick={(e) => e.stopPropagation()} // Prevent backdrop click
        >
          {/* Toolbar */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pb: 1,
              justifyContent: 'space-between',
            }}>
            <Typography
              id="attachment-modal-title"
              level="body-sm"
              sx={{ color: '#fff', fontWeight: 'bold' }}>
              {selectedAttachment?.name}
            </Typography>
            <Box>
              {isImageSelected && (
                <JoyLink
                  href={selectedAttachment?.url}
                  target="_blank"
                  download
                  sx={{ color: '#fff', mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                  <DownloadIcon fontSize="small" sx={{ mr: 0.5 }} /> Download
                </JoyLink>
              )}
              <IconButton variant="soft" onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Image Content Area */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'auto', // Only this area scrolls
            }}>
            {isImageSelected ? (
              <img
                src={selectedAttachment?.url}
                alt={selectedAttachment?.name}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  borderRadius: '4px',
                }}
              />
            ) : (
              <Typography level="body-sm" sx={{ color: '#fff' }}>
                This file cannot be previewed.{' '}
                <JoyLink
                  href={selectedAttachment?.url}
                  target="_blank"
                  rel="noopener"
                  sx={{ color: '#fff', textDecoration: 'underline' }}>
                  Open in a new tab
                </JoyLink>
              </Typography>
            )}
          </Box>

          {/* Navigation Arrows (if multiple images) */}
          {imageAttachments.length > 1 && (
            <>
              <IconButton
                onClick={goToPrevImage}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '0.5rem',
                  transform: 'translateY(-50%)',
                  zIndex: 10,
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                }}>
                <ArrowBackIosNewIcon fontSize="large" htmlColor="#fff" />
              </IconButton>
              <IconButton
                onClick={goToNextImage}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '0.5rem',
                  transform: 'translateY(-50%)',
                  zIndex: 10,
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                }}>
                <ArrowForwardIosIcon fontSize="large" htmlColor="#fff" />
              </IconButton>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}
