import Daily from '@daily-co/daily-js';
import {
  useDevices,
  useInputSettings,
  useLocalSessionId,
  useMeetingState,
  useDaily,
} from '@daily-co/daily-react';
import { isElectron, useUser } from '@groupthinkai/groupthink';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { Divider } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';

import MeetingLink from '@/components/agenda/MeetingLink';
import CallSettings from '@/components/video/CallSettings';
import VideoPreview from '@/components/video/VideoPreview';
import { useCall } from '@/hooks/call';

export default function HairCheck({
  meetingName = null,
  onClose,
  shouldClose = false,
  agendaId = null,
  meetingId = null,
}) {
  const { user } = useUser('me');
  const router = useRouter();
  const callObject = useDaily();
  const { hasCamError, hasMicError, camState, micState } = useDevices();
  const { updateInputSettings } = useInputSettings();
  const { supportsAudioProcessing } = Daily.supportedBrowser();
  const meetingState = useMeetingState();
  const cancelCameraEnablingRef = useRef(false);

  const {
    state: callState,
    selectors,
    startCamera,
    joinRoom,
    closeHaircheckWithoutJoining,
  } = useCall();
  const isInHaircheck = selectors.selectIsInHaircheck(callState);
  const isCallIdleOrIgnored = selectors.selectIsCallIdleOrIgnored(callState);
  const hasJoinedCall = selectors.selectHasJoinedCall(callState);

  const isJoiningCall = meetingState === 'joining-meeting';
  const [isJoiningFromAnotherMeeting, setIsJoiningFromAnotherCall] = React.useState(false);

  // State goes idle -> pending -> (granted | blocked)
  const isLoadingCamera = ['idle', 'pending'].includes(camState);
  const isLoadingMic = ['idle', 'pending'].includes(micState);

  const handleJoinCall = () => {
    Sentry.addBreadcrumb({
      category: 'call-haircheck',
      message: 'Joining call',
      level: 'info',
      data: { hasCamError, hasMicError, camState, micState, userId: user?.id ?? 'unknown' },
    });

    joinRoom();
  };

  const handleContinueWithoutJoining = () => {
    Sentry.addBreadcrumb({
      category: 'call-haircheck',
      message: 'Continuing without joining',
      level: 'info',
      data: { hasCamError, hasMicError, camState, micState, userId: user?.id ?? 'unknown' },
    });

    // cancel if we're in the middle of enabling
    cancelCameraEnablingRef.current = true;

    closeHaircheckWithoutJoining();

    onClose?.();
  };

  const requestPermissions = async () => {
    if (isElectron) {
      try {
        const result = await window.electron.requestMediaPermissions();
        console.log('Electron media permissions check result:', result);
      } catch (error) {
        console.error('Error checking media permissions:', error);
      }
    }
  };

  useEffect(() => {
    if (isElectron) {
      requestPermissions();
    }
  }, [isElectron]);

  useEffect(() => {
    if (hasCamError || hasMicError) {
      if (isElectron) {
        requestPermissions();
      }

      Sentry.addBreadcrumb({
        category: 'call-haircheck',
        message: 'Device error',
        level: 'error',
        data: { hasCamError, hasMicError, userId: user?.id ?? 'unknown' },
      });
    }
  }, [isElectron, hasCamError, hasMicError]);

  useEffect(() => {
    if (!isLoadingCamera && !isLoadingMic) {
      Sentry.addBreadcrumb({
        category: 'call-haircheck',
        message: 'Camera and mic loaded',
        level: 'info',
        data: { userId: user?.id ?? 'unknown' },
      });
    }
  }, [isLoadingCamera, isLoadingMic]);

  useEffect(() => {
    if (callObject && !hasJoinedCall && !isCallIdleOrIgnored) {
      startCameraAsync();
    }
  }, [callObject, isCallIdleOrIgnored, hasJoinedCall]);

  useEffect(() => {
    if (shouldClose) {
      handleContinueWithoutJoining();
    }
  }, [shouldClose]);

  useEffect(() => {
    if (isInHaircheck && callObject && router?.isReady && router.query?.skipConfirm === 'true') {
      setIsJoiningFromAnotherCall(true);
      startCameraAsync().then(() => {
        joinRoom();
      });
    }
  }, [router?.isReady, isInHaircheck, callObject]);

  async function startCameraAsync() {
    await startCamera({ withVideo: true, withAudio: true });
    if (supportsAudioProcessing) {
      console.debug('Enabling noise cancellation');
      await updateInputSettings({
        audio: {
          processor: {
            type: 'noise-cancellation',
          },
        },
      });
    }
  }

  return (
    <Stack gap={2}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
        gap={0}>
        <PreviewSettings agendaId={agendaId} meetingId={meetingId} />
      </Box>
      <Divider>
        <Typography level="title-sm">Meeting Room</Typography>
      </Divider>
      <Stack
        justifyContent="space-between"
        alignItems={'center'}
        display="flex"
        width="100%"
        gap={2}>
        {meetingName && <Typography level="h4">{meetingName}</Typography>}
        {!isJoiningFromAnotherMeeting && (
          <Button
            size="lg"
            color={!isLoadingCamera && !isLoadingMic ? 'primary' : 'neutral'}
            onClick={handleJoinCall}
            disabled={isJoiningCall || isJoiningFromAnotherMeeting}
            startDecorator={
              isLoadingCamera || isLoadingMic ? (
                <CircularProgress size="sm" color="neutral" />
              ) : !hasMicError && !hasCamError ? (
                <VideoCameraFrontOutlinedIcon />
              ) : hasMicError && hasCamError ? null : hasMicError ? (
                <MicOffIcon />
              ) : hasCamError ? (
                <VideocamOffOutlinedIcon />
              ) : (
                <CircularProgress size="sm" color="neutral" />
              )
            }>
            {!hasMicError && !hasCamError
              ? 'Join Now'
              : hasMicError && hasCamError
              ? 'Join Without Video and Audio'
              : hasMicError
              ? 'Join Without Audio'
              : hasCamError
              ? 'Join Without Video'
              : 'Turning on Camera'}
          </Button>
        )}
        <Box mt={2} sx={{ width: '100%', maxWidth: '600px' }}>
          <MeetingLink agendaId={agendaId} meetingId={meetingId} />
        </Box>
        {!isJoiningFromAnotherMeeting && (
          <Button
            size="sm"
            color={!isLoadingCamera && !isLoadingMic ? 'primary' : 'neutral'}
            variant="plain"
            onClick={handleContinueWithoutJoining}
            disabled={isJoiningCall || isJoiningFromAnotherMeeting}
            loading={isJoiningCall || isJoiningFromAnotherMeeting}>
            Close Without Joining
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

function PreviewSettings({ agendaId, meetingId }) {
  const { user } = useUser('me');
  const localSessionId = useLocalSessionId();
  const { camState, micState } = useDevices();

  const isLoadingCamera = ['idle', 'pending'].includes(camState);
  const isLoadingMic = ['idle', 'pending'].includes(micState);

  return (
    <>
      <Stack
        direction="column"
        minWidth={{ xs: '100%', sm: '400px' }}
        sx={{
          padding: 2,
          flexBasis: '40%', // Match or adjust based on sibling's basis
          alignItems: isLoadingCamera || isLoadingMic ? 'center' : undefined,
        }}>
        {isLoadingCamera || isLoadingMic ? (
          <CircularProgress size="lg" />
        ) : (
          <CallSettings
            videoPreview={false}
            showMeetingUrl={false}
            agendaId={agendaId}
            meetingId={meetingId}
          />
        )}
      </Stack>
      <Box
        padding={2}
        minWidth={{ xs: '50%', sm: '14rem' }}
        maxWidth="14rem"
        sx={{
          flexGrow: 0.75,
          flexBasis: '40%', // Adjust this value based on your needs
        }}>
        <VideoPreview sessionId={localSessionId} fallbackSrc={user?.avatar} />
      </Box>
    </>
  );
}
