import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useState } from 'react';

import { Modal } from '@/components/reusable';
import { useCall } from '@/hooks/call';
import { useParticipantUsername } from '@/hooks/useParticipantProperty';

interface RemoveParticipantButtonProps {
  sessionId: string;
  children?: React.ReactNode;
  onRemoveParticipant?: () => void;
}
const RemoveParticipantButton: React.FC<RemoveParticipantButtonProps> = ({
  sessionId,
  children,
  onRemoveParticipant,
}) => {
  const { ejectParticipant } = useCall();

  const username = useParticipantUsername({ sessionId });
  const [open, setOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const label = 'Remove participant';

  const onConfirm = useCallback(() => {
    setIsConfirming(true);
    ejectParticipant(sessionId);
    onRemoveParticipant?.();
    setIsConfirming(false);
    setOpen(false);
  }, [sessionId, ejectParticipant, onRemoveParticipant]);

  //note this is material tooltip, not joy. Joy tooltip is displaying under the video
  return (
    <>
      <Tooltip title={label} placement="top" PopperProps={{ style: { zIndex: '9999' } }}>
        <IconButton
          onClick={() => setOpen(true)}
          aria-label={label}
          sx={(theme) => ({
            color: '#fff',
            '&:hover': {
              color: theme.palette.danger['500'],
              background: 'transparent',
            },
          })}>
          <PersonRemoveOutlinedIcon />
          {children && <Box mx={1}>{children}</Box>}
        </IconButton>
      </Tooltip>
      <Modal
        variant="dialog-warning"
        title={label}
        description={`Are you sure you want to remove ${username} from this meeting?`}
        open={open}
        sx={{ zIndex: '100000' }}
        onClose={() => setOpen(false)}>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            color="danger"
            disabled={isConfirming}
            loading={isConfirming}
            onClick={onConfirm}>
            Yes, remove them
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default RemoveParticipantButton;
