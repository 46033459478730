import Daily from '@daily-co/daily-js';
import { useScreenShare } from '@daily-co/daily-react';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import StopScreenShareOutlinedIcon from '@mui/icons-material/StopScreenShareOutlined';
import { Typography } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';

export default function ScreenShareControlButton({ simple = false }) {
  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare();
  const { supportsScreenShare } = Daily.supportedBrowser();

  const toggleScreenShare = () => (isSharingScreen ? stopScreenShare() : startScreenShare());

  const label = isSharingScreen ? 'Stop sharing screen' : 'Share screen';
  const buttonText = simple ? '' : isSharingScreen ? 'Stop Sharing' : 'Share Screen';
  // #777777 is not part of the Theme palette, so we must use sx to set the color
  const iconButtonProps = isSharingScreen && simple ? { color: 'tertiary' } : {};
  const iconProps = isSharingScreen
    ? { sx: { color: simple ? '' : '#777777', fontSize: '1.25rem' } }
    : { sx: { color: '#777777', fontSize: '1.25rem' } };

  const icon = isSharingScreen ? (
    <StopScreenShareOutlinedIcon {...iconProps} />
  ) : (
    <ScreenShareOutlinedIcon {...iconProps} />
  );

  const iconButton = (
    <IconButton onClick={toggleScreenShare} aria-label={label} {...iconButtonProps}>
      {!buttonText && icon}
      {buttonText && <Typography startDecorator={icon}>{buttonText}</Typography>}
    </IconButton>
  );

  if (!supportsScreenShare) return null;

  // Avoid displaying a tooltip if we already have a button text
  if (buttonText) {
    return iconButton;
  }

  return (
    <Tooltip title={label} placement="top" arrow>
      {iconButton}
    </Tooltip>
  );
}
