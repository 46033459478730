import { Button, Divider, Stack } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { useRouter } from 'next/router';
import React from 'react';

import ShareAgenda from '@/components/agenda/ShareAgenda';
import { Modal } from '@/components/reusable';

const ShareMeetingModal = ({ agendaId, open, setOpen }) => {
  const router = useRouter();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title="Invite Others!"
      description="Anyone else can join your meeting by giving them this link">
      <Divider sx={{ my: 2 }} />
      <ShareAgenda
        agendaId={agendaId}
        onInvite={() => {
          setOpen(false);
          // add the query string shared=true to the current URL
          router.push(`/agendas/${agendaId}?shared=true`);
        }}
      />
      <Divider sx={{ my: 3 }} />
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', lg: 'center' }}>
        <Typography level="body-lg" fontWeight="lg" gutterBottom>
          Continue without inviting anyone else
        </Typography>
        <Button onClick={() => setOpen(false)}>Continue to Meeting</Button>
      </Stack>
    </Modal>
  );
};

export default ShareMeetingModal;
