import { useAgenda, useTranscription, useTranscriptionNotes } from '@groupthinkai/groupthink';
import {
  ExpandMoreOutlined as ExpandMoreOutlinedIcon,
  TuneOutlined as TuneOutlinedIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Dropdown,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
  ListSubheader,
  Stack,
} from '@mui/joy';
import React, { useCallback, useEffect } from 'react';
import _ from 'underscore';
import MessageContents from '@/components/messages/MessageContents';
import { usePusher, useRealtimeCollection } from '@/hooks/realtime';

const DisplayFilter = Object.freeze({
  TRANSCRIPTION: 'transcription',
  NOTES: 'notes',
});

const MeetingTextContainer = ({ agendaId }) => {
  const { agenda, isCurrentUserAgendaEditor } = useAgenda(agendaId);
  const hasActiveMeeting = agenda?.active_meeting;
  const [display, setDisplay] = React.useState([DisplayFilter.NOTES]);
  const [extraData, setExtraData] = React.useState([]);

  const isNotesVisible = display.includes(DisplayFilter.NOTES);
  const isTranscriptionVisible = display.includes(DisplayFilter.TRANSCRIPTION);

  const { transcription_notes } = useTranscriptionNotes(
    isNotesVisible && agenda?.id,
    isNotesVisible && agenda?.active_meeting?.id,
    null, // Get notes for all items in the meeting
    {
      useRealtimeCollection,
    }
  );

  const { transcription } = useTranscription(
    isTranscriptionVisible && agenda?.id,
    isTranscriptionVisible && agenda?.active_meeting?.id,
    {
      usePusher,
    }
  );

  useEffect(() => {
    const transcriptionItems = transcription?.content
      ? _.sortBy(transcription.content, (item) => {
          return parseFloat(item['relative_start']);
        }).map((item) => {
          return {
            oldest_message: {
              created_at: item.timestamp,
              updated_at: item.updated_at,
              agenda_id: agenda?.id,
              thread_id: null,
              id: item.id,
              content: item.sentence ?? item.data,
              threadable: false,
              reactable: false,
              editable: false,
              deleteable: false,
              medium: 'transcription',
              sent_by: {
                id: item.speaker,
                name: item.speaker,
                avatar: item.avatar,
              },
            },
          };
        })
      : [];

    const notesItems = Object.values(transcription_notes ?? [])
      .sort((a, b) => {
        return a.created_at - b.created_at;
      })
      .map((item) => {
        return {
          oldest_message: {
            created_at: item.created_at,
            updated_at: item.updated_at,
            agenda_id: agenda?.id,
            thread_id: null,
            id: item.id,
            content: item.content,
            threadable: false,
            reactable: false,
            editable: false,
            deleteable: false,
            medium: 'note',
            sent_by: {
              id: 0,
              name: 'Groupthink',
              avatar: '/gt_avatar.png',
            },
          },
        };
      });

    let ed = [];

    if (isTranscriptionVisible) {
      ed = ed.concat(transcriptionItems);
    }

    // if display contains notes, concat notesItems to extraData
    if (isNotesVisible) {
      ed = ed.concat(notesItems);
    }

    setExtraData(ed);
  }, [isNotesVisible, isTranscriptionVisible, transcription_notes, transcription]);

  // Auto-scroll effect
  const messagesEndRef = React.useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [extraData]);

  return (
    <Box
      id="meeting-content-container"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      {isCurrentUserAgendaEditor && (
        <>
          {hasActiveMeeting && isCurrentUserAgendaEditor && (
            <Box
              sx={{
                p: 2,
                borderBottom: '1px solid #e2e2e2',
              }}>
              <ChatDisplayFilterMenu display={display} setDisplay={setDisplay} />
            </Box>
          )}
          <Stack sx={{ overflowY: 'auto', flexGrow: 1 }}>
            <Stack spacing={1} sx={{ overflowY: 'auto', maxHeight: '100%', p: 2 }}>
              {extraData.map((message, i) => {
                const adjacent =
                  extraData[i - 1]?.oldest_message?.sent_by?.id ===
                  message.oldest_message.sent_by?.id;
                return (
                  <MessageContents
                    key={message.oldest_message.id}
                    message={message.oldest_message}
                    medium={message.oldest_message.medium ?? 'chat'}
                    setActiveThread={() => {}}
                    adjacent={adjacent}
                    threadable={false}
                    hasUnread={false}
                    threadMessageCount={0}
                    isEditing={false}
                    onEditingCompleted={() => {}}
                  />
                );
              })}
              <div ref={messagesEndRef} />
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
};

const ChatDisplayFilterMenu = ({ display, setDisplay }) => {
  const toggleDisplay = useCallback(
    (filter) => {
      setDisplay((currentDisplay) => {
        const isCurrentlyDisplayed = currentDisplay.includes(filter);

        if (isCurrentlyDisplayed) {
          return currentDisplay.filter((item) => item !== filter);
        } else {
          return [...currentDisplay, filter];
        }
      });
    },
    [display]
  );

  return (
    <Dropdown>
      <MenuButton size="sm" endDecorator={<ExpandMoreOutlinedIcon />}>
        <Typography startDecorator={<TuneOutlinedIcon />}>Notes &amp; Transcription</Typography>
      </MenuButton>
      <Menu sx={{ minWidth: 160, '--ListItemDecorator-size': '24px' }}>
        <ListItem nested>
          <ListSubheader>Display Filters</ListSubheader>
        </ListItem>
        <MenuItem onClick={() => toggleDisplay(DisplayFilter.NOTES)}>
          <Checkbox label="Show Notes" checked={display.includes(DisplayFilter.NOTES)} />
        </MenuItem>
        <MenuItem onClick={() => toggleDisplay(DisplayFilter.TRANSCRIPTION)}>
          <Checkbox
            label="Show Transcription"
            checked={display.includes(DisplayFilter.TRANSCRIPTION)}
          />
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};
export default MeetingTextContainer;
