import Box from '@mui/joy/Box';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Sheet from '@mui/joy/Sheet';
import React, { useState } from 'react';

import RecordingControlButton from '@/components/agenda/meeting/RecordingControlButton';
import { STATE_IDLE } from '@/components/video/AppStates';
import AudioControlButton from '@/components/video/AudioControlButton';
import CallSettingsModal from '@/components/video/CallSettingsModal';
import DeveloperModeModal from '@/components/video/DeveloperModeModal';
import ScreenShareControlButton from '@/components/video/ScreenShareControlButton';
import SettingsControlButton, {
  DeveloperSettingsControlButton,
} from '@/components/video/SettingsControlButton';
import VideoControlButton from '@/components/video/VideoControlButton';
import { useAuth } from '@/hooks/auth';

export default function Tray({ appState = {}, agendaId, meetingId }) {
  const isDev = process.env.NODE_ENV === 'development';

  const [showMeetingInformation, setShowMeetingInformation] = useState(false);
  const toggleMeetingInformation = () => {
    setShowMeetingInformation(!showMeetingInformation);
  };
  const [showDeveloperMode, setShowDeveloperMode] = useState(false);
  const toggleDeveloperMode = () => {
    setShowDeveloperMode(!showDeveloperMode);
  };

  const { isEmployee } = useAuth({ middleware: 'auth' });

  return (
    <Box
      className="tray"
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: { xs: 'center' },
        backgroundColor: 'neutral.800',
        borderBottomLeftRadius: '8px',
        alignItems: 'center',
        py: 1,
      }}>
      <ButtonGroup
        variant="plain"
        spacing="0.5rem"
        size="md"
        buttonFlex={1}
        aria-label="video button group">
        <VideoControlButton badgeOnFault />
        <AudioControlButton badgeOnFault />
        {appState !== STATE_IDLE && (
          <RecordingControlButton agendaId={agendaId} meetingId={meetingId} />
        )}
        <ScreenShareControlButton simple />

        <SettingsControlButton onClick={toggleMeetingInformation} />

        {(isDev || isEmployee) && <DeveloperSettingsControlButton onClick={toggleDeveloperMode} />}
      </ButtonGroup>

      <CallSettingsModal
        open={showMeetingInformation}
        setOpen={setShowMeetingInformation}
        agendaId={agendaId}
        meetingId={meetingId}
      />
      {(isDev || isEmployee) && (
        <DeveloperModeModal
          open={showDeveloperMode}
          setOpen={setShowDeveloperMode}
          appState={appState}
        />
      )}
    </Box>
  );
}
