import { useMeeting, useMeetings } from '@groupthinkai/groupthink';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';
import React, { useEffect, useState } from 'react';

import { useRealtimeResource } from '@/hooks/realtime';

export default function RecordingControlButton({ agendaId, meetingId }) {
  const { meeting } = useMeeting(agendaId, meetingId, {
    useRealtimeResource,
  });
  const { updateMeeting } = useMeetings(agendaId, meetingId);

  const [isRecording, setIsRecording] = useState(meeting?.is_bot_recording ?? false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setIsRecording(meeting?.is_bot_recording ?? false);
  }, [meeting?.is_bot_recording]);

  const toggleRecording = () => {
    updateMeeting(meetingId, {
      payload: { is_bot_recording: !isRecording },
      setIsUpdating,
      onSuccess: () => {
        setIsRecording(!isRecording);
      },
    });
  };

  const label = isRecording ? 'Click to stop recording' : 'Click to start recording';

  // #777777 is not part of the Theme palette, so we must use sx to set the color
  const iconButtonProps = isRecording ? { color: 'primary' } : {};
  const iconProps = isRecording ? {} : { sx: { color: '#777777', fontSize: '1.25rem' } };

  if (!agendaId || !meetingId || !meeting) return '';

  return (
    <Tooltip title={label} placement="top" arrow>
      <IconButton
        onClick={toggleRecording}
        aria-label={label}
        disabled={isUpdating}
        loading={isUpdating}
        {...iconButtonProps}>
        {isRecording ? <RadioButtonCheckedIcon {...iconProps} /> : <CircleIcon {...iconProps} />}
      </IconButton>
    </Tooltip>
  );
}
