import { useAction } from '@groupthinkai/groupthink';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';

import ActionModal from '../action/ActionModal';

import { useCurrentOrganization } from '@/hooks/organization';

export default function MessageSubActions({ message }: { message: Groupthink.MessageResource }) {
  // message has extra
  if (!message.extra) return null;

  // extra has an action id?
  const action_id_regex = /AC([0-9a-f]){32}/;
  const actionId = message.extra.match(action_id_regex)?.[0];
  if (!actionId) return null;

  // action id is valid, render the sub actions
  return (
    <div className="message-sub-actions">
      <MessageActionPreview actionId={actionId} />
    </div>
  );
}

function MessageActionPreview({ actionId }: { actionId: string }) {
  const [currentOrganization] = useCurrentOrganization();
  const { action } = useAction(currentOrganization, actionId);
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  if (!action) return null;

  return (
    <>
      <Button
        variant="soft"
        color="neutral"
        sx={{ mt: 1 }}
        endDecorator={<ChevronRightIcon />}
        onClick={handleOpen}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textTransform: 'none',
          }}>
          <Typography level="body-sm" fontWeight="bold">
            {action.title}{' '}
            <span style={{ fontWeight: 500, fontSize: '.9em', textTransform: 'capitalize' }}>
              ({action.status})
            </span>
          </Typography>
          <Typography level="body-sm">{action?.subtitle}</Typography>
        </Box>
      </Button>
      <ActionModal
        action={action}
        open={open}
        setOpen={(newOpen) => {
          console.log('set open', newOpen);
          setOpen(newOpen);
        }}
      />
    </>
  );
}
