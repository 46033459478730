import { useAgenda, useMeetings, useItemsMutate } from '@groupthinkai/groupthink';
import { Button } from '@mui/joy';
import { useRouter } from 'next/router';
import * as React from 'react';

import ConfirmedAction from '@/components/ConfirmedAction';
import { useCall, CallEndedReason } from '@/hooks/call';
import { useRealtimeResource } from '@/hooks/realtime';

const EndMeetingButton = ({ agendaId, isGroupthinkVideo = false, agendaListRef = null }) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { agenda, isError, mutate } = useAgenda(agendaId, { useRealtimeResource });
  const { updateMeeting } = useMeetings(agendaId, agenda?.active_meeting?.id);
  const mutateCompleted = useItemsMutate(agendaId);
  const { state: callState, selectors, leaveRoom } = useCall();
  const hasJoinedCall = selectors.selectHasJoinedCall(callState);

  const router = useRouter();

  const endMeeting = () => {
    const meetingId = agenda?.active_meeting?.id;
    updateMeeting(meetingId, {
      payload: { end: true, workspace_id: agenda?.workspace.id },
      setIsUpdating,
      // todo: add the hook here that finishes the agenda in the editor
      // duplicated in EndMeetingMenuItem
      onSuccess: () => {
        mutate();

        agendaListRef?.current?.close();
        agendaListRef?.current?.sort();

        leaveRoom({ reason: CallEndedReason.MeetingEnded });

        mutateCompleted();
      },
    });
  };

  const handleLeaveMeeting = () => {
    leaveRoom({ reason: CallEndedReason.LeftMeeting, skipRecap: true });

    router.push('/calendar');
  };

  if (isError) {
    return null;
  }

  if (isGroupthinkVideo) {
    return (
      <ConfirmedAction
        onConfirm={endMeeting}
        title="End Meeting"
        message="Are you sure you want to end this meeting?"
        isConfirming={isUpdating}
        buttonText={'End Meeting...'}
        confirmButtonText={hasJoinedCall ? 'End for Everyone' : 'End Meeting'}
        variant="outlined"
        cancelText="No, Stay in Meeting"
        extraButton={
          hasJoinedCall && (
            <Button variant="soft" color="neutral" onClick={handleLeaveMeeting}>
              End for Me
            </Button>
          )
        }
        size="xs"
      />
    );
  }

  return (
    <Button variant="outlined" size="sm" onClick={endMeeting} color="danger" disabled={isUpdating}>
      {`Remove from ${agenda?.active_meeting?.conferencing.label}`}
    </Button>
  );
};

export default EndMeetingButton;
