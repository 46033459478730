import {
  useParticipantIds,
  useScreenShare,
  useLocalSessionId,
  useDaily,
} from '@daily-co/daily-react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

import { Modal } from '@/components/reusable';
import { useCallLayout } from '@/hooks/callLayout';

const DeveloperModeModal = ({ open, setOpen, appState }) => {
  const daily = useDaily();
  const { screens } = useScreenShare();
  const remoteNonGroupthinkParticipantIds = useParticipantIds({
    filter: (participant) => {
      return participant.user_name !== 'Groupthink' && !participant.local;
    },
  });
  const participantIds = useParticipantIds();
  const localSessionId = useLocalSessionId();

  const { state } = useCallLayout();

  return (
    <Modal title="Developer Settings" open={open} onClose={() => setOpen(false)}>
      <Typography level="body-sm">
        This panel is only visible to employees in production.
      </Typography>
      <Stack direction="column" spacing={2} width="100%" marginTop="2rem">
        <Box>
          <Typography level="title-sm">App State:</Typography>
          <Typography>{JSON.stringify(appState, null, 2)}</Typography>
        </Box>
        <Box>
          <Typography level="title-sm">Screen Ids: </Typography>
          <Typography>{JSON.stringify(screens, null, 2)}</Typography>
        </Box>
        <Box>
          <Typography level="title-sm">localSessionId: </Typography>
          <Typography>{JSON.stringify(localSessionId, null, 2)}</Typography>
        </Box>
        <Box>
          <Typography level="title-sm">Non-Groupthink, Non-local Ids:</Typography>
          <Typography>{JSON.stringify(remoteNonGroupthinkParticipantIds, null, 2)}</Typography>
        </Box>
        <Box>
          <Typography level="title-sm">All Participant Ids:</Typography>
          <Typography>{JSON.stringify(participantIds, null, 2)}</Typography>
        </Box>
        <Box>
          <Typography level="title-sm">GroupthinkVideoContext:</Typography>
          <Typography>{JSON.stringify(state, null, 2)}</Typography>
        </Box>
        <Box>
          <Typography
            startDecorator={
              <IconButton
                onClick={() => daily.addFakeParticipant()}
                aria-label={'Add Fake Participant'}>
                <PersonAddIcon sx={{ fontSize: '2.5rem', color: '#777777' }} />
              </IconButton>
            }>
            Add Fake Participant
          </Typography>
        </Box>
      </Stack>
    </Modal>
  );
};

export default DeveloperModeModal;
