import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import { Box, IconButton } from '@mui/joy';
import Tooltip from '@mui/material/Tooltip';
import { useCallback } from 'react';

import { useCall } from '@/hooks/call';

interface MuteParticipantButtonProps {
  sessionId: string;
  children?: React.ReactNode;
  onMuteParticipant?: () => void;
}

const MuteParticipantButton: React.FC<MuteParticipantButtonProps> = ({
  sessionId,
  children,
  onMuteParticipant,
}) => {
  const { muteParticipant } = useCall();

  const label = 'Mute participant';

  const handleMute = useCallback(() => {
    muteParticipant(sessionId);
    onMuteParticipant?.();
  }, [sessionId, muteParticipant, onMuteParticipant]);

  //note this is material tooltip, not joy. Joy tooltip is displaying under the video
  return (
    <Tooltip title={label} placement="top" PopperProps={{ style: { zIndex: '9999' } }}>
      <IconButton
        onClick={handleMute}
        aria-label={label}
        sx={(theme) => ({
          color: '#fff',
          '&:hover': {
            color: theme.palette.primary['main'],
            background: 'transparent',
          },
        })}>
        <MicOffOutlinedIcon />
        {children && <Box mx={1}>{children}</Box>}
      </IconButton>
    </Tooltip>
  );
};

export default MuteParticipantButton;
