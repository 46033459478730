import { useAudioTrack, useDaily, useDevices, useLocalSessionId } from '@daily-co/daily-react';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';
import Badge from '@mui/joy/Badge';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Switch from '@mui/joy/Switch';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';

import CameraPermissionsSnackbar from '@/components/video/CameraPermissionsSnackbar';

export default function AudioControlButton({ toggle, children, badgeOnFault = false }) {
  const daily = useDaily();

  const localSessionId = useLocalSessionId();
  const localAudio = useAudioTrack(localSessionId);
  const { hasMicError, micState } = useDevices();
  const [isCameraPermissionsSnackbarOpen, setIsCameraPermissionsSnackbarOpen] = useState(false);

  const toggleAudio = () => {
    if (localAudio.blocked) {
      setIsCameraPermissionsSnackbarOpen(true);
    } else {
      daily.setLocalAudio(localAudio.isOff);
    }
  };

  const isLoadingMic = ['idle', 'pending'].includes(micState);
  let label = hasMicError ? 'Error' : localAudio.isOff ? 'Unmute mic' : 'Mute mic';
  const audioFault = localAudio.blocked || localAudio.state === 'interrupted';
  const audioOn = !localAudio.isOff && !audioFault;
  const color = isLoadingMic
    ? 'neutral'
    : localAudio.isOff
    ? audioFault
      ? 'danger'
      : 'tertiary'
    : 'primary';

  const sx = {
    fontSize: '1.25rem',
  };

  // Use a specific error message, if available
  if (micState === 'blocked') {
    label = 'Allow microphone access to turn on';
  } else if (micState === 'in-use') {
    label = 'Mic already in use by another app';
  } else if (micState === 'not-found') {
    label = 'No mic found';
  }

  if (toggle) {
    return (
      <>
        <Typography
          component={'div'}
          startDecorator={
            <Tooltip title={label} placement="top">
              <Switch checked={audioOn} onChange={toggleAudio} />
            </Tooltip>
          }>
          {children ?? 'Microphone'}
        </Typography>
        <CameraPermissionsSnackbar
          open={isCameraPermissionsSnackbarOpen}
          setOpen={setIsCameraPermissionsSnackbarOpen}
        />
      </>
    );
  }

  return (
    <>
      <Tooltip title={label} placement="top" arrow>
        <IconButton onClick={toggleAudio} aria-label={label} color={color}>
          <Badge badgeContent="!" invisible={!hasMicError || !badgeOnFault}>
            {audioOn ? <MicOutlinedIcon sx={sx} /> : <MicOffOutlinedIcon sx={sx} />}
            {children && <Box mx={1}>{children}</Box>}
          </Badge>
        </IconButton>
      </Tooltip>
      <CameraPermissionsSnackbar
        open={isCameraPermissionsSnackbarOpen}
        setOpen={setIsCameraPermissionsSnackbarOpen}
      />
    </>
  );
}
