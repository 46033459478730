import { useAction, useConnections } from '@groupthinkai/groupthink';
import CheckIcon from '@mui/icons-material/Check';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {
  Input,
  Box,
  Sheet,
  Typography,
  Button,
  Divider,
  Avatar,
  FormControl,
  FormLabel,
  Textarea,
  Select,
  Option,
} from '@mui/joy';
import Link from '@mui/joy/Link';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import * as NextLink from 'next/link';
import React, { useEffect, useMemo, useRef } from 'react';

const widgets = {
  input: ({ id, label, value, required, onChange, options }) => {
    const handleChange = (event) => {
      onChange(event.target.value);
    };

    return (
      <Input
        id={id}
        label={label}
        value={value || ''} // use parent's value
        onChange={handleChange}
        required={required}
        type={options.inputType || 'text'}
      />
    );
  },
  textarea: ({ id, label, value, required, onChange, options }) => {
    const handleChange = (event) => {
      onChange(event.target.value);
    };

    return (
      <Textarea
        id={id}
        label={label}
        value={value || ''} // use parent's value
        onChange={handleChange}
        required={required}
        minRows={options.rows || 4}
      />
    );
  },
  select: ({ id, label, value, required, onChange, options }) => {
    const handleChange = (event, newValue) => {
      onChange(newValue);
    };

    return (
      <Select id={id} label={label} value={value || ''} onChange={handleChange} required={required}>
        {Object.entries(options).map(([key, value]) => (
          <Option key={key} value={value}>
            {value}
          </Option>
        ))}
      </Select>
    );
  },
  display: ({ id, label, value, required, onChange, options }) => {
    return <Typography level="body-md">{value}</Typography>;
  },
};

export default function ItemContent({ workspaceId, actionId, outlined = true }) {
  const { action, updateActionProperties, acceptAction, rejectAction } = useAction(
    workspaceId,
    actionId
  );
  const { connections } = useConnections(workspaceId);
  const [formValues, setFormValues] = React.useState({});
  const [isUpdating, setIsUpdating] = React.useState(false);

  const configuredConnectionKeys = Object.values(connections ?? {}).map(
    (connection) => connection.driver
  );

  // make sure that everything in the action.required_connections array is present in configuredConnectionKeys
  const eligibleToAccept = action?.required_connections.every((connection) =>
    configuredConnectionKeys.includes(connection)
  );

  // if action.properties updates, update the formValues
  React.useEffect(() => {
    setFormValues(action?.properties ?? {});
  }, [JSON.stringify(action?.properties)]);

  // 1) Define your debounced function ONCE (memoized).
  const debouncedUpdate = useMemo(
    () =>
      debounce(() => {
        // only do the update if the values are actually different:
        if (
          action?.properties &&
          JSON.stringify(action.properties) !== JSON.stringify(formValues)
        ) {
          updateActionProperties({ payload: { properties: formValues }, setIsUpdating });
        }
      }, 500),
    [action, formValues, updateActionProperties]
  );

  // 2) Whenever `formValues` changes, call the same debounced function.
  useEffect(() => {
    debouncedUpdate();

    // 3) Clean up by cancelling on unmount or when dependencies change.
    return () => {
      debouncedUpdate.cancel();
    };
  }, [formValues, debouncedUpdate]);

  const setFormValue = React.useCallback((key, value) => {
    setFormValues((prev) => {
      if (prev[key] === value) return prev;
      return { ...prev, [key]: value };
    });
  }, []);

  const accept = () => {
    acceptAction({ setIsUpdating });
  };

  const reject = () => {
    rejectAction({ setIsUpdating });
  };

  if (!action) {
    return <div>loading...</div>;
  }

  return (
    <Sheet
      variant={outlined ? 'outlined' : null}
      sx={{ minHeight: 500, flexGrow: 1, borderRadius: 'sm', p: 2, mb: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Avatar>
            <div
              dangerouslySetInnerHTML={{ __html: action.avatar }}
              style={{ display: 'block', width: '100%', height: '100%' }}
            />
          </Avatar>
          <Box sx={{ ml: 2 }}>
            <Typography level="title-sm" textColor="text.primary" sx={{ mb: 0.5 }}>
              {action.integration_name}
            </Typography>
            <Typography level="body-xs" textColor="text.tertiary">
              {dayjs(action.created_at).format('MMM DD YYYY')}
            </Typography>
          </Box>
        </Box>
        {!action?.accepted_at ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
            <Button
              size="sm"
              variant="solid"
              color="primary"
              onClick={accept}
              startDecorator={<CheckIcon />}
              disabled={action?.validation !== true || !eligibleToAccept || isUpdating}>
              Accept
            </Button>
            <Button
              size="sm"
              variant="plain"
              color="danger"
              onClick={reject}
              startDecorator={<DeleteRoundedIcon />}
              disabled={isUpdating}>
              Reject
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Typography level="body-sm">
              Accepted {dayjs(action.accepted_at).format('MMM DD YYYY')}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider sx={{ mt: 2 }} />
      <Box sx={{ py: 2, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Typography level="title-lg" textColor="text.primary">
          {action.title}
        </Typography>
        <Box
          sx={{
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap',
          }}>
          <Typography component="span" level="body-sm" sx={{ mr: 1, display: 'inline-block' }}>
            {action.subtitle}:{' '}
            {action.actionable_url && (
              <Link component={NextLink} href={action.actionable_url}>
                {action.actionable_title}
              </Link>
            )}
          </Typography>
          <Typography level="body-sm" textColor="text.primary">
            {action.notes}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ mt: 2 }}>
        {Object.entries(action.fields.properties).map(([key, value]) => {
          const Field = widgets[value['ui:options'].inputType];
          return (
            <FormControl key={key} sx={{ mb: 2 }}>
              <FormLabel sx={{ fontWeight: 'bold' }}>{value.title}</FormLabel>
              {action?.accepted_at ? (
                <Typography level="body-md">{formValues[key]}</Typography>
              ) : (
                <Field
                  id={key}
                  value={formValues[key]}
                  required={action.fields.required.includes(key)}
                  onChange={(value) => setFormValue(key, value)}
                  options={value?.enum ?? {}}
                />
              )}
            </FormControl>
          );
        })}
      </Box>
    </Sheet>
  );
}
