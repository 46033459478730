import { useAgenda, useMeetings } from '@groupthinkai/groupthink';
import Button from '@mui/joy/Button';
import { useRouter } from 'next/router';
import * as React from 'react';

import ConfirmedAction from '@/components/ConfirmedAction';
import { useCall, CallEndedReason } from '@/hooks/call';
import { useRealtimeResource } from '@/hooks/realtime';

const NextMeetingButton = ({
  currentAgendaId,
  nextAgenda,
  isGroupthinkVideo = false,
  agendaListRef = null,
}) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const {
    agenda,
    isError,
    mutate: mutateAgenda,
  } = useAgenda(currentAgendaId, { useRealtimeResource });
  const agendaName = nextAgenda?.name;
  const nextAgendaId = nextAgenda?.id;
  const router = useRouter();
  const { createMeeting } = useMeetings(nextAgendaId);
  const { updateMeetingDetails, leaveRoom } = useCall();

  const handleJoinNextMeeting = () => {
    leaveRoom({ reason: CallEndedReason.MeetingEnded, skipRecap: true }).then(() => {
      if (!nextAgenda?.active_meeting) {
        createMeeting({
          payload: {
            source: 'next-meeting-start',
            start: true,
            workspace_id: nextAgenda?.workspace.id,
          },
          onSuccess: (nextMeeting) => {
            mutateAgenda();
            updateMeetingDetails({
              roomId: nextAgenda?.room_id,
              agendaId: nextAgenda?.id,
              meetingId: nextMeeting?.id,
            });
            router.push(`/agendas/${nextAgendaId}?skipConfirm=true`);
          },
        });
      } else {
        updateMeetingDetails({
          roomId: nextAgenda?.room_id,
          agendaId: nextAgenda?.id,
          meetingId: nextAgenda?.active_meeting?.id,
        });
        router.push(`/agendas/${nextAgendaId}?skipConfirm=true`);
      }
    });
  };

  if (isError) {
    return null;
  }

  if (isGroupthinkVideo) {
    return (
      <ConfirmedAction
        onConfirm={handleJoinNextMeeting}
        title={`Go to Meeting "${agendaName}"`}
        message={`Are you sure you want to leave this meeting and join "${agendaName}" ?`}
        isConfirming={isUpdating}
        buttonText={`Join Next Meeting "${agendaName}"`}
        confirmButtonText={'Join Next Meeting and Leave Current Meeting'}
        variant="outlined"
        cancelText="No, Stay in Current Meeting"
        size="sm"
        tooltip="hello!"
      />
    );
  }

  return (
    <Button variant="outlined" onClick={handleJoinNextMeeting} color="danger" disabled={isUpdating}>
      {`Remove Groupthink`}
      {agenda?.active_meeting?.conferencing?.label &&
        ` from ${agenda?.active_meeting?.conferencing?.label}`}
    </Button>
  );
};

export default NextMeetingButton;
