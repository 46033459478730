import Daily from '@daily-co/daily-js';
import { useDevices, useInputSettings, useLocalSessionId } from '@daily-co/daily-react';
import { useUser } from '@groupthinkai/groupthink';
import { VolumeUpOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormHelperText,
  FormLabel,
  Option,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/joy';
import React, { useCallback, useState } from 'react';

import MeetingLink from '@/components/agenda/MeetingLink';
import AudioControlButton from '@/components/video/AudioControlButton';
import VideoControlButton from '@/components/video/VideoControlButton';
import VideoPreview from '@/components/video/VideoPreview';

export default function CallSettings({
  videoPreview = true,
  showMeetingUrl = true,
  sx = {},
  agendaId,
  meetingId,
}) {
  const { user } = useUser('me');
  const { supportsAudioProcessing } = Daily.supportedBrowser();

  const localSessionId = useLocalSessionId();
  const {
    currentCam,
    currentMic,
    currentSpeaker,
    microphones,
    speakers,
    cameras,
    hasCamError,
    hasMicError,
    camState,
    micState,
    setMicrophone,
    setCamera,
    setSpeaker,
  } = useDevices();

  const [, setInputSettingsErrorMessage] = useState(null);

  const {
    errorMsg: inputSettingsErrorMessage,
    inputSettings,
    updateInputSettings,
  } = useInputSettings({
    onInputSettingsUpdated: useCallback((event) => {
      console.log('Input settings updated:', event.inputSettings?.audio?.processor);
    }, []),
    onError: useCallback((event) => {
      console.error('Error updating input settings:', event.error);
      setInputSettingsErrorMessage(event.error.message);
    }, []),
  });

  const toggleNoiseCancellation = () => {
    updateInputSettings({
      audio: {
        processor: {
          type:
            inputSettings?.audio?.processor?.type === 'noise-cancellation'
              ? 'none'
              : 'noise-cancellation',
        },
      },
    });
  };

  const updateMicrophone = async (event, newValue) => {
    await setMicrophone(newValue);
  };

  const updateSpeakers = async (event, newValue) => {
    await setSpeaker(newValue);
  };

  const updateCamera = async (event, newValue) => {
    await setCamera(newValue);
  };

  const isCameraReady = camState === 'granted';
  const isMicReady = micState === 'granted';

  return (
    <div>
      <Stack direction="column" spacing={2} sx={{ width: '100%', ...sx }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{ width: '100%' }}
          justifyContent={'center'}
          alignItems={'center'}>
          {videoPreview && (
            <Box
              padding={2}
              minWidth={{ xs: '50%', sm: '12rem' }}
              maxWidth={{ xs: '50%', sm: '12rem' }}
              sx={{ width: '100%' }}>
              <VideoPreview sessionId={localSessionId} fallbackSrc={user?.avatar} />
            </Box>
          )}

          <Box sx={{ width: '100%' }}>
            <Stack spacing={3}>
              {/* Camera select */}
              <Box>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <VideoControlButton toggle={isCameraReady}>Camera</VideoControlButton>
                  <Select
                    id="call-settings-camera"
                    placeholder="Choose one…"
                    value={currentCam?.device?.deviceId ?? ''}
                    size="sm"
                    onChange={updateCamera}
                    disabled={hasCamError}>
                    {!currentCam?.device?.deviceId && (
                      <Option disabled value="">
                        Choose one…
                      </Option>
                    )}
                    {cameras.map((camera) => (
                      <Option key={`cam-${camera.device.deviceId}`} value={camera.device.deviceId}>
                        {camera.device.label}
                      </Option>
                    ))}
                  </Select>
                </Stack>
              </Box>

              {/* Speakers select */}
              <Box display={{ xs: 'none', md: 'initial' }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Typography
                    startDecorator={
                      <VolumeUpOutlined sx={{ fontSize: '1.5rem', color: '#777777' }} />
                    }>
                    Speakers
                  </Typography>

                  <Select
                    id="call-settings-speakers"
                    placeholder="Choose one…"
                    value={currentSpeaker?.device?.deviceId ?? ''}
                    sx={{ flexGrow: 1 }}
                    size="sm"
                    onChange={updateSpeakers}>
                    {/* if micState is blocked, we cannot choose a speaker other than the default */}
                    {!currentSpeaker?.device?.deviceId && micState === 'blocked' && (
                      <Option value="">System default</Option>
                    )}
                    {!currentSpeaker?.device?.deviceId && micState !== 'blocked' && (
                      <Option disabled value="">
                        Choose one…
                      </Option>
                    )}
                    {speakers.map((speaker) => (
                      <Option
                        key={`speaker-${speaker.device.deviceId}`}
                        value={speaker.device.deviceId}>
                        {speaker.device.label}
                      </Option>
                    ))}
                  </Select>
                </Stack>
              </Box>

              {/* Microphone select */}
              <Box>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <AudioControlButton toggle={isMicReady}>Microphone</AudioControlButton>
                  <Select
                    id="call-settings-microphone"
                    placeholder="Choose one…"
                    value={currentMic?.device?.deviceId ?? ''}
                    size="sm"
                    sx={{ flexGrow: 1 }}
                    onChange={updateMicrophone}
                    disabled={hasMicError}>
                    {!currentMic?.device?.deviceId && (
                      <Option disabled value="">
                        Choose one…
                      </Option>
                    )}
                    {microphones.map((mic) => (
                      <Option key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
                        {mic.device.label}
                      </Option>
                    ))}
                  </Select>
                </Stack>
              </Box>

              {/* Noise cancellation */}
              <Box sx={{ display: 'flex' }}>
                <Box>
                  <Typography
                    component={'div'}
                    startDecorator={
                      inputSettings?.audio?.processor !== undefined ? (
                        <Tooltip
                          title={
                            supportsAudioProcessing
                              ? 'Reduce background noise from your mic'
                              : 'Background noise reduction is not supported by your client'
                          }
                          placement="top">
                          <Switch
                            checked={
                              !hasMicError &&
                              inputSettings?.audio?.processor?.type === 'noise-cancellation'
                            }
                            onChange={toggleNoiseCancellation}
                            disabled={!supportsAudioProcessing || hasMicError}
                          />
                        </Tooltip>
                      ) : (
                        <CircularProgress variant="plain" size="sm" />
                      )
                    }
                    sx={{ opacity: inputSettings?.audio?.processor !== undefined ? 1 : 0.5 }}>
                    Reduce Background Noise
                  </Typography>
                  {inputSettingsErrorMessage && (
                    <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                      {inputSettingsErrorMessage}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
        {showMeetingUrl && (
          <Box sx={{ pb: 4 }}>
            <FormLabel>Meeting Link</FormLabel>
            <MeetingLink agendaId={agendaId} meetingId={meetingId} />
          </Box>
        )}
      </Stack>
    </div>
  );
}
