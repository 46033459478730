import { useState } from 'react';

import { Modal } from '@/components/reusable';
import HairCheck from '@/components/video/HairCheck';
import { useCall } from '@/hooks/call';

const HairCheckModal = ({ open, meetingName, agendaId, meetingId }) => {
  // passing closing responsibility to HairCheck so it can turn cameras back off
  const [shouldClose, setShouldClose] = useState(false);

  const { closeHaircheckWithoutJoining } = useCall();

  const onClose = (_event, reason) => {
    if (reason === 'closeClick') {
      closeHaircheckWithoutJoining();
    }
    setShouldClose(false);
  };

  return (
    <Modal open={open} onClose={() => setShouldClose(true)}>
      <HairCheck
        onClose={() => onClose(null, 'closeClick')}
        shouldClose={shouldClose}
        setShouldClose={setShouldClose}
        meetingName={meetingName}
        agendaId={agendaId}
        meetingId={meetingId}
      />
    </Modal>
  );
};

export default HairCheckModal;
