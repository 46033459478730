import {
  DailyEventObject,
  DailyEventObjectActiveSpeakerChange,
  DailyEventObjectAppMessage,
  DailyEventObjectCameraError,
  DailyEventObjectParticipant,
  DailyParticipant,
} from '@daily-co/daily-js';
import { DailyAudio, DailyProvider, useCallObject } from '@daily-co/daily-react';
import { useGuestRoom, useUser } from '@groupthinkai/groupthink';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';
import { useLocalStorage } from 'usehooks-ts';

import { usePrevious } from '@/hooks/util';

type Action =
  | { type: 'SET_MEETING_DETAILS'; roomId: string; agendaId: string; meetingId: string }
  | { type: 'PRE_JOIN_ROOM' }
  | { type: 'DISMISS_PRE_JOIN' }
  | { type: 'STARTED_CAMERA' }
  | { type: 'SET_CAMERA_ERROR'; cameraError: DailyEventObjectCameraError }
  | { type: 'JOINED_ROOM' }
  | { type: 'LEAVING_ROOM' }
  | { type: 'SHOW_RECAP_OVERLAY' }
  | { type: 'DISMISS_RECAP_OVERLAY' }
  | { type: 'LEFT_ROOM'; reason: CallEndedReason }
  | {
      type: 'ACTIVE_SPEAKER_CHANGE';
      activeSpeaker: DailyEventObjectActiveSpeakerChange['activeSpeaker'];
    }
  | { type: 'SET_VIDEO_IN_SIDEBAR'; videoInSidebar: boolean }
  | { type: 'SET_PARTICIPANTS'; participants: DailyParticipant[] }
  | { type: 'SET_ERROR'; error: any }
  | { type: 'CLEAR_ALL' }
  | { type: 'SET_CALL_ACTIVE_IN_OTHER_TAB'; isActive: boolean }
  | { type: 'CALL_STATUS_CHECK' };
type Dispatch = (action: Action) => void;
type State = {
  error: any;
  cameraError: DailyEventObjectCameraError | null;
  roomId: string | null;
  agendaId: string | null;
  meetingId: string | null;
  previousAgendaId: string | null;
  previousMeetingId: string | null;
  callState: CallState;
  activeSpeakerId: string | null;
  videoInSidebar: boolean;
  participants: DailyParticipant[];
  isCallActiveInOtherTab: boolean;
};

// Define message types
type BroadcastMessage = {
  type: 'CALL_STATUS_CHECK' | 'CALL_STATUS_RESPONSE' | 'CALL_JOINED' | 'CALL_LEFT';
  isInCall?: boolean;
  timestamp: number;
};

// Create a singleton channel instance
const createCallChannel = () => {
  let instance: BroadcastChannel | null = null;

  return {
    getInstance: () => {
      if (!instance) {
        instance = new BroadcastChannel('groupthink-call-coordination');
      }
      return instance;
    },
    closeChannel: () => {
      instance?.close();
      instance = null;
    },
  };
};

const callChannel = createCallChannel();

export const enum CallState {
  /**
   * The call is idle. The user has not yet joined a room.
   */
  Idle = 'idle',

  /**
   * The user is in the haircheck, but has not yet joined a room.
   */
  Haircheck = 'haircheck',

  /**
   * The user has dismissed the haircheck. They should not see the haircheck again until they explicitly try to join a room again.
   */
  Ignored = 'ignored', // The user dismissed the haircheck

  /**
   * The user has joined the room.
   */
  Joined = 'joined',

  /**
   * The user is in the process of leaving the room.
   */
  Leaving = 'leaving',

  /**
   * An error occurred.
   */
  Error = 'error',
}

export const enum CallEndedReason {
  LeftMeeting = 'left-meeting',
  MeetingEnded = 'meeting-ended',
}

const initialState = {
  error: null,
  cameraError: null,
  roomId: null,
  agendaId: null,
  meetingId: null,
  previousAgendaId: null,
  previousMeetingId: null,
  callState: CallState.Idle,
  activeSpeakerId: null,
  videoInSidebar: false,
  participants: [],
  isCallActiveInOtherTab: false,
};

// Selectors
export const selectRoomId = (state: State) => state.roomId;
export const selectCallState = (state: State) => state.callState;
export const selectIsCallIdle = (state: State) => state.callState === CallState.Idle;
export const selectIsCallIdleOrIgnored = (state: State) =>
  state.callState === CallState.Idle || state.callState === CallState.Ignored;
export const selectHasJoinedCall = (state: State) => state.callState === CallState.Joined;
export const selectIsInHaircheck = (state: State) => state.callState === CallState.Haircheck;
export const selectActiveSpeakerId = (state: State) => state.activeSpeakerId;
export const selectError = (state: State) => state.error;
export const selectIsVideoInSidebar = (state: State) => state.videoInSidebar;
export const selectIsCallActiveInOtherTab = (state: State) => state.isCallActiveInOtherTab;

interface LeaveRoomParams {
  reason: CallEndedReason;
  skipRecap?: boolean;
}

interface MeetingDetails {
  roomId: string;
  agendaId?: string;
  meetingId?: string;
}

export const CallContext = React.createContext<
  | {
      state: State;
      dispatch: Dispatch;

      /**
       * Shares for external meetings (Meet/Teams/Zoom/Recall)
       */
      externalMeetingShares: any[];

      /**
       * Presents the haircheck, but does not yet join a room.
       */
      presentPreJoinHaircheck: (params: MeetingDetails) => void;

      /**
       * Dismisses the haircheck without joining a room.
       */
      closeHaircheckWithoutJoining: () => Promise<void>;

      /**
       * Starts the camera without joining the room.
       */
      startCamera: ({
        withVideo,
        withAudio,
      }: {
        withVideo?: boolean;
        withAudio?: boolean;
      }) => Promise<void>;

      /**
       * Join the room. The participant will be visible in the list of participants.
       */
      joinRoom: () => Promise<void>;

      /**
       * Leave the room.
       */
      leaveRoom: (params: LeaveRoomParams) => Promise<void>;

      dismissMeetingRecapOverlay: () => void;

      setCallSessionData: (payload) => Promise<void>;

      startSharingDocument: (documentId: string, userId) => void;

      stopSharingDocument: (documentId: string) => void;

      /**
       * Explicitly update the roomId, agendaId, and meetingId. This is used when navigating to an agenda page with an active meeting,
       * but the meeting details are not set yet.
       */
      updateMeetingDetails: (params: MeetingDetails) => void;

      /**
       * Tracks whether the video should be rendered in the sidebar or not.
       */
      setVideoInSidebar: (value: boolean) => void;

      /**
       * Mute a participant's audio.
       */
      muteParticipant: (session_id: string) => void;

      /**
       * Unmute a participant's audio.
       */
      unmuteParticipant: (session_id: string) => void;

      /**
       * Kick a participant off the call.
       */
      ejectParticipant: (session_id: string) => void;

      /**
       * Selectors for the state.
       */
      selectors: {
        selectRoomId: (state: State) => string | null;
        selectCallState: (state: State) => CallState;
        selectIsCallIdle: (state: State) => boolean;
        selectIsCallIdleOrIgnored: (state: State) => boolean;
        selectHasJoinedCall: (state: State) => boolean;
        selectIsInHaircheck: (state: State) => boolean;
        selectActiveSpeakerId: (state: State) => string | null;
        selectError: (state: State) => any;
        selectIsVideoInSidebar: (state: State) => boolean;
        selectIsCallActiveInOtherTab: (state: State) => boolean;
      };
    }
  | undefined
>(undefined);

function callReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_MEETING_DETAILS':
      return {
        ...state,
        roomId: action.roomId,
        agendaId: action.agendaId,
        meetingId: action.meetingId,
      };
    case 'PRE_JOIN_ROOM':
      return {
        ...state,
        callState: CallState.Haircheck,
        previousAgendaId: null,
        previousMeetingId: null,
      };
    case 'DISMISS_PRE_JOIN':
      return {
        ...state,
        callState: CallState.Ignored,
      };
    case 'STARTED_CAMERA':
      return {
        ...state,
        cameraError: null,
      };
    case 'JOINED_ROOM':
      return {
        ...state,
        error: false, // false or null ??
        cameraError: null,
        callState: CallState.Joined,
        activeSpeakerId: null,
        previousAgendaId: null,
        previousMeetingId: null,
      };
    case 'LEAVING_ROOM':
      return {
        ...state,
        callState: CallState.Leaving,
      };
    case 'SET_CAMERA_ERROR':
      return {
        ...state,
        cameraError: action.cameraError,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.error,
      };
    case 'ACTIVE_SPEAKER_CHANGE':
      return {
        ...state,
        activeSpeakerId: action.activeSpeaker?.peerId,
      };
    case 'SET_VIDEO_IN_SIDEBAR':
      return {
        ...state,
        videoInSidebar: action.videoInSidebar,
      };
    case 'SET_PARTICIPANTS':
      return {
        ...state,
        participants: action.participants,
      };
    case 'SHOW_RECAP_OVERLAY':
      return {
        ...state,
        previousAgendaId: state.agendaId ?? state.previousAgendaId,
        previousMeetingId: state.meetingId ?? state.previousMeetingId,
      };
    case 'DISMISS_RECAP_OVERLAY':
      return {
        ...state,
        previousAgendaId: null,
        previousMeetingId: null,
        callState: CallState.Idle,
      };
    case 'LEFT_ROOM':
      return {
        ...initialState,
        callState:
          action.reason === CallEndedReason.LeftMeeting ? CallState.Ignored : CallState.Idle,
        // Don't clear these! They're used by the recap overlay to redirect to the meeting that just ended.
        previousAgendaId: state.previousAgendaId,
        previousMeetingId: state.previousMeetingId,
      };
    case 'SET_CALL_ACTIVE_IN_OTHER_TAB':
      return {
        ...state,
        isCallActiveInOtherTab: action.isActive,
      };

    case 'CALL_STATUS_CHECK':
      // Reset the state and trigger a new check
      return {
        ...state,
        isCallActiveInOtherTab: false,
      };

    case 'CLEAR_ALL':
      return initialState;

    /**
     * This will flag any unhandled actions as a TypeScript error.
     */
    default: {
      const _exhaustiveCheck: never = action;
      throw new Error(`Unhandled action type: ${JSON.stringify(_exhaustiveCheck)}`);
    }
  }
}

const useLocalStorageKey = () => {
  const { user } = useUser('me');
  return 'groupthink_current_video_room_' + (user?.id ?? 'noid');
};

const useBroadcastChannel = (callState: CallState, wrappedDispatch: Dispatch) => {
  useEffect(() => {
    const channel = callChannel.getInstance();

    const handleMessage = (event: MessageEvent<BroadcastMessage>) => {
      console.debug('[CALL][Broadcast] Received message:', event.data);

      switch (event.data.type) {
        case 'CALL_STATUS_CHECK':
          if (callState === CallState.Joined) {
            channel.postMessage({
              type: 'CALL_STATUS_RESPONSE',
              isInCall: true,
              timestamp: Date.now(),
            });
          }
          break;

        case 'CALL_STATUS_RESPONSE':
          if (event.data.isInCall) {
            wrappedDispatch({
              type: 'SET_CALL_ACTIVE_IN_OTHER_TAB',
              isActive: true,
            });
          }
          break;

        case 'CALL_JOINED':
          wrappedDispatch({
            type: 'SET_CALL_ACTIVE_IN_OTHER_TAB',
            isActive: true,
          });
          break;

        case 'CALL_LEFT':
          wrappedDispatch({
            type: 'SET_CALL_ACTIVE_IN_OTHER_TAB',
            isActive: false,
          });
          break;
      }
    };

    const handleBeforeUnload = () => {
      console.debug('[CALL][Broadcast] Leaving call on beforeunload');
      if (callState === CallState.Joined) {
        channel.postMessage({
          type: 'CALL_LEFT',
        });
      }
    };

    channel.addEventListener('message', handleMessage);
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Send initial status check
    channel.postMessage({
      type: 'CALL_STATUS_CHECK',
      timestamp: Date.now(),
    });

    // Broadcast when joining call
    if (callState === CallState.Joined) {
      channel.postMessage({
        type: 'CALL_JOINED',
        isInCall: true,
        timestamp: Date.now(),
      });
    }

    return () => {
      // Broadcast when leaving call
      if (callState === CallState.Joined) {
        channel.postMessage({
          type: 'CALL_LEFT',
          isInCall: false,
          timestamp: Date.now(),
        });
      }

      channel.removeEventListener('message', handleMessage);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [callState, wrappedDispatch]);
};

type CallProviderProps = { children: React.ReactNode };

export const CallProvider = ({ children }: CallProviderProps) => {
  const router = useRouter();

  const { mutate: globalMutate } = useSWRConfig();

  /**
   * Used to trigger a re-render when a participant joins, leaves, or updates.
   */
  const [updateParticipants, setUpdateParticipants] = useState<string | undefined>();

  const [roomId, setRoomId] = useLocalStorage<string | null>(useLocalStorageKey(), null);

  /**
   * -------------------------------
   * Reducer
   * -------------------------------
   *
   * Standard useReducer pattern, but the reducer is initialized with the roomId from local storage, and we use a wrapper around the dispatch function to persist the roomId to local storage.
   */
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(callReducer, {
    ...initialState,
    roomId,
  });
  const wrappedDispatch = useCallback(
    (action: Action) => {
      dispatch(action);
      if (action.type === 'SET_MEETING_DETAILS') {
        setRoomId(action.roomId);
      }
    },
    [setRoomId]
  );

  const { room } = useGuestRoom(state.roomId);

  /**
   * -------------------------------
   * Daily Call Object Instantiation
   * -------------------------------
   *
   * We can't optionally call `useCallObject` without violating the rules of hooks. But we can condition the creation of the instance based on the presence of a token and the call state being non-idle, e.g. we're in a haircheck or in a call.
   */
  const shouldCreateInstance = () => room?.token && state.callState !== CallState.Idle;

  const roomUrl = `https://${process.env.NEXT_PUBLIC_DAILY_DOMAIN_ID}.daily.co/${room?.id}`;
  const providerProps = useMemo(() => {
    return {
      url: roomUrl,
      token: room?.token ?? '',
    };
  }, [roomUrl, room?.id]);

  const callObject = useCallObject({
    options: providerProps,
    shouldCreateInstance,
  });

  /**
   * -------------------------------
   * Public API
   * -------------------------------
   *
   * These functions are exposed to the rest of the application through the CallContext.
   */
  const presentPreJoinHaircheck = useCallback(
    (params: MeetingDetails) => {
      const { roomId, agendaId, meetingId } = params;
      console.debug('[CALL] presentPreJoinHaircheck', { roomId, agendaId, meetingId });

      if (!roomId) {
        throw new Error('roomId is required');
      }

      wrappedDispatch({ type: 'PRE_JOIN_ROOM' });
      wrappedDispatch({ type: 'SET_MEETING_DETAILS', roomId, agendaId, meetingId });
    },
    [wrappedDispatch]
  );

  const closeHaircheckWithoutJoining = useCallback(async () => {
    console.debug('[CALL] closeHaircheckWithoutJoining');

    if (!callObject.isDestroyed()) {
      callObject?.setLocalVideo(false);
      callObject?.setLocalAudio(false, { forceDiscardTrack: true });
      await callObject.destroy();
    }

    wrappedDispatch({ type: 'DISMISS_PRE_JOIN' });
    wrappedDispatch({ type: 'SET_MEETING_DETAILS', roomId: null, agendaId: null, meetingId: null });
  }, [callObject, wrappedDispatch]);

  const startCamera = useCallback(
    async ({
      withVideo = true,
      withAudio = true,
    }: {
      withVideo?: boolean;
      withAudio?: boolean;
    }) => {
      console.debug('[CALL] startCamera', { withVideo, withAudio });

      if (!state.roomId) {
        throw new Error('roomId is required');
      }

      if (callObject) {
        console.debug('[CALL] Leaving existing call before starting camera');
        callObject.leave();
      }

      // The default value for `audioSource` and `videoSource` is true, so we only need to set them to false if we want to disable them.
      let callProperties = {};
      if (!withAudio) {
        callProperties = { ...callProperties, audioSource: false };
      }
      if (!withVideo) {
        callProperties = { ...callProperties, videoSource: false };
      }

      callObject.on('camera-error', handleCameraError);
      await callObject
        .startCamera(callProperties)
        .then(() => {
          console.debug('[CALL] Camera started');
          wrappedDispatch({ type: 'STARTED_CAMERA' });
        })
        .catch((err) => {
          console.debug('[CALL] Error starting camera');
          if (err) {
            wrappedDispatch({ type: 'SET_ERROR', error: err });
          }
        })
        .finally(() => {
          callObject.off('camera-error', handleCameraError);
        });
    },
    [callObject, state.roomId, wrappedDispatch, globalMutate]
  );

  const joinRoom = useCallback(async () => {
    if (!callObject) {
      console.error('[CALL] joinRoom called without callObject');
      return;
    }

    console.debug('[CALL] joinRoom');

    await callObject
      .join()
      .then(() => {
        console.debug('[CALL] Joined room');
        wrappedDispatch({ type: 'JOINED_ROOM' });
      })
      .catch((err) => {
        console.debug('[CALL] Error joing room');
        console.error(err);
        if (err) {
          wrappedDispatch({ type: 'SET_ERROR', error: err });
        }
      });
  }, [callObject, wrappedDispatch, globalMutate]);

  const leaveRoom = useCallback(
    async (params: LeaveRoomParams) => {
      console.debug('[CALL] leaveRoom');
      const { reason, skipRecap = false } = params;

      // Immediately stop the camera and audio
      callObject?.setLocalAudio(false, { forceDiscardTrack: true });
      callObject?.setLocalVideo(false);

      if (state?.callState === CallState.Leaving) {
        console.warn('[CALL] leaveRoom called but already leaving');
        return;
      }

      if (state?.callState === CallState.Idle) {
        console.warn('[CALL] leaveRoom called but already idle');
        return;
      }

      if (!callObject) {
        console.warn('[CALL] leaveRoom called without callObject');
        return;
      }

      const previousAgendaId = state.agendaId;
      const previousMeetingId = state.meetingId;

      const shouldRedirect =
        reason === CallEndedReason.MeetingEnded &&
        previousAgendaId &&
        previousMeetingId &&
        !skipRecap &&
        !state.isCallActiveInOtherTab;

      if (shouldRedirect) {
        wrappedDispatch({ type: 'SHOW_RECAP_OVERLAY' });
      }

      wrappedDispatch({ type: 'LEAVING_ROOM' });

      await callObject
        .leave()
        .then(() => {
          return callObject.destroy();
        })
        .finally(() => {
          wrappedDispatch({ type: 'LEFT_ROOM', reason });
        });
    },
    [callObject, state?.callState, wrappedDispatch]
  );

  const dismissMeetingRecapOverlay = useCallback(() => {
    wrappedDispatch({ type: 'DISMISS_RECAP_OVERLAY' });
  }, [wrappedDispatch]);

  const setCallSessionData = useCallback(
    async (payload) => {
      if (!callObject) {
        console.error('[CALL] setCallSessionData called without callObject');
        return;
      }

      console.debug('[CALL] setCallSessionData');

      await callObject.setMeetingSessionData(payload, 'shallow-merge');
    },
    [callObject, wrappedDispatch, globalMutate]
  );

  const [externalMeetingShares, setExternalMeetingShares] = useState<any[]>([]);

  const startSharingDocument = useCallback(
    async (documentId, userId) => {
      console.debug('[CALL] startSharingDocument', { documentId, userId });

      const newShare = {
        type: 'document',
        documentId: documentId,
        userId: userId,
      };

      if (!callObject) {
        console.debug('[CALL] No callObject, using fallback for external meetings');
        // Fallback for external meetings - use state to store shares
        const existingShare = externalMeetingShares.find(
          (share) => share.documentId === documentId
        );
        if (existingShare) {
          console.debug('[CALL] Found existing external meeting share', { existingShare });
          return;
        }

        setExternalMeetingShares((prevShares) => [...prevShares, newShare]);
        return;
      }

      const currentState = callObject.meetingSessionState();
      console.debug('[CALL] startSharingDocument current state', { currentState });

      // we need to loop through the currentState.data?.shares array (if it exists) and make sure we don't already have a share
      // with this documentId (dupe userId is fine; they might be sharing multiple)
      // then we need to merge in the newShare

      // @ts-ignore
      const shares = currentState?.data?.shares || [];
      const existingShare = shares.find((share) => share.documentId === documentId);

      if (existingShare) {
        console.debug('[CALL] startSharingDocument found existing share', { existingShare });
        return;
      }

      const payload = {
        shares: [...shares, newShare],
      };

      await callObject.setMeetingSessionData(payload, 'shallow-merge');
    },
    [callObject, externalMeetingShares, wrappedDispatch, globalMutate]
  );

  const stopSharingDocument = useCallback(
    async (documentId) => {
      console.debug('[CALL] stopSharingDocument', { documentId });

      if (!callObject) {
        console.debug('[CALL] No callObject, using fallback for external meetings');
        // Fallback for external meetings - use state to store shares
        const existingShare = externalMeetingShares.find(
          (share) => share.documentId === documentId
        );
        if (!existingShare) {
          console.debug('[CALL] stopSharingDocument no existing external share found');
          return;
        }

        setExternalMeetingShares((prevShares) =>
          prevShares.filter((share) => share.documentId !== documentId)
        );
        return;
      }

      const currentState = callObject.meetingSessionState();
      console.debug('[CALL] stopSharingDocument current state', { currentState });

      // @ts-ignore
      const shares = currentState?.data?.shares || [];
      const existingShare = shares.find((share) => share.documentId === documentId);

      if (!existingShare) {
        console.debug('[CALL] stopSharingDocument no existing share found');
        return;
      }

      const payload = {
        shares: shares.filter((share) => share.documentId !== documentId),
      };

      await callObject.setMeetingSessionData(payload, 'shallow-merge');
    },
    [callObject, externalMeetingShares, wrappedDispatch, globalMutate]
  );

  const updateMeetingDetails = useCallback(
    (params: MeetingDetails) => {
      const { roomId, agendaId, meetingId } = params;
      console.debug('[CALL] updateMeetingDetails', { roomId, agendaId, meetingId });
      wrappedDispatch({ type: 'SET_MEETING_DETAILS', roomId, agendaId, meetingId });
    },
    [wrappedDispatch]
  );

  const setVideoInSidebar = useCallback(
    (videoInSidebar: boolean) => {
      console.debug('[CALL] setVideoInSidebar', { videoInSidebar });
      wrappedDispatch({ type: 'SET_VIDEO_IN_SIDEBAR', videoInSidebar });
    },
    [wrappedDispatch]
  );

  const muteParticipant = useCallback(
    (session_id: DailyParticipant['session_id']) => {
      if (!callObject) return;

      const p = state.participants?.find((p) => p?.session_id === session_id);
      const updateParticipantsKey = `mute-${p?.user_id}-${Date.now()}`;
      console.debug('[CALL] Muting participant audio', { session_id, key: updateParticipantsKey });

      if (p?.user_id === 'local') {
        callObject.setLocalAudio(false);
      } else {
        callObject.updateParticipant(session_id, {
          setAudio: false,
        });
      }
      setUpdateParticipants(updateParticipantsKey);
    },
    [callObject, state.participants]
  );

  const unmuteParticipant = useCallback(
    (session_id: DailyParticipant['session_id']) => {
      if (!callObject) return;

      const p = state.participants?.find((p) => p?.session_id === session_id);
      const updateParticipantsKey = `unmute-${p?.user_id}-${Date.now()}`;
      console.debug('[CALL] Unmuting participant audio', {
        session_id,
        key: updateParticipantsKey,
      });

      if (p?.user_id === 'local') {
        callObject.setLocalAudio(true);
      } else {
        callObject.updateParticipant(session_id, {
          setAudio: true,
        });
      }
      setUpdateParticipants(updateParticipantsKey);
    },
    [callObject]
  );

  const ejectParticipant = useCallback(
    (session_id: DailyParticipant['session_id']) => {
      if (!callObject) return;

      const p = state.participants?.find((p) => p?.session_id === session_id);
      const updateParticipantsKey = `eject-${p?.user_id}-${Date.now()}`;
      console.debug('[CALL] Ejecting participant', { session_id, key: updateParticipantsKey });

      callObject.updateParticipant(session_id, { eject: true });
      setUpdateParticipants(updateParticipantsKey);
    },
    [callObject]
  );

  /**
   * Debug Utilities
   *
   * Log events to the console.
   */
  const showEvent = (evt: DailyEventObject, key: string, payload: any) => {
    console.debug(`[CALL] [EVENT: ${evt.action}]`, { key, payload });
  };

  const handleCameraError = (evt: DailyEventObjectCameraError) => {
    console.warn('[CALL] Camera error', evt);
    wrappedDispatch({ type: 'SET_CAMERA_ERROR', cameraError: evt });
  };

  /**
   * Daily Event Handlers
   */
  const handleJoinedMeetingEvent = (evt: DailyEventObject) => {
    const { action } = evt;
    if (action !== 'joined-meeting') return;
    const updateParticipantsKey = `joined-meeting-${Date.now()}`;
    showEvent(evt, updateParticipantsKey, {
      participant: evt.participant,
    });
    setUpdateParticipants(updateParticipantsKey);
  };

  const handleLeftMeetingEvent = (evt: DailyEventObject) => {
    const { action } = evt;
    if (action !== 'left-meeting') return;
    const updateParticipantsKey = `left-meeting-${Date.now()}`;
    showEvent(evt, updateParticipantsKey, { participant: evt.participant });
    setUpdateParticipants(updateParticipantsKey);
  };

  const handleAppMessageEvent = async (evt: DailyEventObjectAppMessage) => {
    const { action, data, fromId } = evt;
    if (action !== 'app-message') return;
    showEvent(evt, `app-message-${fromId}-${Date.now()}`, { action, fromId, data });
  };

  const handleActiveSpeakerChangeEvent = (evt: DailyEventObjectActiveSpeakerChange) => {
    const { action, activeSpeaker } = evt;
    if (action !== 'active-speaker-change') return;
    showEvent(evt, `active-speaker-change-${activeSpeaker}-${Date.now()}`, { activeSpeaker });
    wrappedDispatch({ type: 'ACTIVE_SPEAKER_CHANGE', activeSpeaker });
  };

  const handleParticipantJoinedOrUpdatedEvent = (evt: DailyEventObjectParticipant) => {
    const { action, participant } = evt;
    if (action !== 'participant-joined' && action !== 'participant-updated') return;
    const updateParticipantsKey = `updated-${participant?.user_id}-${Date.now()}`;
    showEvent(evt, updateParticipantsKey, { participant: evt.participant });
    setUpdateParticipants(updateParticipantsKey);
  };

  const handleParticipantLeftEvent = (evt) => {
    const updateParticipantsKey = `left-${evt?.participant?.user_id}-${Date.now()}`;
    showEvent(evt, updateParticipantsKey, { participant: evt.participant });
    setUpdateParticipants(updateParticipantsKey);
  };

  const handlePlayTrackEvent = (evt) => {
    const updateParticipantsKey = `track-started-${evt?.participant?.user_id}-${Date.now()}`;
    showEvent(evt, updateParticipantsKey, { participant: evt.participant });
    setUpdateParticipants(updateParticipantsKey);
  };

  const handleDestroyTrackEvent = (evt) => {
    const updateParticipantsKey = `track-stopped-${evt?.participant?.user_id}-${Date.now()}`;
    showEvent(evt, updateParticipantsKey, { participant: evt.participant });
    setUpdateParticipants(updateParticipantsKey);
  };

  /**
   * -------------------------------
   * Effects
   * -------------------------------
   *
   * Update participants for any event that happens to keep the local participants list up to date.
   * We grab the whole participant list to make sure everyone's status is the most up-to-date.
   */
  useEffect(() => {
    if (!callObject) return;
    if (updateParticipants) {
      const participants = Object.values(callObject?.participants());
      wrappedDispatch({ type: 'SET_PARTICIPANTS', participants });
    }
  }, [updateParticipants, callObject]);

  /**
   * Leave the room whenever we transition from having an active meeting to not having an active meeting.
   */
  const agenda = room?.agendas?.[0];
  const hasRoom = Boolean(room);
  const hasActiveMeeting = Boolean(agenda?.active_meeting);
  const hadActiveMeeting = usePrevious(hasActiveMeeting);
  const shouldLeaveRoom = hasRoom && hadActiveMeeting && !hasActiveMeeting;
  const shouldClearRoomId = hasRoom && !hasActiveMeeting;
  useEffect(() => {
    if (shouldLeaveRoom) {
      leaveRoom({ reason: CallEndedReason.MeetingEnded });
    } else if (shouldClearRoomId) {
      wrappedDispatch({
        type: 'SET_MEETING_DETAILS',
        roomId: null,
        agendaId: null,
        meetingId: null,
      });
    }
  }, [shouldLeaveRoom, shouldClearRoomId, wrappedDispatch]);

  /**
   * Register Event Listeners
   */
  useEffect(() => {
    const showError = (e) => {
      console.debug('[CALL] [EVENT: error]');
      console.warn(e);
    };

    if (!callObject) return;
    callObject.on('error', showError);
    callObject.on('joined-meeting', handleJoinedMeetingEvent);
    callObject.on('left-meeting', handleLeftMeetingEvent);
    callObject.on('participant-joined', handleParticipantJoinedOrUpdatedEvent);
    callObject.on('participant-updated', handleParticipantJoinedOrUpdatedEvent);
    callObject.on('participant-left', handleParticipantLeftEvent);
    callObject.on('app-message', handleAppMessageEvent);
    callObject.on('active-speaker-change', handleActiveSpeakerChangeEvent);
    callObject.on('track-started', handlePlayTrackEvent);
    callObject.on('track-stopped', handleDestroyTrackEvent);

    return () => {
      // clean up
      callObject.off('error', showError);
      callObject.off('joined-meeting', handleJoinedMeetingEvent);
      callObject.off('left-meeting', handleLeftMeetingEvent);
      callObject.off('participant-joined', handleParticipantJoinedOrUpdatedEvent);
      callObject.off('participant-updated', handleParticipantJoinedOrUpdatedEvent);
      callObject.off('participant-left', handleParticipantLeftEvent);
      callObject.off('app-message', handleAppMessageEvent);
      callObject.off('active-speaker-change', handleActiveSpeakerChangeEvent);
      callObject.off('track-started', handlePlayTrackEvent);
      callObject.off('track-stopped', handleDestroyTrackEvent);
    };
  }, [callObject]);

  // Add cross-tab communication
  useBroadcastChannel(state.callState, wrappedDispatch);

  const value = React.useMemo(
    () => ({
      state,
      dispatch: wrappedDispatch,
      externalMeetingShares,
      selectors: {
        selectRoomId,
        selectCallState,
        selectIsCallIdle,
        selectIsCallIdleOrIgnored,
        selectHasJoinedCall,
        selectIsInHaircheck,
        selectActiveSpeakerId,
        selectError,
        selectIsVideoInSidebar,
        selectIsCallActiveInOtherTab,
      },
      presentPreJoinHaircheck,
      closeHaircheckWithoutJoining,
      startCamera,
      joinRoom,
      leaveRoom,
      dismissMeetingRecapOverlay,
      setCallSessionData,
      startSharingDocument,
      stopSharingDocument,
      updateMeetingDetails,
      setVideoInSidebar,
      muteParticipant,
      unmuteParticipant,
      ejectParticipant,
    }),
    [
      state,
      wrappedDispatch,
      externalMeetingShares,
      selectRoomId,
      selectCallState,
      selectIsCallIdle,
      selectIsCallIdleOrIgnored,
      selectHasJoinedCall,
      selectIsInHaircheck,
      selectActiveSpeakerId,
      selectError,
      selectIsVideoInSidebar,
      selectIsCallActiveInOtherTab,
      presentPreJoinHaircheck,
      closeHaircheckWithoutJoining,
      startCamera,
      joinRoom,
      setCallSessionData,
      startSharingDocument,
      stopSharingDocument,
      leaveRoom,
      updateMeetingDetails,
      setVideoInSidebar,
      muteParticipant,
      unmuteParticipant,
      ejectParticipant,
    ]
  );

  return (
    <CallContext.Provider value={value}>
      <DailyProvider callObject={callObject}>
        {children}
        <DailyAudio onPlayFailed={(e) => console.log(e)} />
      </DailyProvider>
    </CallContext.Provider>
  );
};

/**
 * Main entry point for consuming the CallContext.
 */
export function useCall() {
  const context = React.useContext(CallContext);
  if (context === undefined) {
    throw new Error('useCallContext must be used within a CallProvider');
  }
  return context;
}
