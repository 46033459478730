import { useCalendar, useOrganization } from '@groupthinkai/groupthink';
import { useEffect, useState } from 'react';

import ShareMeetingModal from '@/components/video/ShareMeetingModal';
import ShareMeetingWithCalendarPromptModal from '@/components/video/ShareMeetingWithCalendarPromptModal';
import { useCall } from '@/hooks/call';
import { useCallLayout } from '@/hooks/callLayout';

export default function StartPromo({ agenda }) {
  const [showShareMeetingModal, setShowShareMeetingModal] = useState(false);
  const [showConfigureCalendarModal, setShowConfigureCalendarModal] = useState(false);
  const { state: groupthinkVideoState } = useCallLayout();
  const { state, selectors } = useCall();
  const hasJoinedCall = selectors.selectHasJoinedCall(state);

  const organizationId = agenda?.workspace?.id;
  const { organization } = useOrganization(organizationId);
  const { calendar, hasConnectedCalendar } = useCalendar(
    organization?.id,
    organization?.calendar_id
  );

  useEffect(() => {
    // Wait for the user to join the call before evaluating...
    if (!hasJoinedCall) {
      return;
    }
    const hasAgenda = !!agenda;
    const isAlone =
      !groupthinkVideoState.participantVideos ||
      groupthinkVideoState.participantVideos?.length === 0;
    const isShouldCreateAgendasAsOrganizerEnabled =
      calendar && calendar.settings?.should_create_agendas_as_organizer;
    const isMeetingAttachedToEvent =
      agenda?.attached_to_event || agenda?.active_meeting?.calendar_vendor_event_id;
    const isImpromptuMeeting = hasAgenda && !isMeetingAttachedToEvent;
    const isUserNeedsCalendarSetup =
      !hasConnectedCalendar || !isShouldCreateAgendasAsOrganizerEnabled;

    // Both modals have an invite link, so we'll only show the Invite modal if the Calendar modal is not shown.
    if (isImpromptuMeeting && isUserNeedsCalendarSetup) {
      if (showConfigureCalendarModal === null) setShowConfigureCalendarModal(true);
      return;
    } else if (isAlone && (!hasAgenda || isImpromptuMeeting)) {
      if (showShareMeetingModal === null) setShowShareMeetingModal(!showConfigureCalendarModal);
      return;
    }
  }, [
    hasJoinedCall,
    agenda,
    groupthinkVideoState,
    calendar,
    hasConnectedCalendar,
    setShowShareMeetingModal,
    setShowConfigureCalendarModal,
  ]);

  return (
    <>
      <ShareMeetingWithCalendarPromptModal
        open={showConfigureCalendarModal}
        setOpen={setShowConfigureCalendarModal}
        agendaId={agenda?.id}
        meetingId={agenda?.active_meeting?.id}
      />
      <ShareMeetingModal
        open={showShareMeetingModal}
        setOpen={setShowShareMeetingModal}
        agendaId={agenda?.id}
      />
    </>
  );
}
