import { useUser } from '@groupthinkai/groupthink';
import { ContentCopyOutlined as ContentCopyOutlinedIcon } from '@mui/icons-material';
import { IconButton, Input, Tooltip } from '@mui/joy';
import * as React from 'react';

import { copyTextToClipboard } from '@/hooks/util';

export default function MeetingLink({
  agendaId = null,
  meetingId = null,
  tooltipTitle = null,
}: {
  agendaId?: string;
  meetingId?: string;
  tooltipTitle?: string;
}) {
  const { user } = useUser('me');

  const meetingUrl = new URL(process.env.NEXT_PUBLIC_FRONTEND_URL ?? window.location.href);
  meetingUrl.hash = ''; // Remove fragment

  // If neither agendaId nor meetingId are provided, the URL will be the current page URL
  if (agendaId && meetingId) {
    meetingUrl.pathname = `/agendas/${agendaId}/meetings/${meetingId}`;
  } else if (agendaId) {
    meetingUrl.pathname = `/agendas/${agendaId}`;
  }

  const clipboardUrl = `${meetingUrl.toString()}${user ? `?invited_by=${user.id}` : ''}`;

  const handleCopyLink = React.useCallback(() => {
    copyTextToClipboard(clipboardUrl);
  }, [clipboardUrl]);

  return (
    <Tooltip title={tooltipTitle || 'Anyone with this link can access the agenda'}>
      <Input
        defaultValue={meetingUrl.toString()}
        readOnly
        type="url"
        size="sm"
        endDecorator={
          <IconButton variant="soft" aria-label="Copy meeting link" onClick={handleCopyLink}>
            <ContentCopyOutlinedIcon />
          </IconButton>
        }
      />
    </Tooltip>
  );
}
