import { useParticipantIds, useScreenShare, ScreenShare } from '@daily-co/daily-react';
import * as React from 'react';

// TODO: If we're able to remove these dependencies on Daily React, we could possible merge this with call.tsx.

/**
 * CallLayoutPreset
 *
 * The overall layout of the video call. This is the layout that the user sees.
 * These correspond to the Video Layout Wireframes in Figma.
 *
 * The layouts can be categorized into 3 types, based on the number of columns and their symmetry:
 * - FocusLayout: an assymetric split columns layout with the focus on one side.
 * - HostLayout: a symmetric split columns layout.
 * - FullWidth Layout: a single column layout.
 *
 * This is the full list of supported layouts:
 * - screenShareParticipants: a FocusLayout with the screen share as the focus, attendees as the side
 * - screenShareChat: same as screenShareParticipants, but with ChatTranscriptNotes instead of the attendees
 * - fullAgenda: a HostLayout with the full agenda on one side, attendees on the other side, with ChatTranscriptNotes underneath the attendees
 * - defaultMeeting: a HostLayout with the attendees on one side (optional pinned video), and the FocusAgendaItem and ChatTranscriptNotes on the other side
 * - videoChat: a FocusLayout with the attendees as the focus, ChatTranscriptNotes as the side (TODO: we don't have CTN yet, so this is actually a FullWidth Layout)
 *
 * Refer to Figma Video Layout Wireframes for more details.
 *
 * participants
 * - grid: a static layout with all attendees in a grid
 * - bubble: a dynamic layout with attendees in bubbles
 */
type CallLayoutPreset =
  | 'screenShareParticipants'
  | 'screenShareChat'
  | 'fullAgenda'
  | 'defaultMeeting'
  | 'videoChat';

/**
 * ParticipantsLayoutPreset
 *
 * The layout of the participants in the video call. This is the layout that the user sees.
 *
 * - grid: a static layout with all attendees in a grid
 * - bubble: a dynamic layout with attendees in bubbles
 */
type ParticipantsLayoutPreset = 'bubble' | 'grid';

/**
 * CallLayoutHorizontalBias
 *
 * The horizontal bias of the layout. Determines which side will have more space available.
 */
type CallLayoutHorizontalBias = 'left' | 'right';

type Action =
  | { type: 'SET_LAYOUT_HORIZONTAL_BIAS'; bias: CallLayoutHorizontalBias }
  | { type: 'SET_PARTICIPANTS_LAYOUT'; layout: ParticipantsLayoutPreset }
  | { type: 'SET_PARTICIPANT_VIDEOS'; participants: string[] }
  | { type: 'SET_PARTICIPANT_SCREENS'; screens: ScreenShare[] }
  | { type: 'SET_AGENDA_ID'; agendaId: string }
  | { type: 'PIN_SCREEN'; id: string }
  | { type: 'UNPIN_SCREEN' }
  | { type: 'CLEAR_ALL' };
type Dispatch = (action: Action) => void;
type State = {
  callLayout?: CallLayoutPreset;
  participantsLayout?: ParticipantsLayoutPreset;
  layoutHorizontalBias?: CallLayoutHorizontalBias;
  pinnedScreen?: ScreenShare | null;
  participantVideos?: string[] | null;
  participantScreens?: ScreenShare[] | null;
  agendaId: string;
  prefersNotes: boolean; // If available, the user prefers to see ChatTranscriptNotes
};
type CallLayoutProviderProps = { children: React.ReactNode };
type GenericDispatchActionFunction = (dispatch: Dispatch) => void;

const CallLayoutContext = React.createContext<
  | {
      state: State;
      dispatch: Dispatch;
      setLayoutHorizontalBias: (dispatch: Dispatch, bias: CallLayoutHorizontalBias) => void;
      setParticipantsLayout: (dispatch: Dispatch, layout: ParticipantsLayoutPreset) => void;
      setParticipantVideos: (dispatch: Dispatch, participants: string[] | null) => void;
      setParticipantScreens: (dispatch: Dispatch, screens: ScreenShare[] | null) => void;
      setAgendaId: (dispatch: Dispatch, agendaId: string) => void;
      pinScreen: (dispatch: Dispatch, id: string) => void;
      unpinScreen: (dispatch: Dispatch) => void;
      clearAll: GenericDispatchActionFunction;
      groupthinkParticipantId: string | undefined;
    }
  | undefined
>(undefined);

function groupthinkVideoReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_LAYOUT_HORIZONTAL_BIAS':
      return {
        ...state,
        layoutHorizontalBias: action.bias,
      };
    case 'SET_PARTICIPANTS_LAYOUT':
      return {
        ...state,
        participantsLayout: action.layout,
      };
    case 'SET_PARTICIPANT_VIDEOS':
      return {
        ...state,
        participantVideos: action.participants,
      };
    case 'SET_PARTICIPANT_SCREENS': {
      if (state.pinnedScreen) {
        if (action.screens?.length > 0) {
          if (
            action.screens.find((_screen) => _screen.session_id === state.pinnedScreen?.session_id)
          ) {
            // Case 1: A screen was being shared, there has been a change in screens being shared, and the pinned screen is still being shared.
            // Result: Leave the pinned screen up, and update the participant screens.
            return {
              ...state,
              participantScreens: action.screens.filter((_screen) => {
                return _screen.session_id !== state.pinnedScreen.session_id;
              }),
            };
          } else {
            // Case 2: A screen was being shared, there has been a change in screens being shared, and the pinned screen is no longer being shared.
            // Result: Pin one of the remaining screens and update the participant screens.
            return {
              ...state,
              pinnedScreen: action.screens[0],
              participantScreens: action.screens.slice(1),
            };
          }
        } else {
          // Case 3: A screen was being shared, there has been a change in screens being shared, and now there are no screens being shared.
          // Result: Leave screensharing layout and clear screen sharing state.
          return {
            ...state,
            pinnedScreen: null,
            participantScreens: null,
            callLayout: state.agendaId ? 'defaultMeeting' : 'videoChat',
          };
        }
      } else {
        if (action.screens?.length > 0) {
          // Case 4: No screens were being shared, and now there are screens being shared.
          // Result: Pin one of the available screens and update the participant screens.
          return {
            ...state,
            pinnedScreen: action.screens[0],
            participantScreens: action.screens.slice(1),
            callLayout: 'screenShareParticipants',
          };
        } else {
          // Case 5: No screens were being shared, and now there are no screens being shared.
          // Result: Leave screensharing layout and clear screen sharing state.
          return {
            ...state,
            pinnedScreen: null,
            participantScreens: null,
            callLayout: state.agendaId ? 'defaultMeeting' : 'videoChat',
          };
        }
      }
    }
    case 'SET_AGENDA_ID': {
      let callLayout = state.callLayout;
      if (action.agendaId && state.callLayout === 'videoChat') {
        // If we are in a videoChat layout, we must switch to a layout that supports the agenda
        callLayout = 'defaultMeeting';
      } else if (
        !action.agendaId &&
        state.callLayout !== 'screenShareParticipants' &&
        state.callLayout !== 'screenShareChat'
      ) {
        // If we don't have an agenda, we should switch to a non-agenda layout, unless we are already in a screen share layout
        callLayout = 'videoChat';
      }
      return {
        ...state,
        agendaId: action.agendaId,
        callLayout,
      };
    }
    case 'PIN_SCREEN': {
      if (state.pinnedScreen) {
        // Case 1: There is already a screen pinned.
        // Result: Pin the new screen and remove it from the list of participant screens, and add the previously pinned screen back to the list
        return {
          ...state,
          pinnedScreen: state.participantScreens.find(
            (_screen) => _screen.session_id === action.id
          ),
          participantScreens: [
            ...state.participantScreens.filter((_screen) => action.id !== _screen.session_id),
            state.pinnedScreen,
          ],
        };
      }

      // Case 2: There's no screen pinned at this moment.
      // Result: Pin the screen and remove it from the list of participant screens
      return {
        ...state,
        pinnedScreen: state.participantScreens.find((_screen) => _screen.session_id === action.id),
        participantScreens: state.participantScreens.filter(
          (_screen) => action.id !== _screen.session_id
        ),
        callLayout: 'screenShareParticipants',
      };
    }
    case 'UNPIN_SCREEN': {
      return {
        ...state,
        // Unpin the screen
        pinnedScreen: null,
        // Add the unpinned screen back to the list of participant screens
        participantScreens: [...(state.participantScreens || []), state.pinnedScreen],
        // Even if there are other screens being shared, we assume the user wants to unpin the screen and go back to the default layout
        callLayout: state.agendaId ? 'defaultMeeting' : 'videoChat',
      };
    }
    case 'CLEAR_ALL':
      return {
        ...state,
        pinnedScreen: null,
        agendaId: null,
        callLayout: 'videoChat',
        participantsLayout: 'bubble',
        prefersNotes: false,
        participantVideos: null,
        participantScreens: null,
      };
    default: {
      const _exhaustiveCheck: never = action;
      throw new Error(`Unhandled action type: ${JSON.stringify(_exhaustiveCheck)}`);
    }
  }
}

export function CallLayoutProvider({ children }: CallLayoutProviderProps) {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(groupthinkVideoReducer, {
    agendaId: null,
    callLayout: 'videoChat',
    layoutHorizontalBias: 'left',
    participantsLayout: 'bubble',
    prefersNotes: false,
  });

  const remoteNonGroupthinkParticipantIds = useParticipantIds({
    filter: (participant) => {
      return participant.user_name !== 'Groupthink' && !participant.local;
    },
  });
  const groupthinkParticipantId = useParticipantIds({
    filter: (participant) => {
      return participant.user_name === 'Groupthink';
    },
  })?.[0];

  const { screens } = useScreenShare();

  React.useEffect(() => {
    // Pinning non-screens is not implemented yet.
    // const nonPinnedRemoteNonGroupthinkParticipantIds = remoteNonGroupthinkParticipantIds.filter(
    //   (session_id) => session_id !== state.pinnedSessionId
    // );
    // setParticipantVideos(dispatch, nonPinnedRemoteNonGroupthinkParticipantIds);
    setParticipantVideos(dispatch, remoteNonGroupthinkParticipantIds);
  }, [remoteNonGroupthinkParticipantIds]);

  React.useEffect(() => {
    // There may have been a screen sharing change.
    // Either someone started sharing their screen, or stopped sharing their screen.

    setParticipantScreens(dispatch, screens);
  }, [screens]);

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      setLayoutHorizontalBias,
      setParticipantsLayout,
      setParticipantVideos,
      setParticipantScreens,
      setAgendaId,
      pinScreen,
      unpinScreen,
      clearAll,
      groupthinkParticipantId,
    }),
    [state, dispatch, groupthinkParticipantId, remoteNonGroupthinkParticipantIds, screens?.length]
  );
  return <CallLayoutContext.Provider value={value}>{children}</CallLayoutContext.Provider>;
}

export function useCallLayout() {
  const context = React.useContext(CallLayoutContext);
  if (context === undefined) {
    throw new Error('useCallLayout must be used within a CallLayoutProvider');
  }
  return context;
}

/**
 * Set the layout bias.
 *
 * @param dispatch - The dispatch function.
 * @param bias - The layout bias.
 */
function setLayoutHorizontalBias(dispatch: Dispatch, bias: CallLayoutHorizontalBias) {
  dispatch({ type: 'SET_LAYOUT_HORIZONTAL_BIAS', bias });
}

/**
 * Set the layout of the participants in the video call.
 *
 * @param dispatch - The dispatch function.
 * @param layout - The layout of the participants in the video call.
 */
function setParticipantsLayout(dispatch: Dispatch, layout: ParticipantsLayoutPreset) {
  dispatch({ type: 'SET_PARTICIPANTS_LAYOUT', layout });
}

/**
 * Request to pin a shared screen.
 * When pinned, the screen session is excluded from the list of participant screens.
 *
 * @param dispatch - The dispatch function.
 * @param id - The session id for the screen.
 */
function pinScreen(dispatch: Dispatch, id: string) {
  dispatch({ type: 'PIN_SCREEN', id });
}

/**
 * Request to unpin a shared screen.
 * When unpinned, the screen is added back to the list of participant screens.
 *
 * @param dispatch - The dispatch function.
 */
function unpinScreen(dispatch: Dispatch) {
  dispatch({ type: 'UNPIN_SCREEN' });
}

/**
 * Set the videos of the participants to display in the bubble/grid.
 * There may be additional participants in the call, but these are the ones we actually wish to display.
 *
 * @param dispatch - The dispatch function.
 * @param participants - The participants to display in the bubble/grid.
 */
function setParticipantVideos(dispatch: Dispatch, participants: string[]) {
  dispatch({ type: 'SET_PARTICIPANT_VIDEOS', participants });
}

/**
 * Set the screens of the participants to display in the bubble/grid.
 * There may be additional screens being shared in the call, but these are the ones we actually wish to display in the bubble/grid.
 *
 * @param dispatch - The dispatch function.
 * @param screens - The screens to display in the bubble/grid.
 */
function setParticipantScreens(dispatch: Dispatch, screens: ScreenShare[]) {
  dispatch({ type: 'SET_PARTICIPANT_SCREENS', screens });
}

/**
 * Set whether the meeting has an agenda. This allows the provider to select an appropriate layout.
 *
 * @param dispatch - The dispatch function.
 * @param agendaId - The id of the agenda.
 */
function setAgendaId(dispatch: Dispatch, agendaId: string) {
  dispatch({ type: 'SET_AGENDA_ID', agendaId });
}

/**
 * Clear all state.
 *
 * @param dispatch - The dispatch function.
 */
function clearAll(dispatch: Dispatch) {
  dispatch({ type: 'CLEAR_ALL' });
}
