import Daily from '@daily-co/daily-js';
import {
  DailyVideo,
  useAudioTrack,
  useLocalSessionId,
  useParticipantProperty,
  useVideoTrack,
} from '@daily-co/daily-react';
import { useGuestRoom } from '@groupthinkai/groupthink';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import GraphicEqRoundedIcon from '@mui/icons-material/GraphicEqRounded';
import MicOffIcon from '@mui/icons-material/MicOff';
import { Stack } from '@mui/joy';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { useCallback, useMemo, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import MuteParticipantButton from '@/components/video/MuteParticipantButton';
import RemoveParticipantButton from '@/components/video/RemoveParticipantButton';
import { useCall } from '@/hooks/call';
import { useCallLayout } from '@/hooks/callLayout';
import { useParticipantUsername } from '@/hooks/useParticipantProperty';

/**
 * Visual representation of a participant.
 * May or may not have video enabled.
 * May be rendered as a bubble or a rectangle in the case of a screenshare.
 *
 * @param {string} id - The participant's session ID.
 * @param {boolean} isActiveSpeaker - Whether the participant is the active speaker.
 * @param {boolean} isScreenShare - Whether the participant is sharing their screen.
 * @param {boolean} isLocal - Whether the participant is the local participant.
 * @param {boolean} isPreview - Whether the participant is in preview mode (haircheck/settings).
 * @param {boolean} isPinned - Whether the tile is pinned.
 * @param {boolean} isBubble - Whether the tile is a bubble or a rectangle.
 * @param {boolean} isContextMenuDisabled - Whether the context menu is disabled.
 * @param {object} sx - Custom styles to apply to the Tile container.
 */
export default function Tile({
  id,
  isActiveSpeaker = false,
  isScreenShare = false,
  isLocal = false,
  isPreview = false,
  isPinned = false,
  isBubble = true,
  isContextMenuDisabled = false,
  sx,
}) {
  const handle = useFullScreenHandle();
  const isFullscreen = handle.active;

  // make zIndex hover changes persistent
  const { zIndex, updateZIndex } = useDynamicZIndex();

  const audioState = useAudioTrack(id);
  const isAudioMuted = audioState.isOff;
  const isParticipantStatusBadgeHidden =
    (!isActiveSpeaker && !isAudioMuted) || isScreenShare || isPreview;

  const renderParticipantStatusBadge = useCallback(() => {
    const sx = {
      //backgroundColor: 'rgba(31,151,172,0.9)',
      borderRadius: isBubble ? '50%' : '5px',
      fontSize: '2.5rem',
      padding: '0.5rem',
      color: '#fff',
      position: 'absolute',
      bottom: '0px',
      zIndex: 5,
      left: 'calc(50% - 1.25rem)', // center
    };

    return isActiveSpeaker && !isAudioMuted ? (
      <GraphicEqRoundedIcon sx={sx} />
    ) : isAudioMuted ? (
      <MicOffIcon sx={sx} />
    ) : null;
  }, [isActiveSpeaker, isAudioMuted, isParticipantStatusBadgeHidden]);

  return (
    <Box
      position="relative"
      onMouseOver={isBubble ? updateZIndex : null}
      sx={(theme) => ({
        width: '100%',
        outline:
          isActiveSpeaker && !isAudioMuted ? `1px solid ${theme.palette.primary[500]}` : 'none',
        borderRadius: isScreenShare ? '4px' : isBubble ? '50%' : '4px',
        overflow: isScreenShare ? 'scroll' : 'hidden',
        maxHeight: isFullscreen ? '100%' : `calc(100vh)`,
        '&:hover': isContextMenuDisabled
          ? {}
          : {
              // transform: isScreenShare ? 'none' : 'scale(1.05)',
              '.tile-overlay': {
                display: 'flex',
              },
            },
        zIndex: isBubble ? (isActiveSpeaker ? highestZIndex + 1 : zIndex) : 'initial',
        ...sx,
      })}>
      {/* Going fullscreen will render <FullScreen />'s children */}
      <FullScreen handle={handle}>
        {renderParticipantStatusBadge()}
        {isScreenShare ? (
          <ScreenShareVideo sessionId={id} isFullscreen={isFullscreen} />
        ) : (
          <Box display={'flex'}>
            <ParticipantVideo
              sessionId={id}
              isScreenShare={isScreenShare}
              isLocal={isLocal}
              isFullscreen={isFullscreen}
              exitFullscreen={() => handle.exit()}
              isBubble={isBubble}
            />
          </Box>
        )}
        {isScreenShare && isFullscreen && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '2rem',
              left: '2rem',
              width: '15vh',
              height: '15vh',
              borderRadius: '50%',
              overflow: 'hidden',
              zIndex: 1000,
            }}>
            <ParticipantVideo sessionId={id} isLocal={isLocal} isBubble={false} />
          </Box>
        )}
        {!isContextMenuDisabled && (
          <TileOverlay
            sessionId={id}
            isScreenShare={isScreenShare}
            isLocal={isLocal}
            isPreview={isPreview}
            isPinned={isPinned}
            handle={handle}
          />
        )}
      </FullScreen>
    </Box>
  );
}

/**
 * Renders the participant's video or screenshare, with a fallback avatar.
 *
 * @param {string} sessionId - The participant's session ID.
 * @param {boolean} isScreenShare - Whether the participant is sharing their screen.
 * @param {boolean} isLocal - Whether the participant is the local participant.
 * @param {boolean} isFullscreen - Whether the video is fullscreen at this time.
 * @param {function} exitFullscreen - Callback to exit fullscreen mode.
 */
function ParticipantVideo({ sessionId, isLocal = false, isBubble = true }) {
  const videoState = useVideoTrack(sessionId);
  const isVideoOn = !videoState.isOff;

  let dailyVideoBubbleStyle = {
    width: '100%',
    height: 'auto',
    aspectRatio: isBubble ? '1' : 'initial',
    backgroundColor: '#eeeeee',
  };

  if (isVideoOn) {
    return (
      <DailyVideo
        automirror
        isLocal={isLocal}
        sessionId={sessionId}
        type="video"
        fit={'cover'}
        style={dailyVideoBubbleStyle}
      />
    );
  }

  return <TileAvatar sessionId={sessionId} isLocal={isLocal} isBubble={isBubble} />;
}

const dailyVideoScreenShareStyle = () => {
  return useMemo(
    () => ({
      width: '100%',
      height: 'auto',
      aspectRatio: 'unset',
      backgroundColor: '#eeeeee',
      maxHeight: '100%',
    }),
    []
  );
};
function ScreenShareVideo({ sessionId, isFullscreen }) {
  const daily_video = (
    <DailyVideo
      automirror
      sessionId={sessionId}
      type={'screenVideo'}
      style={dailyVideoScreenShareStyle(isFullscreen)}
    />
  );

  return daily_video;
}

function ActionButton({ children, title, ...props }) {
  return (
    <IconButton
      aria-label={title}
      sx={{
        '&:hover': { backgroundColor: 'rgba(178,178,178,0.3)' },
      }}
      {...props}>
      {children}
    </IconButton>
  );
}

export function FullscreenButton(props) {
  // The svg used here is the same one as FullScreen from icons-material.
  // We need to use svg in order to apply a stroke around the shape.
  return (
    <ActionButton title="Go Fullscreen" {...props}>
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#ffffff">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
            stroke="#000000"
            strokeWidth={1}
            strokeOpacity={0.87}
          />
        </svg>
      </SvgIcon>
    </ActionButton>
  );
}

function FullscreenExitButton(props) {
  // The svg used here is the same one as FullscreenExit from icons-material.
  // We need to use svg in order to apply a stroke around the shape.
  return (
    <ActionButton title="Exit Fullscreen" {...props}>
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          fill="#ffffff">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"
            stroke="#000000"
            strokeWidth={1}
            strokeOpacity={0.87}
          />
        </svg>
      </SvgIcon>
    </ActionButton>
  );
}

function PinScreenButton(props) {
  // The svg used here is the same one as OpenInFull from icons-material.
  // We need to use svg in order to apply a stroke around the shape.
  return (
    <ActionButton title="Expand" {...props}>
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#ffffff">
          <rect fill="none" height="24" width="24" />
          <polygon
            points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71"
            stroke="#000000"
            strokeWidth={1}
            strokeOpacity={0.87}
          />
        </svg>
      </SvgIcon>
    </ActionButton>
  );
}

function UnpinScreenButton(props) {
  // The svg used here is the same one as CloseSharp from icons-material.
  // We need to use svg in order to apply a stroke around the shape.
  return (
    <ActionButton title="Unpin" {...props}>
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#ffffff">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
            stroke="#000000"
            strokeWidth={1}
            strokeOpacity={0.87}
          />
        </svg>
      </SvgIcon>
    </ActionButton>
  );
}

/**
 * Displays a descriptive label over the tile on hover.
 *
 * @param {string} id - The participant's session ID.
 * @param {boolean} isScreenShare - Whether the participant is sharing their screen.
 * @param {boolean} isLocal - Whether the participant is the local participant.
 * @param {boolean} isPreview - Whether the participant is in preview mode (haircheck/settings).
 */
function TileOverlay({
  sessionId,
  handle,
  isScreenShare = false,
  isLocal = false,
  isPreview = false,
  isPinned = false,
}) {
  const username = useParticipantUsername({ sessionId, isScreenShare, isLocal });
  const [controlling, setControlling] = useState(false);

  const isFullscreen = handle.active;
  const { supportsFullscreen } = Daily.supportedBrowser();

  // make zIndex hover changes persistent
  const { dispatch, pinScreen, unpinScreen } = useCallLayout();

  const isFullscreenAvailable =
    isScreenShare && supportsFullscreen && !isFullscreen && document.fullscreenEnabled;

  if (isPreview) {
    return null;
  }

  return (
    <Box
      className="tile-overlay"
      sx={{
        position: controlling ? 'absolute' : 'sticky',
        display: controlling ? 'flex' : 'none',
        bottom: 0,
        height: controlling ? '100%' : '4.5rem',
        width: '100%',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.5)',
        backdropFilter: 'blur(5px)',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 10,
      }}>
      <Stack>
        {controlling && (
          <ActionButton
            title="Close Options"
            onClick={() => setControlling(false)}
            sx={{
              p: 0,
              borderRadius: '0px',
            }}>
            <KeyboardArrowDown
              sx={{
                color: 'white',
              }}
            />
          </ActionButton>
        )}
        <Typography level="body-md" color="white">
          {username}
        </Typography>

        {!controlling && (
          <ActionButton
            title="Options"
            onClick={() => setControlling(true)}
            sx={{
              p: 0,
            }}>
            <KeyboardArrowUp
              sx={{
                color: 'white',
              }}
            />
          </ActionButton>
        )}
      </Stack>
      <Box
        sx={{
          display: controlling ? 'flex' : 'none',
        }}>
        {isFullscreenAvailable && (
          <FullscreenButton
            onClick={() => {
              setControlling(false);
              handle.enter();
            }}
          />
        )}
        <ParticipantAdminControls sessionId={sessionId} isScreenShare={isScreenShare} />
        {isPinned && isScreenShare && !isFullscreen && (
          <UnpinScreenButton onClick={() => unpinScreen(dispatch)} />
        )}
        {!isPinned && isScreenShare && (
          <PinScreenButton onClick={() => pinScreen(dispatch, sessionId)} />
        )}
        {isFullscreen && (
          <>
            <FullscreenExitButton onClick={() => handle.exit()} />
          </>
        )}
      </Box>
    </Box>
  );
}

function ParticipantAdminControls({ sessionId, isScreenShare }) {
  const localSessionId = useLocalSessionId();
  const permissions = useParticipantProperty(sessionId, 'permissions');
  const localPermissions = useParticipantProperty(localSessionId, 'permissions');
  const isThisParticipantAnAdmin = permissions?.canAdmin;
  const canLocalUserAdmin = localPermissions?.canAdmin;

  const audioState = useAudioTrack(sessionId);

  // TODO: Get actual owner status from the room object
  const isLocalUserAnOwner = false;
  const isLocal = sessionId === localSessionId;
  const canLocalUserMuteThisParticipant = canLocalUserAdmin;
  const canLocalUserRemoveThisParticipant =
    isLocalUserAnOwner || (canLocalUserAdmin && !isThisParticipantAnAdmin);
  const isAudioOn = !audioState.isOff;
  const isParticipantMuteButtonVisible = canLocalUserMuteThisParticipant && isAudioOn;
  const isParticipantRemoveButtonVisible = canLocalUserRemoveThisParticipant && !isLocal;
  const isControlsVisible =
    !isScreenShare && (isParticipantMuteButtonVisible || isParticipantRemoveButtonVisible);

  return (
    <>
      {isControlsVisible && <Divider orientation="vertical" flexItem />}
      {isParticipantMuteButtonVisible && <MuteParticipantButton sessionId={sessionId} />}
      {isParticipantRemoveButtonVisible && <RemoveParticipantButton sessionId={sessionId} />}
    </>
  );
}

/**
 * When a participant's video is off, render a placeholder Avatar.
 *
 * @param {string} sessionId - The participant's session ID.
 * @param {boolean} isLocal - Whether the participant is the local participant.
 */
function TileAvatar({ sessionId, isLocal = false, isBubble = true }) {
  const { state: callState, selectors } = useCall();
  const currentVideoRoomId = selectors.selectRoomId(callState);

  const username = useParticipantUsername({ sessionId, isLocal });
  const user_id = useParticipantProperty(sessionId, 'user_id');
  const { room } = useGuestRoom(currentVideoRoomId);

  const participantInitials =
    user_id === 'groupthink' ? 'GT' : isLocal ? '(You)' : nameToInitials(username);

  const agendaUsers = room?.agendas[0]?.users;
  const participantData = agendaUsers?.find((user) => user.id == user_id); // need to use == here because user_id is a string and user.id is an int
  const avatarUrl = user_id === 'groupthink' ? '/gt_primary.svg' : participantData?.avatar;
  const backgroundColor = user_id === 'groupthink' ? '#FFFFFF' : '#BBBBBB';
  return (
    <Avatar
      alt={username}
      src={avatarUrl}
      sx={{
        width: '100%',
        height: 'auto',
        aspectRatio: isBubble ? '1' : '16/9',
        borderRadius: isBubble ? '50%' : '5px',
        backgroundColor,
      }}>
      {participantInitials}
    </Avatar>
  );
}

/**
 * @param {string} name - The participant's name.
 * @returns {string} - The participant's initials.
 */
function nameToInitials(name) {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
}

// Simple global variable for z-index management
let highestZIndex = 1;

const useDynamicZIndex = () => {
  const [zIndex, setZIndex] = useState(highestZIndex);

  const updateZIndex = () => {
    highestZIndex += 1;
    setZIndex(highestZIndex);
  };

  return { zIndex, updateZIndex };
};
