import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';
import React from 'react';

import { useCallLayout } from '@/hooks/callLayout';

export default function VideoBiasControlButton() {
  const { state, dispatch, setLayoutHorizontalBias } = useCallLayout();

  const isBiasLeft = state.layoutHorizontalBias === 'left';
  const toggleBias = () => {
    setLayoutHorizontalBias(dispatch, isBiasLeft ? 'right' : 'left');
  };

  const label = isBiasLeft ? 'Expand' : 'Collapse';
  const accessibilityLabel = isBiasLeft ? 'Expand this panel' : 'Collapse this panel';

  return (
    <Tooltip title={label} placement="top" arrow>
      <IconButton onClick={toggleBias} aria-label={accessibilityLabel} color="primary">
        {isBiasLeft ? (
          <SwitchRightIcon sx={{ fontSize: '1.25rem' }} />
        ) : (
          <SwitchLeftIcon sx={{ fontSize: '1.25rem' }} />
        )}
      </IconButton>
    </Tooltip>
  );
}
