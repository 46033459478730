import { useLocalSessionId } from '@daily-co/daily-react';
import { useAgenda, useCalendar, useOrganization } from '@groupthinkai/groupthink';
import { Box, Stack, Tooltip, useTheme } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { useElementSize } from 'usehooks-ts';

import MeetingStatus from '@/components/agenda/MeetingStatus';
import StartPromo from '@/components/agenda/StartPromo';
import HairCheckModal from '@/components/video/HairCheckModal';
import Tile from '@/components/video/Tile';
import Tray from '@/components/video/Tray';
import VideoBiasControlButton from '@/components/video/VideoBiasControlButton';
import { useCall } from '@/hooks/call';
import { useCallLayout } from '@/hooks/callLayout';
import useCloudLayout from '@/hooks/useCloudLayout';

export default function CallContainer({ agendaId }) {
  const { agenda } = useAgenda(agendaId);

  const organizationId = agenda?.workspace?.id;
  const { organization } = useOrganization(organizationId);
  const { calendar, hasConnectedCalendar } = useCalendar(
    organization?.id,
    organization?.calendar_id
  );

  const { state: callState, selectors } = useCall();
  const isCallIdleOrIgnored = selectors.selectIsCallIdleOrIgnored(callState);
  const isInHaircheck = selectors.selectIsInHaircheck(callState);
  const hasJoinedCall = selectors.selectHasJoinedCall(callState);

  const { dispatch, setLayoutHorizontalBias } = useCallLayout();

  const [showShareMeetingModal, setShowShareMeetingModal] = useState(null);
  const [showConfigureCalendarModal, setShowConfigureCalendarModal] = useState(null);
  const theme = useTheme();
  const mdPlus = useMediaQuery(theme.breakpoints.up('md'));

  // get the query string from the URL
  const router = useRouter();
  const { query } = router;

  const {
    state: groupthinkVideoState,
    dispatch: groupthinkVideoDispatch,
    setAgendaId,
  } = useCallLayout();

  useEffect(() => {
    if (agenda?.id) {
      setAgendaId(groupthinkVideoDispatch, agenda.id);
    }
  }, [agenda?.id]);

  /**
   * Bias towards the agenda while the user is not in a call.
   */
  useEffect(() => {
    if (isCallIdleOrIgnored) {
      setLayoutHorizontalBias(dispatch, 'left');
    } else if (isInHaircheck || hasJoinedCall) {
      setLayoutHorizontalBias(dispatch, 'left');
    }
  }, [isInHaircheck, hasJoinedCall, isCallIdleOrIgnored]);

  /**
   * -------------------------------
   * Modals
   * -------------------------------
   *
   * Configure Calendar Connection
   * - Displayed if this is an impromptu meeting (the agenda is not attached to an event),
   *   and the user has not set up at least one calendar connection, or they have
   *   but they have not enabled "Automatically add Groupthink to calendar events you organize".
   *
   * Invite People to Meeting
   * - Displayed if this is an impromptu meeting (the agenda is not attached to an event),
   *   and the user has a calendar set up, they have enabled "Automatically add Groupthink to calendar events you organize",
   *   but the user is alone in the meeting.
   * - Displayed if this is a video room without an agenda, and the user is by themselves.
   */
  useEffect(() => {
    // Wait for the user to join the call before evaluating...
    if (!hasJoinedCall) {
      return;
    }
    const isAlone =
      !groupthinkVideoState.participantVideos ||
      groupthinkVideoState.participantVideos?.length === 0;
    const isShouldCreateAgendasAsOrganizerEnabled =
      calendar && calendar.settings?.should_create_agendas_as_organizer;
    const isMeetingAttachedToEvent =
      agenda?.attached_to_event || agenda?.active_meeting?.calendar_vendor_event_id;
    const isImpromptuMeeting = !isMeetingAttachedToEvent;
    const isUserNeedsCalendarSetup =
      !hasConnectedCalendar || !isShouldCreateAgendasAsOrganizerEnabled;

    // Both modals have an invite link, so we'll only show the Invite modal if the Calendar modal is not shown.
    if (isImpromptuMeeting && isUserNeedsCalendarSetup) {
      if (showConfigureCalendarModal === null) setShowConfigureCalendarModal(true);
      return;
    } else if (isAlone && isImpromptuMeeting) {
      if (showShareMeetingModal === null) setShowShareMeetingModal(!showConfigureCalendarModal);
      return;
    }
  }, [
    agenda,
    groupthinkVideoState,
    calendar,
    hasConnectedCalendar,
    setShowShareMeetingModal,
    setShowConfigureCalendarModal,
  ]);

  const participantsComponent = <ParticipantVideos />;

  const renderHaircheckIfNecessary = React.useCallback(() => {
    // If the user already joined the meeting, avoid rendering the haircheck modal.
    // This fixes an issue where the haircheck modal would be rendered, but in a closed state, which would cause unnecessary calls to startCamera() and so on.

    if (hasJoinedCall || (router.isReady && query?.autoJoin === 'true')) return null;

    if (!mdPlus) {
      return <HairCheckModal open={isInHaircheck} meetingName={agenda?.name} />;
    }

    return (
      <Box sx={{ px: 4, pb: 4, maxWidth: '800px' }}>
        <HairCheckModal
          open={isInHaircheck}
          meetingName={agenda?.name}
          agendaId={agenda?.id}
          meetingId={agenda?.active_meeting?.id}
        />
      </Box>
    );
  }, [hasJoinedCall, isInHaircheck, router.isReady, query]);

  if (isCallIdleOrIgnored) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: {
          xs: `
'controls'
'participants'`,
          lg: `
'participants'
'controls'`,
        },
        gridTemplateRows: { xs: '40px 1fr', lg: 'calc(100% - 112px) 64px' },
        height: '100%',
        ml: 2,
        borderBottomLeftRadius: 4,
      }}>
      {renderHaircheckIfNecessary()}
      <Box
        sx={{
          gridArea: 'participants',
          px: 2,
          py: 1,
          flexGrow: 1,
          overflow: 'hidden',
          borderTopLeftRadius: '8px',
          backgroundColor: 'neutral.800',
        }}>
        {participantsComponent}
      </Box>
      <Box
        sx={{
          gridArea: 'controls',
        }}>
        <Stack direction="row" alignItems="center">
          <Box position="absolute" pl={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <VideoBiasControlButton />
          </Box>
          <Tray agendaId={agenda?.id} meetingId={agenda?.active_meeting?.id} />
        </Stack>
      </Box>
      <StartPromo agenda={agenda} />
    </Box>
  );
}

function ParticipantVideos() {
  const { state: groupthinkVideoState } = useCallLayout();

  if (groupthinkVideoState.participantVideos?.length <= 1) {
    return <FocusedParticipantVideoGrid />;
  }

  return <BubbleGrid />;
}

function FocusedParticipantVideoGrid() {
  const {
    dispatch,
    state: groupthinkVideoState,
    groupthinkParticipantId,
    setPinnedVideoSession,
  } = useCallLayout();

  const localSessionId = useLocalSessionId();
  const { state: callState, selectors } = useCall();
  const hasJoinedCall = selectors.selectHasJoinedCall(callState);
  const activeSpeakerId = selectors.selectActiveSpeakerId(callState);
  const isInHaircheck = selectors.selectIsInHaircheck(callState);
  const isVideoInSidebar = selectors.selectIsVideoInSidebar(callState);

  const isAlone = groupthinkVideoState.participantVideos?.length < 1;

  const otherParticipantVideoSessionId =
    groupthinkVideoState.participantVideos?.length > 0
      ? groupthinkVideoState.participantVideos[0]
      : null;

  // If it's just the one local participant, render them in the big video tile
  const focusedVideoParticipantId = isAlone ? localSessionId : otherParticipantVideoSessionId;

  return (
    <Box
      sx={{
        maxWidth: '100%',
        height: 'calc(100% - 10px)',
      }}>
      <Box
        className="focus-video"
        id={'focus-video'}
        sx={{
          mx: { xs: 0, md: 'auto' },
          my: 1,
          maxWidth: '100%',
          height: '100%',
          aspectRatio: '16/9',
        }}>
        {(!isAlone || hasJoinedCall) && (
          <Tile
            id={focusedVideoParticipantId}
            isActiveSpeaker={focusedVideoParticipantId === activeSpeakerId}
            isLocal={isAlone}
            isBubble={false}
            onPinParticipant={() => setPinnedVideoSession(dispatch, focusedVideoParticipantId)}
            sx={{
              maxWidth: `100%`,
              maxHeight: `100%`,
            }}
          />
        )}
        {/* Displays a subtle gray box within the participants container when the user is in haircheck mode. */}
        {isInHaircheck && !isVideoInSidebar && (
          <Box
            sx={{
              borderRadius: 4,
              backgroundColor: '#b2b2b2',
              height: '100%',
            }}></Box>
        )}
      </Box>
      <Stack
        sx={{
          position: 'absolute',
          top: { xs: '16rem', lg: isVideoInSidebar ? '10.25rem' : '7.5rem' },
          right: isVideoInSidebar ? '0.5rem' : 0,
          width: isAlone ? '10%' : '20%',
          mr: 1,
          gap: '1rem',
        }}>
        <Box
          sx={{
            width: '100%',
          }}>
          {!isAlone && hasJoinedCall && (
            <Tooltip title="You" variant="solid" placement={'left'} arrow>
              <Box>
                <Tile
                  id={localSessionId}
                  isActiveSpeaker={localSessionId === activeSpeakerId}
                  isLocal={true}
                  isBubble={true}
                  isContextMenuDisabled={true}
                />
              </Box>
            </Tooltip>
          )}
          {groupthinkParticipantId && (
            <Tooltip title="Groupthink" variant="solid" placement={'right'} arrow>
              <Box
                sx={{
                  width: isAlone ? '100%' : '40%',
                  height: isAlone ? '100%' : '40%',
                  position: 'absolute',
                  bottom: '-20%',
                  left: '-10%',
                }}>
                <Tile
                  id={groupthinkParticipantId}
                  isActiveSpeaker={groupthinkParticipantId === activeSpeakerId}
                  isLocal={false}
                  isBubble={true}
                  isContextMenuDisabled={true}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

function ParticipantVideoList({ tileStyles = [], defaultStyle = {}, wrapSx = {} }) {
  const {
    state: groupthinkVideoState,
    dispatch,
    setPinnedVideoSession,
    groupthinkParticipantId,
  } = useCallLayout();

  /* This is for displaying our self-view. */
  const localSessionId = useLocalSessionId();
  const isAlone =
    groupthinkVideoState.participantScreens?.length < 1 &&
    groupthinkVideoState.participantVideos?.length < 1 &&
    !groupthinkVideoState.pinnedSessionId;
  const { state: callState, selectors } = useCall();
  const hasJoinedCall = selectors.selectHasJoinedCall(callState);
  const activeSpeakerId = selectors.selectActiveSpeakerId(callState);

  let tileIndex = 0;
  return (
    <Box
      className="participant-list-wrap"
      sx={{
        overflowX: 'scroll',
        height: '100%',
        ...wrapSx,
      }}>
      {groupthinkVideoState.participantVideos?.map((id) => (
        <Tile
          key={id}
          id={id}
          isActiveSpeaker={id === activeSpeakerId}
          onPinParticipant={() => setPinnedVideoSession(dispatch, id)}
          sx={{ ...(tileStyles[tileIndex++] || defaultStyle) }}
        />
      ))}
      {localSessionId && hasJoinedCall && (
        <Tile
          id={localSessionId}
          isActiveSpeaker={localSessionId === activeSpeakerId}
          isLocal
          isAlone={isAlone}
          onPinParticipant={() => setPinnedVideoSession(dispatch, localSessionId)}
          sx={{ ...(tileStyles[tileIndex++] || defaultStyle) }}
        />
      )}
      {groupthinkParticipantId && (
        <Tile // Groupthink bot, keep this last for useCloudLayout
          id={groupthinkParticipantId}
          isActiveSpeaker={groupthinkParticipantId === activeSpeakerId}
          sx={{ ...(tileStyles[tileIndex++] || defaultStyle) }}
        />
      )}
    </Box>
  );
}

function BubbleGrid() {
  const { state: groupthinkVideoState, groupthinkParticipantId } = useCallLayout();

  /* This is for displaying our self-view. */
  const localSessionId = useLocalSessionId();
  const { state: callState, selectors } = useCall();
  const hasJoinedCall = selectors.selectHasJoinedCall(callState);

  // Layout params
  const shouldUseCloudLayout = groupthinkVideoState.participantsLayout == 'bubble';
  let gallerySx = {};
  // Handle count mismatches caused by freezes. Could get removed if we resolve the freezes but won't hurt
  let defaultStyle = {};

  // Get/store container dimensions
  const [containerRef, containerDimensions] = useElementSize();

  const videoCount =
    (groupthinkVideoState.participantVideos?.length ?? 0) +
    (groupthinkParticipantId ? 1 : 0) +
    (localSessionId && hasJoinedCall ? 1 : 0);

  const tileStyles = useCloudLayout(
    videoCount,
    containerDimensions?.width,
    containerDimensions?.height,
    groupthinkParticipantId ? true : false, // endsWithGroupthinkBot
    !shouldUseCloudLayout // isDisabled - this param is so we can dynamically swap layouts w/o changing # of hooks
  );

  if (shouldUseCloudLayout) {
    // copy style from first tile for default
    defaultStyle = tileStyles && tileStyles[0] ? { ...tileStyles[0] } : {};
    // but leave it in the middle or wherever
    delete defaultStyle.transform;
  } else {
    const { cols, rows } = getGridLayout(
      videoCount,
      containerDimensions?.height,
      containerDimensions?.width
    );
    gallerySx = {
      display: 'grid',
      gridTemplateRows: `repeat(${rows}, 1fr)`,
      gridTemplateColumns: `repeat(${cols}, 1fr)`,
      gap: '1rem',
      placeItems: 'center',
    };

    defaultStyle = {
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
    };
  }

  return (
    <Box
      className="tile-gallery"
      ref={containerRef}
      id={'bubble-grid'}
      sx={{
        mx: { xs: 0, md: 0 },
        my: 1,
        height: '100%',
      }}>
      <ParticipantVideoList
        defaultStyle={defaultStyle}
        tileStyles={tileStyles}
        wrapSx={gallerySx}
      />
    </Box>
  );
}

const getGridLayout = (count, containerHeight, containerWidth, max = 16) => {
  // Config: Max Participants in the grid
  const itemCount = Math.min(count, max);
  const aspectRatio = containerWidth / containerHeight;
  const cols = Math.min(itemCount, Math.ceil(Math.sqrt(itemCount) * aspectRatio));
  const rows = Math.ceil(itemCount / cols);

  return { rows, cols };
};
