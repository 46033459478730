import {
  SimulationNodeDatum,
  forceSimulation,
  forceManyBody,
  forceY,
  forceX,
  forceCollide,
} from 'd3-force';
import { useState, useEffect } from 'react';

interface Node extends SimulationNodeDatum {
  width: number;
  height: number;
  radius: number;
  x?: number;
  y?: number;
}

/**
 * Custom React hook to calculate and return styles for rendering elements
 * in a cloud-like layout within a container. Designed for a dynamic number
 * of elements (circles), making them cluster around the center with slight overlap.
 *
 * @param count - The number of elements to layout.
 * @param containerWidth - The width of the container.
 * @param containerHeight - The height of the container.
 * @param endsWithGroupthinkBot - Whether the first element is the Groupthink bot.
 * @param disabled - Whether to disable the layout.
 * @returns An array of React.CSSProperties objects for each element.
 */
const useCloudLayout = (
  count: number,
  containerWidth: number,
  containerHeight: number,
  endsWithGroupthinkBot: boolean,
  disabled: boolean
): React.CSSProperties[] => {
  const [styles, setStyles] = useState<React.CSSProperties[]>([]);
  const [positions, setPositions] = useState<{ x: number; y: number }[]>([]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    let frameId: number | null;

    // use saved positions from last simulation if we have them
    const updatedPositions = [...positions];
    while (updatedPositions.length < count) {
      updatedPositions.push({
        x: containerWidth / 2 + (Math.random() - 0.5) * 50,
        y: containerHeight / 2 + (Math.random() - 0.5) * 50,
      });
    }
    if (updatedPositions.length > count) {
      // Truncate array
      // TODO: remove the one that actually left
      updatedPositions.length = count;
    }
    setPositions(updatedPositions);

    // calculate what size circles should be to all fit
    const nodeDiameter = calculateCircleSize(count, containerWidth, containerHeight, 0.85);

    // Create nodes array with calculated sizes
    const nodes: Node[] = updatedPositions.map((pos) => {
      const jitter = 1 + (Math.random() * 0.15 - 0.15);
      return {
        x: pos.x,
        y: pos.y,
        width: nodeDiameter * jitter,
        height: nodeDiameter * jitter,
        radius: (nodeDiameter * jitter) / 2,
      };
    });

    // scale groupthink bot
    if (endsWithGroupthinkBot) {
      const last_index = nodes.length - 1;
      nodes[last_index].radius *= 0.4;
      nodes[last_index].width *= 0.4;
      nodes[last_index].height *= 0.4;
    }

    // Custom force to keep nodes within the container
    const forceContain = (alpha: number) => {
      nodes.forEach((node) => {
        const radiusPlusBuffer = node.radius + 5;
        if (node.x && node.y) {
          node.x += (containerWidth / 2 - node.x) * 0.1 * alpha;
          node.y += (containerHeight / 2 - node.y) * 0.1 * alpha;
          // Adjust constraints to allow nodes to reach up to the edges
          node.x = Math.max(radiusPlusBuffer, Math.min(containerWidth - radiusPlusBuffer, node.x));
          node.y = Math.max(radiusPlusBuffer, Math.min(containerHeight - radiusPlusBuffer, node.y));
        }
      });
    };

    let tickCounter = 0;
    const maxTicks = 200; // Stop after 100 ticks
    const bodyGravity = 0.05; // attraction towards each other
    const centerGravity = 0.2; // attraction towards center
    const overlapPercentage = 0.85; // percentage of overlap between circles

    const updateStylesInIdleTime = (newStyles) => {
      if ('requestIdleCallback' in window) {
        requestIdleCallback(() => setStyles(newStyles));
      } else {
        // Fallback for browsers without requestIdleCallback
        setTimeout(() => setStyles(newStyles), 1);
      }
    };

    const runSimulation = async () => {
      const simulation = forceSimulation(nodes)
        .force('charge', forceManyBody().strength(bodyGravity)) // gravity
        .force('x', forceX(containerWidth / 2).strength(centerGravity)) // centering gravity
        .force('y', forceY(containerHeight * 0.2).strength(centerGravity)) // push towards top gravity
        .force(
          // prevent overlap
          'collision',
          forceCollide<Node>()
            .radius((node) => node.radius * overlapPercentage) // slight overlap
            .strength(0.7)
        )
        .on('tick', () => {
          // keep within container bounds
        });

      const simulate = () => {
        simulation.tick();
        forceContain(simulation.alpha());
        tickCounter++;

        // Update positions
        const updatedPositions = nodes.map((node) => ({ x: node.x, y: node.y }));
        setPositions(updatedPositions);

        const newStyles: React.CSSProperties[] = nodes.map((node) => {
          const translateX = node.x ? node.x - node.radius : 0;
          const translateY = node.y ? node.y - node.radius : 0;

          return {
            position: 'absolute' as const, // Explicit type for 'position'
            width: `${node.width}px`,
            height: `${node.height}px`,
            transform: `translate(${translateX.toFixed(1)}px, ${translateY.toFixed(1)}px)`,
            // Ensure proper stacking and prevent visual glitches
            willChange: 'transform',
          };
        });
        setStyles(newStyles);

        // Continue or stop the simulation based on a condition
        if (tickCounter < maxTicks) {
          frameId = requestAnimationFrame(simulate);
        } else {
          simulation.stop();
        }
      };

      // Start the simulation
      frameId = requestAnimationFrame(simulate);
    };

    runSimulation();

    return () => {
      if (frameId) {
        cancelAnimationFrame(frameId);
      }
    };
  }, [count, containerWidth, containerHeight, endsWithGroupthinkBot, disabled]);

  return !disabled ? styles : [];
};

/**
 * Calculates the optimal size for circles in a container, considering the
 * number of circles, container dimensions, and desired overlap.
 *
 * @param count - The number of circles.
 * @param containerWidth - Width of the container.
 * @param containerHeight - Height of the container.
 * @param overlapFactor - Factor to adjust the overlap of circles.
 * @returns The calculated diameter for each circle.
 */
const calculateCircleSize = (
  count: number,
  containerWidth: number,
  containerHeight: number,
  overlapFactor: number = 1
): number => {
  const containerArea = containerWidth * containerHeight * (count > 3 ? 0.8 : 0.6);
  const effectiveCircleArea = containerArea / count / overlapFactor;
  const circleRadius = Math.sqrt(effectiveCircleArea / Math.PI);

  return Math.min(circleRadius * 2, containerWidth, containerHeight);
};

export default useCloudLayout;
