import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Badge from '@mui/joy/Badge';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';

export default function SettingsControlButton({ onClick, getUserMediaError }) {
  const label = 'Settings';

  // #777777 is not part of the Theme palette, so we must use sx to set the color
  const iconButtonProps = {};
  const iconProps = { sx: { color: '#777777', fontSize: '1.5rem' } };

  return (
    <Tooltip title={label} placement="top" arrow>
      <IconButton onClick={onClick} aria-label={label} {...iconButtonProps}>
        <Badge badgeContent="!" invisible={!getUserMediaError}>
          <SettingsOutlinedIcon {...iconProps} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}

export function DeveloperSettingsControlButton({ onClick }) {
  const label = 'Developer settings';

  // lightgrey is not part of the Theme palette, so we must use sx to set the color
  const iconButtonProps = {};
  const iconProps = { sx: { color: 'lightgrey', fontSize: '1.25rem' } };

  return (
    <Tooltip title={label} placement="top" arrow>
      <IconButton onClick={onClick} aria-label={label} {...iconButtonProps}>
        <DeveloperModeIcon {...iconProps} />
      </IconButton>
    </Tooltip>
  );
}
