import { useMessage } from '@groupthinkai/groupthink';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Textarea from '@mui/joy/Textarea';
import React, { useState } from 'react';

interface MessageEditorProps {
  message: Groupthink.MessageResource;

  onFocus?: () => void;
  autoFocus?: boolean;
  onEditingCompleted?: () => void;
}

export default function MessageEditor(props: MessageEditorProps) {
  const { message, onFocus = null, autoFocus = true, onEditingCompleted = null } = props;

  const [content, setContent] = useState(message.content || '');
  const [isUpdating, setIsUpdating] = useState(false);

  const isValid = Boolean((' ' + content).slice(1).replace(/(\r\n|\n|\r)/gm, ''));
  const { updateMessage, mutate } = useMessage(
    message.threadable_id,
    message.thread_id,
    message.id
  );

  const editorRef = React.useRef();

  /**
   * Performs the remote update of the message content.
   */
  const updateMessageContent = (content) => {
    updateMessage({
      payload: { content },
      setIsUpdating,
      onSuccess: () => {
        onEditingCompleted?.();
      },
    });
  };

  const handleSave = async () => {
    if (!isValid) {
      return;
    }

    /**
     * Optimistically update the message content in the cache.
     */
    const options = {
      optimisticData: () => ({ data: { ...message, content } }),
      populateCache: () => ({ data: { ...message, content } }),
      rollbackOnError(error) {
        // If it's timeout abort error, don't rollback
        return error.name !== 'AbortError';
      },
    };
    mutate(updateMessageContent(content), options);
  };

  const handleCancel = async () => {
    onEditingCompleted?.();
  };

  return (
    <Textarea
      className="message-edit-composer"
      variant="outlined"
      minRows={1}
      size="md"
      value={content}
      disabled={isUpdating}
      ref={editorRef}
      onFocus={onFocus}
      autoFocus={autoFocus}
      onChange={(e) => setContent(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !(e.metaKey || e.ctrlKey || e.shiftKey || e.altKey)) {
          handleSave();
        }
      }}
      endDecorator={
        <ButtonGroup
          variant="solid"
          spacing="0.5rem"
          size="md"
          sx={{ ml: 'auto' }}
          aria-label="message editor group">
          <Button color="neutral" disabled={isUpdating} onClick={handleCancel}>
            Cancel
          </Button>
          <Button loading={isUpdating} color="primary" disabled={isUpdating} onClick={handleSave}>
            Save
          </Button>
        </ButtonGroup>
      }
    />
  );
}
