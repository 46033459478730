import { useDevices, useVideoTrack } from '@daily-co/daily-react';
import Avatar from '@mui/joy/Avatar';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import React from 'react';

import Tile from '@/components/video/Tile';

function VideoPreview({ sessionId, fallbackSrc }) {
  const videoState = useVideoTrack(sessionId);
  const { hasCamError, cameraError } = useDevices();

  if (hasCamError || videoState.isOff) {
    let message = "We can't see you";

    if (hasCamError) {
      if (cameraError?.type === 'permissions') {
        message = 'Camera blocked';
      } else if (cameraError?.type === 'cam-in-use') {
        message = 'Camera in use by another app';
      } else if (cameraError?.type === 'not-found') {
        message = 'No camera found';
      }
    }

    return (
      <Stack justifyContent="center" alignItems="center">
        <Avatar
          alt={hasCamError ? 'Camera error' : 'Camera is off'}
          src={fallbackSrc}
          sx={{
            width: '100%',
            height: 'auto',
            aspectRatio: '1',
            borderRadius: '50%',
            backgroundColor: '#BBB',
          }}
        />
        <Typography level="body-sm" sx={{ mt: 1 }}>
          {message}
        </Typography>
      </Stack>
    );
  }

  if (sessionId) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <Tile id={sessionId} isPreview />
        <Typography level="body-sm" sx={{ mt: 1 }}>
          You look fantastic 😉
        </Typography>
      </Stack>
    );
  }

  return null;
}

export default VideoPreview;
